/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import Grid from '@solussaude/core/Grid';
import Page from '@solussaude/core/Surfaces/Page';
import Form from '@solussaude/core/CustomForm';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import ActionButton from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import TableCRUD from '@solussaude/core/DataDisplay/TableCRUD';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import WebContext from '@solussaude/core/WebContext';
import {
  SearchIcon,
  WarningIcon,
  CheckCircleIcon,
} from '@solussaude/core/Icons';
import { isPromise } from '@solussaude/core/Utils';
import SolusAPI from '../../../services/SolusApi';
import defaultTheme from '../../../styles/themes/default';
import { consultaPedido, procedimentos } from './settings';

let mapping;
const getMapping = () => mapping;
const setMapping = (newMapping) => {
  mapping = newMapping;
};

let mappingForm;
const getMappingForm = () => mappingForm;
const setMappingForm = (pGetMapping) => {
  mappingForm = pGetMapping;
};

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

export default function ConsultaPedido() {
  const theme = defaultTheme;
  const webContext = React.useContext(WebContext);

  const [state, setState] = React.useState({
    loading: true,
    render: <></>,
    guia: '',
    apiDataE: { selecionado: 0, lista: [] },
    successApiE: false,
    apiDataC: [],
    successApiC: false,
    id: '',
  });

  const [proc, setProc] = React.useState();

  React.useEffect(() => {
    const init = async () => {
      const userAuth = await isPromise(webContext.module.beneficiario.userAuth);
      const dados = await SolusAPI.get('api/settings/estados');
      const dadosC = await SolusAPI.post('api/settings/cidades', {
        estado: dados.data[0].codigo,
      });

      setState({
        loading: false,
        apiDataE: { selecionado: 0, lista: dados.data },
        successApiE: dados.success,
        apiDataC: dadosC.data,
        id: userAuth.id,
      });
    };
    init();
  }, []);

  const handleCloseModal = () => {
    webContext.showModal({
      open: false,
    });
  };

  const Procedimentos = ({ items, cidade, estado, idBeneficiario, guia }) => (
    <>
      <TableCRUD
        settings={procedimentos(
          items,
          cidade,
          estado,
          idBeneficiario,
          guia,
          webContext,
        )}
        inLoading={state.loading}
      />
    </>
  );

  const handleClick = async () => {
    const { pedido, cidade, estado } = getFormValues();
    let dados = [];
    let dadosG = [];
    if (pedido) {
      dadosG = await SolusAPI.post('api/beneficiarios/status-pedido', {
        id: pedido,
      });
      dados = await SolusAPI.post('api/beneficiarios/lista-itens-pedidos', {
        id: pedido,
      });

      if (dadosG.data !== '') {
        webContext.showModal({
          open: true,
          renderBody: (
            <>
              <BodyInfo
                icon={<WarningIcon color="error" />}
                title="Opss..."
                body={dadosG.data}
                noDivider
              />
              <ActionButton>
                <Button
                  variant="contained"
                  color="secondary"
                  text="Fechar"
                  onClick={() => handleCloseModal()}
                />
              </ActionButton>
            </>
          ),
        });
        setProc(<></>);
      } else {
        webContext.showModal({
          open: true,
          renderBody: (
            <>
              <BodyInfo
                icon={<CheckCircleIcon color="primary" />}
                title="Pedido aprovado"
                body="Seu pedido foi aprovado. Por favor, procure a rede credenciada."
                noDivider
              />
              <ActionButton>
                <Button
                  variant="contained"
                  color="secondary"
                  text="Fechar"
                  onClick={() => handleCloseModal()}
                />
              </ActionButton>
            </>
          ),
        });
        setProc(
          <Procedimentos
            items={dados.data}
            cidade={cidade}
            estado={estado}
            idBeneficiario={state.id}
            guia={pedido}
            webContext={webContext}
          />,
        );
      }
    } else {
      webContext.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<WarningIcon color="error" />}
              title="Erro"
              body="É necessário preencher a guia"
              noDivider
            />
            <ActionButton>
              <Button
                variant="contained"
                color="secondary"
                text="Fechar"
                onClick={() => handleCloseModal()}
              />
            </ActionButton>
          </>
        ),
      });

      setProc(<></>);
    }
  };

  const changeEstado = async (e, estados) => {
    const { pedido } = getFormValues();
    const cidades = await SolusAPI.post('api/settings/cidades', {
      estado: e.value,
    });

    if (cidades === undefined) {
      return <CircularIndeterminateModal />;
    }

    setState({
      ...state,
      guia: pedido,
      apiDataE: { selecionado: e.value, lista: estados },
      apiDataC: cidades.data,
    });

    return null;
  };

  setMapping({
    ...getMapping(),
    getMappingForm,
    getFormValues,
  });

  const settings = consultaPedido(
    webContext,
    getMapping,
    state.apiDataE,
    changeEstado,
    state.apiDataC,
    state.guia,
    state.id,
    handleClick,
  );

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page icon={SearchIcon} title="Consulta de pedido">
            <Grid item sm={12} md={12}>
              {state.successApiE ? (
                <Form
                  settings={settings}
                  setValues={setFormValues}
                  setMappingForm={setMappingForm}
                />
              ) : null}
              {proc}
            </Grid>
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
