import Request from '../request';

export const getDadosBeneficiario = async () => {
  const resp = await Request({ method: 'get', path: 'api/beneficiarios' });

  if (resp.status === 200)
    return {
      naturezaJuridica: resp?.data[0]?.natureza_juridica,
      ...resp.data[0],
    };

  return resp;
};
