import { processData } from '@solussaude/core/Utils';

const meioUtilizado = [
  {
    key: 'Ônibus',
    name: 'Ônibus',
  },
  {
    key: 'Carro',
    name: 'Carro',
  },
];

export const formData = (state) => ({
  form: [
    {
      inputs: [
        [
          {
            // Column
            elem_id: 'dataViagem',
            elem_val: state.dataViagem,
            type: 'InputText',
            typeInputText: 'date',
            shrink: true,
            variant: 'outlined',
            label: 'Data de viagem',
            required: true,
            flex: 5,
            small: true,
          },
        ],
      ],
    },
  ],
});

export const formDeslocamento = (state, handleEstado, handleCidade) => ({
  form: [
    {
      inputs: [
        [
          {
            // Column
            elem_id: 'meioUtilizado',
            elem_val: state.meioUtilizado,
            type: 'RadioButton',
            label: 'Meio utilizado para o deslocamento',
            value: meioUtilizado,
            required: true,
            variant: 'outlined',
            small: true,
          },
        ],
        [
          {
            elem_id: 'estadoOrigem',
            elem_val: state.estadoOrigem.selecionado,
            type: 'Select',
            variant: 'outlined',
            small: true,
            value: processData(
              state.estadoOrigem.descricao,
              'valor',
              'descricao',
            ),
            label: 'Estado origem',
            onChange: (e) => handleEstado(e, 'origem'),
            required: true,
            flex: 5,
          },
          {
            // Column
            elem_id: 'cidadeOrigem',
            elem_val: state.cidadeOrigem.selecionado || 0,
            type: 'Autocomplete',
            label: 'Cidade origem',
            variant: 'outlined',
            options: processData(state.cidadeOrigem.descricao, 'nome', 'nome'),
            labelOption: (saida) => saida.name || 'Selecione uma opção',
            fullWidth: true,
            small: true,
            flex: 5,
          },
        ],
        [
          {
            elem_id: 'estadoDestino',
            elem_val: state.estadoDestino.selecionado,
            type: 'Select',
            variant: 'outlined',
            small: true,
            value: processData(
              state.estadoDestino.descricao,
              'valor',
              'descricao',
            ),
            label: 'Estado destino',
            onChange: (e) => handleEstado(e, 'destino'),
            required: true,
            flex: 5,
          },
          {
            // Column
            elem_id: 'cidadeDestino',
            elem_val: state.cidadeDestino.selecionado || 0,
            type: 'Autocomplete',
            label: 'Cidade destino',
            variant: 'outlined',
            options: processData(state.cidadeDestino.descricao, 'nome', 'nome'),
            labelOption: (saida) => saida.name || 'Selecione uma opção',
            fullWidth: true,
            small: true,
            flex: 5,
          },
        ],
        [
          {
            // Column
            elem_id: 'dataViagem',
            elem_val: state.dataViagem,
            type: 'InputText',
            typeInputText: 'date',
            shrink: true,
            variant: 'outlined',
            label: 'Data de viagem',
            required: true,
            flex: 5,
            small: true,
          },
        ],
        [
          {
            // Column
            elem_id: 'motivo',
            elem_val: state.motivo,
            type: 'InputText',
            label: 'Motivo do deslocamento',
            flex: 10,
            variant: 'outlined',
            rows: 5,
            small: true,
            required: true,
          },
        ],
      ],
    },
  ],
});
