import styled from 'styled-components';
import ButtonFab from '@solussaude/core/Inputs/ButtonFab';

export const BodyStatus = styled.div`
  display: flex;
  color: ${(props) => props.color};
`;

export const Container = styled.div`
  margin: 15px;
`;

export const TitleCont = styled.div.attrs({
  className: 'shadow',
})`
  margin-bottom: 40px;
  display: inline-grid;
  background: ${(props) => props.theme.palette.background.headerTitle};
  padding: 20px;
  border-radius: ${(props) => props.theme.shape.borderRadius};

  h5 {
    color: ${(props) => props.theme.palette.primary.contrastText};
    font-weight: 100;
  }
`;

export const ContainerButton = styled.div`
  display: flex;

  button:first-of-type {
    margin-right: 5px;
    width: inherit;
  }

  .MuiButton-label {
    white-space: nowrap;
  }
`;

export const ContainerDetail = styled.div`
  tbody .MuiTableCell-body {
    padding: 6px 16px;
  }
`;

export const BodyDetail = styled.div`
  background: #ededed;
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;

export const BodyDetailData = styled.div.attrs({
  className: 'shadow',
})`
  padding: 20px;
`;

export const ContainerResponse = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ModalResponse = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContentResponse = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

export const ActionButtons = styled.div`
  padding-top: 10px;
  justify-content: ${(props) =>
    props.direction !== undefined ? props.direction : 'flex-end'};
  display: flex;
  flex: 1;

  .MuiStepper-horizontal {
    padding-bottom: 0px;
  }
`;

export const SpaceItems = styled.div`
  padding-bottom: 45px;
`;

export const Fab = styled(ButtonFab)`
  right: 62px;
  bottom: 32px;
  z-index: 1100;
  position: fixed !important;
`;
