import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'contentCenter',
})`
  flex: 1;
`;

export const TablePanel = styled.div.attrs({
  className: 'withoutShadow',
})`
  background: #fff;

  margin-bottom: 35px;
  /* padding-left:35px;
    padding-right:35px; */

  box-shadow: none;
  border-radius: 5px;
  display: block;
  justify-content: center;
`;

export const TableHeader = styled.div.attrs({
  className: 'withoutShadow contentCenter',
})`
  flex: 1;
  height: 125px;
  margin-top: -25px;

  /* background: linear-gradient(to right,#42275a ,#b4044a); */
  background: linear-gradient(to right, #f1f1f1, #fff);
  border-radius: 3px;

  h1 {
    color: #fff;
    font-weight: 300;
  }
`;

export const TableBody = styled.div`
  flex: 1;
  margin-bottom: 20px;
  margin-top: 10px;

  h3 {
    color: #000;
    font-weight: 300;
    text-align: center;
  }
`;

export const ComponentTag = styled.div`
  header {
    display: ${(props) => props.display};
    transition: all 2s linear;
  }
`;

export const ContentLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentDisabled = styled.div`
  & > form > div {
    .MuiInputBase-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.8);
      cursor: default;
    }
  }
`;

export const Centralized = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;