/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import BodyFormModal from '@solussaude/core/Helpers/BodyFormModal';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import { isPromise } from '@solussaude/core/Utils';
import WebContext from '@solussaude/core/WebContext';
import SolusAPI from '../../../services/SolusApi';

import { renderForm } from './settings';

export default function ExtratoAtendimento() {
  const { module } = React.useContext(WebContext);

  const [state, setState] = React.useState({
    inLoading: true,
    action: {
      verifyURL: 'api/beneficiarios/atendimento/verify',
      verifyData: {},
      urlPDF: 'api/beneficiarios/atendimento/pdf',
      dataPDF: {},
    },
    apiData: [],
  });

  React.useEffect(() => {
    const getFromAPI = async () => {
      const userAuth = await isPromise(module.beneficiario.userAuth);
      const dados = await SolusAPI.get(
        'api/beneficiarios/combo-extrato-atendimento',
      );

      setState((old) => ({
        ...old,
        inLoading: false,
        action: {
          ...old.action,
          verifyData: {
            api: 'S',
            listar: 'S',
            titular: userAuth.id_titular,
            contrato: userAuth.id_contrato,
            userId: userAuth.id,
            imprimir: 'S',
          },
          dataPDF: {
            api: 'S',
            listar: 'S',
            titular: userAuth.id_titular,
            contrato: userAuth.id_contrato,
            userId: userAuth.id,
          },
        },
        apiData: dados.data,
      }));
    };
    getFromAPI();
  }, []);

  return (
    <>
      {state.inLoading === false ? (
        <BodyFormModal
          title="Extrato de Atendimentos"
          description="Escolha o ano que deseja emitir o extrato de atendimento."
          formSettings={renderForm(state.apiData)}
          descriptionSuccess={
            <p>Seu extrato de atendimento foi impresso com sucesso!</p>
          }
          action={state.action}
        />
      ) : (
        <CircularIndeterminateModal />
      )}
    </>
  );
}
