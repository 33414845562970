/* eslint-disable no-unused-vars */

import React from 'react';
import Form from '@solussaude/core/CustomForm';
import Grid from '@solussaude/core/Grid';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import IconButton from '@solussaude/core/Inputs/IconButton';
import Tooltip from '@solussaude/core/DataDisplay/Tooltip';
import WebContext from '@solussaude/core/WebContext';
import {
  AccountCircleIcon,
  AttachFileIcon,
  DeleteIcon,
} from '@solussaude/core/Icons';
import { formBotaoAnexo, formTipoAnexo, formListaAnexo } from './settings';
import { ContainerForm, ContainerAnexo, ContainerModalAnexo } from './styles';

let mappingForm;
const setMappingForm = (pGetMapping) => {
  mappingForm = pGetMapping;
};

let mappingFormAnexo;
const getMappingFormTipoAnexo = () => mappingFormAnexo;
const mappingFormTipoAnexo = (pGetMapping) => {
  mappingFormAnexo = pGetMapping;
};

let formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

const arrayTipoAnexo = [];
const setFormTipoAnexoValues = (newValue, i) => {
  arrayTipoAnexo[i] = newValue;
};
const getFormTipoAnexoValues = () => arrayTipoAnexo;

let formListaAnexoValues;

let form = [];
export default function Anexos({ setMapping, getMapping }) {
  setMapping({
    ...getMapping(),
    getFormAnexos: form,
    getMappingFormTipoAnexo,
  });

  const getFormAnexoValues = () => form;
  const setFormAnexoValues = (newValue, i) => {
    //Está enviando um campo undefined, dessa forma com o if a anexação fica correta no array;
    if (newValue.anexo) {
      form[i] = newValue;
    }
  };

  const [state, setState] = React.useState({
    anexo: [],
    render: <></>,
    operacao: 'I',
  });

  const webContext = React.useContext(WebContext);
  const ref = React.useRef(null);

  React.useEffect(() => {
    form = [];
  }, [webContext.tipoSolicitacao]);

  React.useEffect(() => {
    if (ref.current && state.operacao === 'I') {
      ref.current.click();
    }
  }, [state]);

  const handleCloseModal = () => {
    webContext.showModal({
      open: false,
    });
  };

  const onClickRemover = (i) => {
    form.splice(i, 1);
    setState({ ...state, operacao: 'E' });
  };

  const Anexo = ({ i }) => (
    <Grid item xs={12} sm={12} md={4} key={i}>
      <ContainerAnexo>
        <Form
          settings={formListaAnexo(form[i], arrayTipoAnexo[i], i, ref)}
          setValues={(newValue) => setFormAnexoValues(newValue, i)}
          setMappingForm={setMappingForm}
        />
        <IconButton onClick={() => onClickRemover(i)}>
          <Tooltip icon={<DeleteIcon color="secondary" />} title="Remover" />
        </IconButton>
      </ContainerAnexo>
    </Grid>
  );

  const ListaAnexo = () => (
    <>
      {form.map((data, i) => (
        <Anexo i={i} />
      ))}
    </>
  );

  const insereAnexo = () => {
    const { tipoAnexo } = getMapping().getMappingFormTipoAnexo()();

    if (tipoAnexo.values) {
      setState({
        operacao: 'I',
        anexo: form,
        render: (
          <Grid container>
            <ListaAnexo />
          </Grid>
        ),
      });
      webContext.showModal({ open: false });
    } else {
      tipoAnexo.setError({
        is: true,
        helperText: 'Preenchimento obrigatório',
      });
    }
  };

  const onClickSalva = () => {
    webContext.showModal({
      open: true,
      renderBody: (
        <ContainerModalAnexo>
          <BodyInfo
            icon={<AttachFileIcon />}
            title="Anexo"
            body="Informe abaixo o tipo do anexo: nota fiscal, recibo e etc..."
          />

          <form onSubmit={(e) => e.preventDefault()}>
            <Form
              settings={formTipoAnexo(handleCloseModal, insereAnexo)}
              setValues={(newValue) =>
                setFormTipoAnexoValues(newValue, form.length - 1)
              }
              setMappingForm={mappingFormTipoAnexo}
            />
          </form>
        </ContainerModalAnexo>
      ),
    });
    form.push(getFormAnexoValues());
  };

  return (
    <>
      <BodyInfo title="Anexos" icon={<AccountCircleIcon />} noDivider />
      <Form settings={formBotaoAnexo(onClickSalva)} setValues={setFormValues} />
      <ContainerForm>{state.render}</ContainerForm>
    </>
  );
}
