import styled from 'styled-components';

const defaultDirection = 'flex-end';
export const ContentSolicitacao = styled.div`
  @keyframes widthToMax {
    0% {
      width: 300px;
    }
    100% {
      width: 500px;
    }
  }

  animation: 0.2s ease-out 0s 1 widthToMax;
  margin: 0px 15px;
  width: 500px;
`;

export const ActionButtons = styled.div`
  padding-top: 10px;
  justify-content: ${(props) => (props.direction !== undefined ? props.direction : defaultDirection)};
  display: flex;
  flex: 1;

  .MuiStepper-horizontal {
    padding-bottom: 0px;
  }
`;

export const BodyDescription = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  color: #686868;

  p {
    font-size: 18px;
    text-align: justify;
  }

  b {
    color: #000;
  }
`;
