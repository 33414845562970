import React from 'react';

import {
  AssignmentIcon,
  PersonIcon,
  HomeIcon,
  LocalAtmIcon,
  InfoIcon,
  BuildIcon,
} from '@solussaude/core/Icons';

import Page404 from '../pages/404';
import Page500 from '../pages/500';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import NewPassword from '../pages/RegisterPassword/NewPassword';
import WithoutAction from '../pages/RegisterPassword/WithoutAction';
import BoletoDigital from '../pages/BoletoDigital';
import Perfil from '../pages/Perfil';
import MeuPlano from '../pages/MeuPlano';
import EsqueciSenha from '../pages/EsqueciSenha';
import AlterarSenha from '../pages/AlterarSenha';
import Documents from '../pages/Documents';
import Servicos from '../pages/Servicos';
import CancelamentoContrato from '../pages/Servicos/CancelamentoContrato';
import Carteirinha from '../pages/Servicos/Carteirinha';
import CanaisAtendimento from '../pages/Servicos/CanaisAtendimento';
import Detalhes from '../pages/Servicos/CanaisAtendimento/Detalhes';
import FormularioReembolso from '../pages/Servicos/SolicitacaoProtocoloReembolso/FormularioUsuario';
// eslint-disable-next-line max-len
import FormularioInstrumentacaoCirurgica from '../pages/Servicos/SolicitacaoProtocoloReembolso/FormularioInstrumentacaoCirurgica';
import FormularioDeslocamento from '../pages/Servicos/SolicitacaoProtocoloReembolso/FormularioDeslocamento';
import FormularioOutros from '../pages/Servicos/SolicitacaoProtocoloReembolso/FormularioOutros';
import LimiteUtilizacao from '../pages/Info/LimiteUtilizacao';
import PlanosProdutos from '../pages/Info/PlanosProdutos';
import MeusProtocolos from '../pages/Info/MeusProtocolos';
import Comunicados from '../pages/Info/Comunicados';
import ConsultaPedido from '../pages/Info/ConsultaPedido';
import GuiaMedico from '../pages/GuiaMedico';
import Info from '../pages/Info';
import PaymentConfirm from '../pages/PaymentConfirm';
import ComponenteCadastral from '../pages/ComponenteCadastral';
import PrazoCarencia from '../pages/Info/PrazoCarencia';

const Pathname = (path) => `${localStorage.getItem('basename')}${path}`;

export const Pages = [
  {
    id: 1,
    route: Pathname('/500'),
    component: Page500,
    private: false,
  },
  {
    id: 2,
    route: Pathname('/400'),
    component: Page404,
    private: false,
  },
  {
    id: 3,
    route: Pathname('/login'),
    component: Login,
    private: false,
  },
  {
    id: 4,
    route: Pathname('/esqueci-senha'),
    component: EsqueciSenha,
    private: false,
  },
  {
    id: 5,
    route: Pathname('/dashboard'),
    component: Dashboard,
    private: true,
    menu: true,
    title: 'Home',
    icon: <HomeIcon />,
  },
  {
    id: 6,
    route: Pathname('/cadastrar-senha'),
    component: NewPassword,
    private: false,
  },
  {
    id: 8,
    route: Pathname('/without-action'),
    component: WithoutAction,
    private: false,
  },
  {
    id: 9,
    route: Pathname('/boleto-digital'),
    component: BoletoDigital,
    private: true,
  },
  {
    id: 10,
    route: Pathname('/plano'),
    component: MeuPlano,
    private: true,
    menu: true,
    title: 'Meu Plano',
    icon: <AssignmentIcon />,
  },
  {
    id: 7,
    route: Pathname('/perfil'),
    component: Perfil,
    private: true,
    menu: true,
    title: 'Meu Perfil',
    icon: <PersonIcon />,
  },
  {
    id: 11,
    route: Pathname('/alterar-senha'),
    component: AlterarSenha,
    private: true,
  },
  {
    id: 12,
    route: Pathname('/documents'),
    component: Documents,
    private: true,
    menu: true,
    permission: ['PF'],
    title: 'Boletos',
    icon: <LocalAtmIcon />,
  },
  {
    id: 13,
    route: Pathname('/servicos/cancelamento-contrato'),
    component: CancelamentoContrato,
    private: true,
  },
  {
    id: 14,
    route: Pathname('/servicos'),
    component: Servicos,
    private: true,
    menu: true,
    title: 'Serviços',
    icon: <BuildIcon />,
  },
  {
    id: 15,
    route: Pathname('/servicos/carteirinha'),
    component: Carteirinha,
    private: true,
  },
  {
    id: 16,
    route: Pathname('/servicos/canais-atendimento'),
    component: CanaisAtendimento,
    private: true,
  },
  {
    id: 17,
    route: Pathname('/servicos/canais-atendimento/detalhes'),
    component: Detalhes,
    private: true,
  },
  {
    id: 18,
    route: Pathname('/servicos/solicitacao-protocolo-reembolso'),
    component: FormularioReembolso,
    private: true,
  },
  {
    id: 19,
    route: Pathname(
      '/servicos/solicitacao-protocolo-reembolso/instrumentacao-cirurgica',
    ),
    component: FormularioInstrumentacaoCirurgica,
    private: true,
  },
  {
    id: 20,
    route: Pathname('/servicos/solicitacao-protocolo-reembolso/deslocamento'),
    component: FormularioDeslocamento,
    private: true,
  },
  {
    id: 21,
    route: Pathname('/servicos/solicitacao-protocolo-reembolso/outros'),
    component: FormularioOutros,
    private: true,
  },
  {
    id: 22,
    route: Pathname('/info/limites-utilizacao'),
    component: LimiteUtilizacao,
    private: true,
  },
  {
    id: 23,
    route: Pathname('/info/planos-produtos'),
    component: PlanosProdutos,
    private: true,
  },
  {
    id: 24,
    route: Pathname('/info/meus-protocolos'),
    component: MeusProtocolos,
    private: true,
  },
  {
    id: 25,
    route: Pathname('/info/comunicados'),
    component: Comunicados,
    private: true,
  },
  {
    id: 26,
    route: Pathname('/info/consulta-pedido'),
    component: ConsultaPedido,
    private: true,
  },
  {
    id: 27,
    route: Pathname('/info'),
    component: Info,
    private: true,
    menu: true,
    title: 'Informações',
    icon: <InfoIcon />,
  },
  {
    id: 28,
    route: Pathname('/info/prazo-carencia'),
    component: PrazoCarencia,
    private: true,
  },
  {
    id: 29,
    route: Pathname('/info/guias-medico'),
    component: GuiaMedico,
    private: true,
  },
  {
    id: 30,
    route: Pathname('/guias-medico'),
    component: GuiaMedico,
    private: false,
  },
  {
    id: 31,
    route: Pathname('/plano/componente-cadastral'),
    component: ComponenteCadastral,
    private: true,
  },
  {
    id: 32,
    route: Pathname('/debit/payment-confirm'),
    component: PaymentConfirm,
    private: true,
  },
];
