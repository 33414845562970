import { FormatDate } from '../../utils';

import { DescriptionIcon, EventIcon } from '@solussaude/core/Icons';

const processImage = (apiData) => {
  const array = [];

  apiData.forEach((img) => {
    array.push({
      href: '#',
      url: img.url,
    });
  });
  return array;
};

export const slider = (apiData) => {
  if (apiData.length > 0) {
    return {
      color: 'white',
      img: processImage(apiData),
    };
  }
  return null;
};

const processData = (apiData) => {
  const array = [];
  let i = 0;
  if (apiData.length > 0) {
    while (i < 3) {
      if (i < apiData.length) {
        array.push({
          data: FormatDate(apiData[i].data),
          descricao: apiData[i].titulo,
        });
      }
      i += 1;
    }
  }
  return array;
};

export const tableDadosComunicados = (apiData) => ({
  table: {
    options: {
      type: 'headerTop',
      small: true,
    },
    headers: [
      {
        label: 'Data',
        icon: <EventIcon color="primary" />,
      },
      {
        label: 'Descrição',
        icon: <DescriptionIcon color="primary" />,
      },
    ],

    data: processData(apiData),
  },
});
