export const formOutros = () => ({
  form: [
    {
      inputs: [
        [
          {
            // Column
            elem_id: 'motivo',
            type: 'InputText',
            label: 'Motivo pelo qual não usou a rede credenciada',
            flex: 10,
            variant: 'outlined',
            rows: 5,
            small: true,
            required: true,
          },
        ],
      ],
    },
  ],
});
