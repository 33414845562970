/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import Grid from '@solussaude/core/Grid';
import Typography from '@solussaude/core/Typography';

import {
  AssignmentIcon,
  FormatAlignLeftIcon,
  WarningIcon,
  ShowChartIcon,
  TimelineIcon,
} from '@solussaude/core/Icons';
import Table from '@solussaude/core/DataDisplay/TableSimple';
import TableCRUD from '@solussaude/core/DataDisplay/TableCRUD';
import Page from '@solussaude/core/Surfaces/Page';
import Button from '@solussaude/core/Inputs/Button';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import { Link } from '@solussaude/core/Router';
import { isPromise } from '@solussaude/core/Utils';
import WebContext from '@solussaude/core/WebContext';
import SolusAPI from '../../services/SolusApi';

import defaultTheme from '../../styles/themes/default';
import { TablePanel, TableBody, NoStylesLink } from './styles';
import {
  tableDadosPlano,
  tableReajusteTabelaPreco,
  tableReajusteFaixaEtaria,
  tableHistoricoPlanos,
} from './settings';

export default function MeuPlano() {
  const context = React.useContext(WebContext);
  const [state, setState] = React.useState({
    loading: true,
    successApiPlano: true,
    apiDataPlano: [],
    successApiEndereco: true,
    apiDataEndereco: [],
    apiDataReajusteTabelaPreco: [],
    successApiReajusteTabelaPreco: true,
    apiDataReajusteFaixaEtaria: [],
    successApiReajusteFaixaEtaria: true,
    apiDataHistoricoPlanos: [],
    successApiHistoricoPlanos: true,
    naturezaContrato: '',
    naturezaJuridica: '',
  });
  const theme = defaultTheme;

  React.useEffect(() => {
    const search = async () => {
      const userAuth = await isPromise(context.module.beneficiario.userAuth);

      const dadosPlano = await SolusAPI.post('api/beneficiarios/planos', {
        id: userAuth.id_plano,
      });

      const dadosContrato = await SolusAPI.get(
        `api/beneficiarios/dados-contrato/${userAuth.id_contrato}`,
      );

      const dadosReajusteTabelaPreco = await SolusAPI.get(
        `v1/beneficiario/${userAuth.id}/reajuste/tabelapreco`,
        {},
        { appName: 'api_webbeneficiario' },
      );

      const dadosReajusteFaixaEtaria = await SolusAPI.get(
        `v1/beneficiario/${userAuth.id}/reajuste/faixaetaria`,
        {},
        { appName: 'api_webbeneficiario' },
      );

      const dadosHistoricoPlanos = await SolusAPI.get(
        `v1/beneficiario/${userAuth.id}/historicoplano`,
        {},
        { appName: 'api_webbeneficiario' },
      );

      setState({
        ...state,
        loading: false,
        apiDataPlano: dadosPlano.data,
        successApiPlano: dadosPlano.success,
        apiDataEndereco: userAuth,
        successApiEndereco: userAuth.success === undefined,
        successApiReajusteTabelaPreco: dadosReajusteTabelaPreco.status === 200,
        apiDataReajusteTabelaPreco: dadosReajusteTabelaPreco.data,
        successApiReajusteFaixaEtaria: dadosReajusteFaixaEtaria.status === 200,
        apiDataReajusteFaixaEtaria: dadosReajusteFaixaEtaria.data,
        apiDataHistoricoPlanos: dadosHistoricoPlanos.data,
        successApiHistoricoPlanos: dadosHistoricoPlanos.success,
        naturezaContrato: dadosContrato.data[0].tipo_contratacao,
        naturezaJuridica: userAuth.natureza_juridica,
      });
    };
    search();
  }, []);

  const renderBodyTablePlano = () => {
    if (state.apiDataPlano.length === 0) {
      return (
        <Table
          inLoading={state.loading}
          error="Não há dados do plano para mostrar!"
          settings={tableDadosPlano(state.apiDataPlano)}
        />
      );
    }
    if (state.apiDataPlano.length > 0) {
      return (
        <Table
          inLoading={state.loading}
          settings={tableDadosPlano(state.apiDataPlano)}
        />
      );
    }
    return null;
  };

  const renderBodyTableHistoricoPlano = () => {
    if (state.apiDataHistoricoPlanos.length > 0) {
      return (
        <TableCRUD
          inLoading={state.loading}
          settings={tableHistoricoPlanos(state.apiDataHistoricoPlanos)}
        />
      );
    }
    if (state.apiDataHistoricoPlanos.length === 0) {
      return (
        <TableCRUD
          inLoading={state.loading}
          error="Não há dados do reajuste para mostrar!"
          settings={tableHistoricoPlanos(state.apiDataHistoricoPlanos)}
        />
      );
    }

    return null;
  };

  const renderBodyTableReajusteTabelaPreco = () => {
    if (state.apiDataReajusteTabelaPreco) {
      return (
        <>
          <TableCRUD
            inLoading={state.loading}
            settings={tableReajusteTabelaPreco(
              state.apiDataReajusteTabelaPreco,
              state.naturezaContrato,
            )}
          />
        </>
      );
    }

    return null;
  };

  const renderBodyTableReajusteFaixaEtaria = () => {
    if (state.apiDataReajusteFaixaEtaria) {
      return (
        <TableCRUD
          inLoading={state.loading}
          settings={tableReajusteFaixaEtaria(state.apiDataReajusteFaixaEtaria)}
        />
      );
    }

    return null;
  };

  const renderDefaultErrorPlano = (
    <>
      <BodyInfo
        icon={<WarningIcon color="error" />}
        title="Opss..."
        body="Não foi possivel carregar seu plano, tente novamente mais tarde."
        noDivider
      />
    </>
  );

  const renderDefaultErrorReajuste = (
    <>
      <BodyInfo
        icon={<WarningIcon color="error" />}
        title="Opss..."
        body="Não foi possivel carregar seu reajuste, tente novamente mais tarde."
        noDivider
      />
    </>
  );

  const renderDefaultErrorHistorico = (
    <>
      <BodyInfo
        icon={<WarningIcon color="error" />}
        title="Opss..."
        body="Não foi possivel carregar seu histórico de planos, tente novamente mais tarde."
        noDivider
      />
    </>
  );

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page
            title="Componente cadastral"
            icon={AssignmentIcon}
            type="Borderless"
          >
            <Grid item sm={12} md={12}>
              <Typography variant="h6">Meu plano</Typography>
              <TablePanel>
                <TableBody>
                  {state.successApiPlano
                    ? renderBodyTablePlano()
                    : renderDefaultErrorPlano}
                </TableBody>
                <NoStylesLink>
                  <Link
                    to={`${localStorage.getItem(
                      'basename',
                    )}/plano/componente-cadastral`}
                  >
                    <Button
                      text="Visualizar componente completo"
                      variant="contained"
                      color="primary"
                      startIcon={<FormatAlignLeftIcon />}
                      fullWidth={false}
                    />
                  </Link>
                </NoStylesLink>
              </TablePanel>
              <br />
              <BodyInfo
                title="Limites de utilização do plano"
                body="Você pode consultar os limites de utilização do seu plano, clique no botão para visualizar."
                noDivider
              />
              <NoStylesLink>
                <Link
                  to={`${localStorage.getItem(
                    'basename',
                  )}/info/limites-utilizacao`}
                >
                  <Button
                    text="Visualizar limites"
                    variant="contained"
                    color="primary"
                    startIcon={<FormatAlignLeftIcon />}
                    fullWidth={false}
                  />
                </Link>
              </NoStylesLink>
            </Grid>
          </Page>
        </Grid>
        <Grid item sm={12} md={12}>
          <Page
            title="Reajuste de valores"
            icon={ShowChartIcon}
            type="Borderless"
          >
            <Grid item sm={12} md={12}>
              <TablePanel>
                <TableBody>
                  {state.successApiReajusteTabelaPreco
                    ? renderBodyTableReajusteTabelaPreco()
                    : renderDefaultErrorReajuste}
                </TableBody>
              </TablePanel>
              <TablePanel>
                <TableBody>
                  {state.successApiReajusteFaixaEtaria
                    ? renderBodyTableReajusteFaixaEtaria()
                    : renderDefaultErrorReajuste}
                </TableBody>
              </TablePanel>
            </Grid>
          </Page>
        </Grid>
        {state.successApiHistoricoPlanos ? (
          <Grid item sm={12} md={12}>
            <Page
              title="Histórico de Planos"
              icon={TimelineIcon}
              type="Borderless"
            >
              <Grid container>
                <TablePanel>
                  <TableBody>
                    {state.successApiHistoricoPlanos
                      ? renderBodyTableHistoricoPlano()
                      : renderDefaultErrorHistorico}
                  </TableBody>
                </TablePanel>
              </Grid>
            </Page>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}
