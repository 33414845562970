/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import Form from '@solussaude/core/CustomForm';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import TableModal from '@solussaude/core/Helpers/TableModal';
import ActionButton from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import WebContext from '@solussaude/core/WebContext';
import {
  AccountBalanceIcon,
  CloseIcon,
  SearchIcon,
} from '@solussaude/core/Icons';
import SolusAPI from '../../../../../services/SolusApi';
import {
  formDadosInstrumentador,
  formInstrumentador,
  tableInstrumentadores,
} from './settings';
import { Container } from './styles';

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

let formInstrumentadorValues;
const getInstrumentadorFormValues = () => formInstrumentadorValues;
const setInstrumentadorFormValues = (newValue) => {
  formInstrumentadorValues = newValue;
};

let mappingFormInstrumentador;
const getMappingFormInstrumentador = () => mappingFormInstrumentador;
const setMappingFormInstrumentador = (pGetMapping) => {
  mappingFormInstrumentador = pGetMapping;
};

export default function DadosInstrumentador({ setMapping, getMapping }) {
  setMapping({
    ...getMapping(),
    getMappingFormInstrumentador,
    getFormDadosInstrumentador: getFormValues,
  });

  const webContext = React.useContext(WebContext);

  const [state, setState] = React.useState({
    tipoPessoa: 'PF',
    cpf: '',
    cnpj: '',
    prestador: { selecionado: '', descricao: [] },
    nomeInstrumentador: '',
    loading: true,
  });

  React.useEffect(() => {
    const init = async () => {
      const dados = SolusAPI.get(
        'v1/prestador',
        {},
        { appName: 'api_prestador' },
      );

      setState({
        ...state,
        loading: false,
        prestador: { selecionado: '', descricao: dados.data },
      });
    };
    init();
  }, []);

  const handleClose = () => {
    webContext.showModal({
      open: false,
    });
  };

  const ListagemInstrumentador = () => {
    const [listagem, setListagem] = React.useState({
      prestador: { selecionado: '', descricao: [] },
      cpf: '',
      loading: true,
      qtdRegistro: 10,
      qtdRequisicao: 0,
      tipoBusca: 'nome',
      parametro: '',
      render: (
        <>
          <TableModal tableSettings={tableInstrumentadores([])} />
        </>
      ),
    });

    React.useEffect(() => {
      const init = async () => {
        const dadosInstrumentadores = await SolusAPI.get(
          'v1/prestador',
          {
            tipobusca: listagem.tipoBusca,
            parametro: listagem.parametro,
            quantidaderegistro: listagem.qtdRegistro,
          },
          { appName: 'api_prestador' },
        );

        setListagem({
          ...listagem,
          prestador: { selecionado: '', descricao: dadosInstrumentadores.data },
          loading: false,
          render: (
            <>
              <TableModal
                tableSettings={tableInstrumentadores(
                  dadosInstrumentadores.data,
                )}
                refGetMapping={getMapping}
                idElemChange="nomeInstrumentador"
                element="nome"
                handleClick={(row) => handleClickInstrumentador(row)}
              />
            </>
          ),
        });
      };
      init();
    }, []);

    // NÃO TEM
    React.useEffect(
      () =>
        setListagem({
          ...listagem,
          loading: false,
          qtdRegistro: 10,
          qtdRequisicao: 0,
        }),
      [listagem.tipoBusca],
    );

    const handleClickInstrumentador = (row) => {
      const formMap = getMapping().getMappingFormInstrumentador()();

      formMap.nomeInstrumentador.setValues(row.nome);
      if (formMap.tipoPessoa.self.elem_val === 'PF') {
        formMap.cpf.setValues(row.cpf);
      } else {
        formMap.cnpj.setValues(row.cnpj);
      }

      webContext.showModal({
        open: false,
      });
    };

    // NÃO TEM
    const changePesquisa = () => {
      const { tipoBusca, parametro } = getInstrumentadorFormValues();

      setListagem({ ...listagem, tipoBusca, parametro });
    };

    const handleInstrumentador = async () => {
      const { tipoBusca, parametro } = getInstrumentadorFormValues();

      let dadosInstrumentadores = {};

      if (tipoBusca.includes('nome')) {
        dadosInstrumentadores = await SolusAPI.get(
          'v1/prestador',
          {
            nomeContendo: parametro,
          },
          { appName: 'api_prestador' },
        );
      } else {
        dadosInstrumentadores = await SolusAPI.get(
          'v1/prestador',
          {
            tipobusca: tipoBusca,
            parametro,
          },
          { appName: 'api_prestador' },
        );
      }

      setListagem({
        ...listagem,
        prestador: { selecionado: '', descricao: dadosInstrumentadores.data },
        render: (
          <>
            <TableModal
              tableSettings={tableInstrumentadores(dadosInstrumentadores.data)}
              refGetMapping={getMapping}
              idElemChange="nomeInstrumentador"
              element="nome"
              handleClick={(row) => handleClickInstrumentador(row)}
            />
          </>
        ),
      });
    };

    if (listagem.loading) return <CircularIndeterminateModal />;

    return (
      <>
        <Container>
          <BodyInfo
            icon={<SearchIcon />}
            title="Pesquise pelo instrumentador"
          />
          <br />
          <Form
            settings={formInstrumentador(listagem, changePesquisa)}
            setValues={setInstrumentadorFormValues}
          />
          <br />
          <br />
          <ActionButton direction="space-between">
            <Button
              variant="outlined"
              color="secondary"
              text="Fechar"
              startIcon={<CloseIcon />}
              onClick={() => handleClose()}
            />
            <Button
              variant="contained"
              color="primary"
              // text="Procurar"
              text={
                listagem.qtdRequisicao === 0 ? 'Procurar' : 'Mais registros'
              }
              startIcon={<SearchIcon />}
              onClick={() => handleInstrumentador()}
            />
          </ActionButton>
          {listagem.render}
        </Container>
      </>
    );
  };

  const onClick = () => {
    webContext.showModal({
      open: true,
      renderBody: <ListagemInstrumentador />,
    });
  };

  const handleTipoPessoa = (e) => {
    const { cpf, cnpj, nomeInstrumentador } = getFormValues();
    setState({
      ...state,
      tipoPessoa: e.value,
      cpf,
      cnpj,
      prestador: {
        selecionado: nomeInstrumentador,
        descricao: state.prestador.descricao,
      },
      nomeInstrumentador,
    });
  };

  return (
    <>
      <BodyInfo
        title="Dados do instrumentador"
        icon={<AccountBalanceIcon />}
        noDivider
      />
      <Form
        settings={formDadosInstrumentador(state, onClick, handleTipoPessoa)}
        setValues={setFormValues}
        setMappingForm={setMappingFormInstrumentador}
      />
    </>
  );
}
