/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { DriveEtaIcon } from '@solussaude/core/Icons';
import Grid from '@solussaude/core/Grid';
import Form from '@solussaude/core/CustomForm';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import SolusAPI from '../../../../services/SolusApi';
import defaultTheme from '../../../../styles/themes/default';
import Anexos from '../Anexos';
import { formDeslocamento } from './settings';

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

let dataValues;
const getDataValues = () => dataValues;
const setDataValues = (newValue) => {
  dataValues = newValue;
};

let formValuesFormDeslocamento;
const getMappingFormDeslocamento = () => formValuesFormDeslocamento;
const setMappingFormDeslocamento = (newValue) => {
  formValuesFormDeslocamento = newValue;
};

let dataValueDeslocamento;
const getMappingFormDeslocamentoData = () => dataValueDeslocamento;
const setMappingFormDeslocamentoData = (newValue) => {
  dataValueDeslocamento = newValue;
};

export default function FormularioDeslocamento({
  setMapping,
  getMapping,
  tipoSolicitacao,
}) {
  const theme = defaultTheme;

  const [state, setState] = React.useState({
    loading: true,
    estadoOrigem: { selecionado: '', descricao: [] },
    cidadeOrigem: { selecionado: '', descricao: [] },
    estadoDestino: { selecionado: '', descricao: [] },
    cidadeDestino: { selecionado: '', descricao: [] },
    meioUtilizado: '',
    dataViagem: '',
    motivo: '',
    valorPedagio: 0,
  });

  setMapping({
    ...getMapping(),
    getFormDeslocamento: getFormValues,
    getFormDeslocamentoData: getDataValues,
    getMappingFormDeslocamento,
    getMappingFormDeslocamentoData,
  });

  React.useEffect(() => {
    const init = async () => {
      const dadosEstado = await SolusAPI.get(
        'v1/dominio/estado',
        {},
        { appName: 'api_comercial' },
      );

      if (dadosEstado.status === 200) {
        const dadosCidade = await SolusAPI.get(
          'v1/cidade',
          {
            uf: dadosEstado.data[0].codigo,
          },
          { appName: 'api_comercial' },
        );

        setState({
          loading: false,
          estadoOrigem: { selecionado: '', descricao: dadosEstado.data },
          cidadeOrigem: { selecionado: '', descricao: dadosCidade.data },
          estadoDestino: { selecionado: '', descricao: dadosEstado.data },
          cidadeDestino: { selecionado: '', descricao: dadosCidade.data },
        });
      }
    };
    init();
  }, []);

  const handleEstado = async (e, tipo) => {
    const { meioUtilizado, motivo, cidadeDestino, cidadeOrigem, dataViagem } =
      getFormValues();

    const dadosCidade = await SolusAPI.get(
      'v1/cidade',
      {
        uf: e.value,
      },
      { appName: 'api_comercial' },
    );

    if (tipo === 'destino') {
      setState({
        ...state,
        cidadeDestino: { selecionado: '', descricao: dadosCidade.data },
        estadoDestino: {
          selecionado: e.value,
          descricao: state.estadoDestino.descricao,
        },
        cidadeOrigem: {
          selecionado: cidadeOrigem?.key,
          descricao: state.cidadeOrigem.descricao,
        },
        meioUtilizado,
        valorPedagio: 0,
        dataViagem,
        motivo,
      });
    } else {
      setState({
        ...state,
        cidadeOrigem: { selecionado: '', descricao: dadosCidade.data },
        estadoOrigem: {
          selecionado: e.value,
          descricao: state.estadoOrigem.descricao,
        },
        cidadeDestino: {
          selecionado: cidadeDestino?.key,
          descricao: state.cidadeDestino.descricao,
        },
        meioUtilizado,
        valorPedagio: 0,
        dataViagem,
        motivo,
      });
    }
  };

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Grid item sm={12} md={12}>
            <BodyInfo title="Deslocamentos" icon={<DriveEtaIcon />} noDivider />
            <Form
              settings={formDeslocamento(state, handleEstado)}
              setValues={setFormValues}
              setMappingForm={setMappingFormDeslocamento}
            />
            <br />
            <Anexos
              setMapping={setMapping}
              getMapping={getMapping}
              tipoSolicitacao={tipoSolicitacao}
            />
            <br />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
