import SolusAPI from '@solussaude/core/Service';
import { logoff } from './auth';

const Request = async ({ method, path, params, config }) => {
  let req;

  if (method === 'get') req = await SolusAPI.get(path, params, config);
  else if (method === 'post') req = await SolusAPI.post(path, params, config);
  else if (method === 'put') req = await SolusAPI.put(path, params, config);
  else if (method === 'delete') req = await SolusAPI.delete(path, config);

  if (req.status === 200 || req.status === 201 || req.status === 202) {
    return {
      data: req.data,
      status: req.status,
      success: true,
    };
  }

  if (req.status === 401) {
    logoff();
    return Promise.reject();
  }

  return {
    erro: {
      mensagem: req.data.messageAPI.mensagem || req.data.message,
    },
    status: req.status,
    success: false,
  };
};

export default Request;
