import { processData } from '@solussaude/core/Utils';

export const temp = {};
export const renderForm = (apiData) => ({
  form: [
    {
      label: 'Nome do form',
      inputs: [
        [
          // Row
          {
            elem_id: 'select_ano',
            type: 'Select',
            label: 'Ano base',
            value: processData(apiData, 'ano', 'ano'),
          },
        ],
      ],
    },
  ],
});
