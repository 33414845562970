/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import {
  AssignmentIcon,
  CheckCircleIcon,
  MessageIcon,
  SendIcon,
} from '@solussaude/core/Icons';
import Grid from '@solussaude/core/Grid';
import Page from '@solussaude/core/Surfaces/Page';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import TextField from '@solussaude/core/Inputs/TextField';
import Message from '@solussaude/core/Helpers/Message';
import Table from '@solussaude/core/DataDisplay/TableSimple';
import IconButton from '@solussaude/core/Inputs/IconButton';
import MensConfirmacao from '@solussaude/core/Helpers/MensConfirmacao';
import ActionButtons from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import WebContext from '@solussaude/core/WebContext';
import { useLocation } from '@solussaude/core/Router';
import SolusAPI from '../../../../services/SolusApi';
import defaultTheme from '../../../../styles/themes/default';
import { renderMensagens, dadosAtendimento } from './settings';
import { Container, ContainerInput } from './styles';

export default function Detalhes() {
  const theme = defaultTheme;
  const webContext = React.useContext(WebContext);

  const location = useLocation();

  const { id } = location.state;
  const [state, setState] = React.useState({
    loading: true,
    apiData: [],
    successApi: true,
    apiDataAtend: [],
    successApiAtend: true,
    reload: false,
  });

  let formValues;
  const getFormValues = () => formValues;
  const setFormValues = (newValue) => {
    formValues = newValue;
  };

  React.useEffect(() => {
    const init = async () => {
      const dados = await SolusAPI.post(
        'api/beneficiarios/mensagens-atendimento',
        {
          id,
        },
      );
      const dadosAtend = await SolusAPI.post(
        'api/beneficiarios/dados-atendimento',
        {
          atendimento: id,
        },
      );

      setState({
        loading: false,
        apiData: dados.data,
        successApi: dados.success,
        apiDataAtend: dadosAtend.data,
        successApiAtend: dadosAtend.success,
      });
    };
    init();
  }, [state.reload]);

  const handleClose = () => {
    webContext.showModal({
      open: false,
    });

    setState({ ...state, reload: true });
  };

  const handleEnviar = async () => {
    const { assunto } = getFormValues();
    const dados = await SolusAPI.post('api/beneficiarios/insere-mensagem', {
      assunto,
      idAtendimento: id,
    });

    if (Object.keys(dados.data).length === 0) {
      webContext.showModal({
        open: true,
        renderBody: (
          <MensConfirmacao
            IconTitle={<CheckCircleIcon />}
            title="Mensagem enviada com sucesso!"
            description={
              <p>
                Sua mensagem foi enviada com sucesso, aguarde o retorno da
                operadora
              </p>
            }
            handleClick={handleClose}
          />
        ),
      });
    } else {
      webContext.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<CheckCircleIcon color="primary" />}
              body={dados.data.retMessage}
              noDivider
            />
            <ActionButtons direction="flex-end">
              <Button
                text="Fechar"
                variant="contained"
                color="secondary"
                fullWidth={false}
                onClick={() => handleClose()}
              />
            </ActionButtons>
          </>
        ),
      });
    }
  };

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page title={`Chamado ${id}`} icon={AssignmentIcon}>
            <Grid item sm={12} md={12}>
              {state.loading === false ? (
                <Table
                  settings={dadosAtendimento(state.apiDataAtend)}
                  inLoading={state.loading}
                />
              ) : null}
              <Container>
                <BodyInfo
                  icon={<MessageIcon color="primary" />}
                  title="Mensagens"
                  body="Abaixo estão as mensagens relacionadas ao chamado."
                />
                <Message settings={renderMensagens(state.apiData)} />
                <ContainerInput>
                  <TextField
                    data={{
                      elem_id: 'assunto',
                      type: 'InputText',
                      fullWidth: false,
                      variant: 'outlined',
                    }}
                    refGetValues={getFormValues}
                    refSetValues={setFormValues}
                  />
                  <IconButton
                    style={{ marginTop: 32, marginBottom: 8 }}
                    onClick={() => handleEnviar()}
                  >
                    <SendIcon color="primary" />
                  </IconButton>
                </ContainerInput>
              </Container>
            </Grid>
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
