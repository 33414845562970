import React from 'react';
import Button from '@solussaude/core/Inputs/Button';

import { CloseIcon, SendIcon } from '@solussaude/core/Icons';

export const temp = {};

export const renderForm = (
  handleBlur,
  mapping,
  clickTermos,
  handleCloseModal,
  handleClick,
) => ({
  form: [
    {
      inputs: [
        [
          // Row
          {
            // Column
            elem_id: 'email',
            type: 'InputText',
            label: 'E-mail',
            required: true,
            onBlur: (elem) => handleBlur(elem, mapping),
          },
          {
            // Column
            elem_id: 'email_repetir',
            type: 'InputText',
            label: 'Confirmar E-mail',
            required: true,
            onBlur: (elem) => handleBlur(elem, mapping),
          },
        ],
        [
          {
            // Column
            elem_id: 'email_2',
            type: 'InputText',
            label: '2º E-mail',
            onBlur: (elem) => handleBlur(elem, mapping),
          },
          {
            // Column
            elem_id: 'email_2_repetir',
            type: 'InputText',
            label: 'Confirmar 2º E-mail',
            onBlur: (elem) => handleBlur(elem, mapping),
          },
        ],
        [
          // Row
          {
            // Column
            elem_id: 'celular',
            type: 'InputMask',
            typeMask: 'celular',
            required: true,
            label: 'Celular',
            onBlur: (elem) => handleBlur(elem, mapping),
            flex: 6,
          },
        ],
        [
          {
            elem_id: 'termos',
            type: 'Checkbox',
            label: (
              <>
                <p>
                  Li e concordo com os{' '}
                  <Button
                    onClick={() => clickTermos()}
                    text="Termos de serviço"
                    color="primary"
                    fullWidth={false}
                  />
                </p>
              </>
            ),
            required: true,
          },
        ],
        [
          {
            elem_id: 'btnClose',
            type: 'Button',
            text: 'Fechar',
            fullWidth: true,
            color: 'secondary',
            icon: <CloseIcon />,
            variant: 'outlined',
            onClick: () => handleCloseModal(),
          },
          {
            elem_id: 'btn',
            type: 'Button',
            text: 'Confirmar',
            fullWidth: true,
            endIcon: <SendIcon />,
            color: 'primary',
            variant: 'contained',
            onClick: () => handleClick(),
          },
        ],
      ],
    },
  ],
});
