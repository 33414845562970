/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import {
  PlaceIcon,
  WarningIcon,
  CheckCircleIcon,
} from '@solussaude/core/Icons';
import Grid from '@solussaude/core/Grid';
import Divider from '@solussaude/core/Divider';
import Page from '@solussaude/core/Surfaces/Page';
import Form from '@solussaude/core/CustomForm';
import FormEndereco from '@solussaude/core/Utils/FormEndereco';
import TableCRUD from '@solussaude/core/DataDisplay/TableCRUD';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import ActionButtons from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import { isPromise } from '@solussaude/core/Utils';
import WebContext from '@solussaude/core/WebContext';
import ChangePassword from '@solussaude/core/ChangePassword';
import SolusAPI from '../../services/SolusApi';
import defaultTheme from '../../styles/themes/default';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import { ContentDisabled, Centralized } from './styles';
import { formMeusDados, tableTelefone, tableEmail } from './settings';

export default function Profile() {
  const context = React.useContext(WebContext);
  const theme = defaultTheme;

  const [state, setState] = React.useState({
    displayEnder: 'none',
    loading: true,
    successApiPlano: true,
    apiDataPlano: [],
    apiDataAttDados: false,
    successApiTelefone: true,
    apiDataTelefone: [],
    successApiTipoTelefone: true,
    apiDataTipoTelefone: [],
    successApiEmail: true,
    apiDataEmail: [],
    successApiEstado: true,
    apiDataEstado: [],
    successApiNacionalidade: true,
    apiDataNacionalidade: [],
    successApiOrgaoE: true,
    apiDataOrgaoE: [],
    successApiEndereco: true,
    apiDataEndereco: [],
    apiDataTitu: [],
    podeAlterarSenha: true,
    infoUsuario: true,
    reload: false,
  });

  let formMeusDadosValue;
  const getFormMeusDados = () => formMeusDadosValue;
  const setFormMeusDados = (newValue) => {
    formMeusDadosValue = newValue;
  };

  let formEnderecoValue;
  const getFormEndereco = () => formEnderecoValue;

  let mapping;
  const getMapping = () => mapping;
  const setMapping = (newMapping) => {
    mapping = newMapping;
  };

  let mappingForm;
  const getMappingForm = () => mappingForm;
  const setMappingForm = (pGetMapping) => {
    mappingForm = pGetMapping;
  };

  let mappingTelefone;
  const getMappingTelefone = () => mappingTelefone;
  const setMappingTelefone = (pGetMapping) => {
    mappingTelefone = pGetMapping;
  };

  const search = async () => {
    context.module.beneficiario.userAuth = SolusAPI.get('api/beneficiarios');
    const userAuth = await isPromise(context.module.beneficiario.userAuth);
    const AttDados = await SolusAPI.get(
      'api/beneficiarios/pode-atualizar-dados',
    );
    const dadosTitu = await SolusAPI.post('api/beneficiarios/find', {
      id: userAuth.id_titular,
    });
    const dadosT = await SolusAPI.post('api/beneficiarios/lista-telefones');
    const dadosTt = await SolusAPI.get('api/settings/tipo-telefone');
    const dadosE = await SolusAPI.post('api/beneficiarios/lista-email', {
      tipo: 'T',
    });
    const dadosEn = await SolusAPI.get('api/beneficiarios/lista-endereco');
    const dadosEc = await SolusAPI.get('api/settings/estado-civil');
    const dadosN = await SolusAPI.get('api/settings/nacionalidade');
    const dadosOe = await SolusAPI.get('api/settings/orgao-emissor');
    const dadosAlteraSenha = await SolusAPI.get('api/settings/altera-senha');
    const dadosInfoBeneficiario = await SolusAPI.get(
      'api/settings/info-usuario',
    );
    setState({
      ...state,
      loading: false,
      apiDataPlano: userAuth,
      successApiPlano: userAuth.success === undefined,
      apiDataAttDados: AttDados.data === 'S',
      apiDataTelefone: dadosT.data,
      successApiTelefone: dadosT.success,
      apiDataTipoTelefone: dadosTt.data,
      successApiTipoTelefone: dadosTt.success,
      apiDataEmail: dadosE.data,
      successApiEmail: dadosE.success,
      successApiEstado: dadosEc.success,
      apiDataEstado: dadosEc.data,
      successApiNacionalidade: dadosN.success,
      apiDataNacionalidade: dadosN.data,
      successApiOrgaoE: dadosOe.success,
      apiDataOrgaoE: dadosOe.data,
      successApiEndereco: dadosEn.success,
      apiDataEndereco: dadosEn.data,
      apiDataTitu: dadosTitu.data?.length > 0 ? dadosTitu.data[0] : [],
      podeAlterarSenha: dadosAlteraSenha.data === 'S',
      infoUsuario: dadosInfoBeneficiario.data === 'S',
    });
  };

  React.useEffect(() => {
    search();
  }, [state.reload]);

  const handleClose = () => {
    context.showModal({
      open: false,
    });
  };

  const onClickEnviarMeusDados = async () => {
    const {
      nomeMae,
      nomePai,
      pis,
      cpf,
      rg,
      orgaoEmissor,
      dataEmissao,
      paisEmissor,
      estadoCivil,
    } = getFormMeusDados();

    if (
      !nomeMae ||
      !nomePai ||
      !cpf ||
      !rg ||
      !orgaoEmissor ||
      !dataEmissao ||
      !paisEmissor ||
      !estadoCivil
    ) {
      context.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<WarningIcon color="error" />}
              title="Erro"
              body="É necessário preencher todos os campos!"
              noDivider
            />
            <ActionButtons direction="flex-end">
              <Button
                text="Fechar"
                variant="contained"
                color="secondary"
                fullWidth={false}
                onClick={() => handleClose()}
              />
            </ActionButtons>
          </>
        ),
      });
      return;
    }

    const dados = await SolusAPI.post(
      'api/beneficiarios/solicita-alteracao-dados',
      {
        nomeMae,
        nomePai,
        cpf,
        pis,
        rg,
        orgaoEmissor: orgaoEmissor.key,
        dataEmissao,
        paisEmissor: paisEmissor.key,
        estadoCivil,
        id_contrato: state.apiDataPlano.id_contrato,
        id_titular: state.apiDataPlano.id_titular,
      },
    );

    if (dados.data.retMessage === null) {
      context.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<CheckCircleIcon color="primary" />}
              title="Solicitação concluída com sucesso!"
              body="A solicitação de alteração dos seus dados foi concluída com sucesso!"
              noDivider
            />
            <ActionButtons direction="flex-end">
              <Button
                text="Concluir"
                variant="contained"
                color="primary"
                fullWidth={false}
                onClick={() => handleClose()}
              />
            </ActionButtons>
          </>
        ),
      });
    } else {
      context.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<WarningIcon color="error" />}
              title="Erro"
              body={dados.data.retMessage}
              noDivider
            />
            <ActionButtons direction="flex-end">
              <Button
                text="Fechar"
                variant="contained"
                color="secondary"
                fullWidth={false}
                onClick={() => handleClose()}
              />
            </ActionButtons>
          </>
        ),
      });
    }

    setState({ ...state, reload: true, loading: true });
  };

  const renderTableEmail = () => {
    if (state.successApiEmail) {
      return (
        <TableCRUD
          settings={tableEmail(state.apiDataEmail)}
          inLoading={state.loading}
        />
      );
    }
  };

  const renderForm = () => {
    if (state.loading === false) {
      return (
        <ContentDisabled>
          <Form
            settings={formMeusDados(
              { onClickEnviarMeusDados },
              state.apiDataPlano,
              state.apiDataEstado,
              state.apiDataNacionalidade,
              state.apiDataOrgaoE,
              state.apiDataAttDados,
            )}
            setValues={setFormMeusDados}
            setMappingForm={setMappingForm}
            inLoading={state.loading}
            key={0}
          />
        </ContentDisabled>
      );
    }
  };

  setMapping({
    ...getMapping(),
    getMappingForm,
    getFormEndereco,
  });

  const renderFormEnd = () => (
    <FormEndereco theme={theme} type="Perfil" button="show" />
  );

  const renderDefaultError = () => (
    <>
      <BodyInfo
        icon={<WarningIcon color="error" />}
        title="Opss..."
        body="Não foi possivel carregar seus dados, tente novamente mais tarde."
        noDivider
      />
    </>
  );

  const renderDefaultErrorEndereco = () => (
    <>
      <BodyInfo
        icon={<WarningIcon color="error" />}
        title="Opss..."
        body="Não foi possivel carregar seu endereço, tente novamente mais tarde."
        noDivider
      />
    </>
  );
  const renderDefaultErrorTelefone = () => (
    <>
      <BodyInfo
        icon={<WarningIcon color="error" />}
        title="Opss..."
        body="Não foi possivel carregar seus telefones, tente novamente mais tarde."
        noDivider
      />
    </>
  );
  const renderDefaultErrorEmail = () => (
    <>
      <BodyInfo
        icon={<WarningIcon color="error" />}
        title="Opss..."
        body="Não foi possivel carregar seus email, tente novamente mais tarde."
        noDivider
      />
    </>
  );

  const Telefones = () => {
    const [tel, setTel] = React.useState({
      loading: true,
      apiData: [],
      successApiTelefone: true,
      reload: false,
    });

    React.useEffect(() => {
      const init = async () => {
        const dadosT = await SolusAPI.post('api/beneficiarios/lista-telefones');
        setTel({
          loading: false,
          apiData: dadosT.data,
          successApiTelefone: dadosT.success,
        });
      };
      init();
    }, []);

    const onClick = async () => {
      setTel({
        ...tel,
        loading: true,
      });
      const dadosT = await SolusAPI.post('api/beneficiarios/lista-telefones');
      setTel({
        loading: false,
        apiData: dadosT.data,
        successApiTelefone: dadosT.success,
      });
    };

    if (tel.loading)
      return (
        <Centralized>
          {' '}
          <CircularIndeterminateModal />{' '}
        </Centralized>
      );

    if (tel.successApiTelefone && tel.loading === false) {
      return (
        <TableCRUD
          settings={tableTelefone(
            tel.apiData,
            state.apiDataTipoTelefone,
            onClick,
          )}
          inLoading={tel.loading}
          refSetMapping={setMappingTelefone}
          refGetMapping={getMappingTelefone}
        />
      );
    }
    return renderDefaultErrorTelefone();
  };

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={6}>
          <Page title="Meus dados" icon={PlaceIcon}>
            <Grid item sm={12} md={12}>
              {state.successApiPlano ? renderForm() : renderDefaultError()}
              <Divider />
            </Grid>
          </Page>
        </Grid>

        <Grid item sm={12} md={6}>
          <Page title="Endereço" icon={PlaceIcon}>
            <Grid item sm={12} md={12}>
              {state.successApiPlano
                ? renderFormEnd()
                : renderDefaultErrorEndereco()}
              <Divider />
            </Grid>
          </Page>
        </Grid>

        <Grid item sm={12} md={6}>
          <Page title="Contato" icon={PlaceIcon}>
            <Grid item sm={10} md={10}>
              <Telefones />
              <br />
              <Divider />
              <br />
              {state.successApiEmail
                ? renderTableEmail()
                : renderDefaultErrorEmail()}
              <br />
              <Divider />
            </Grid>
          </Page>
        </Grid>

        <Grid item sm={12} md={12}>
          {state.podeAlterarSenha ? <ChangePassword /> : null}
        </Grid>
      </Grid>
    </>
  );
}
