import React from 'react';
import { processData } from '@solussaude/core/Utils';

export const cancelamento = {
  steps: [
    'Informações sobre o cancelamento',
    'Informações para contato',
    'Informações para o cancelamento',
    'Confirmação',
  ],
  stepContent: [
    {
      title:
        'Saiba sobre as Consequências do Cancelamento ou Exclusão do Contrato de Plano de Saúde:',
      text: (
        <>
          <span>Art. 15 da RN 561/22</span>
          <div>
            <p style={{ marginBottom: `${15}px` }}>
              I - eventual ingresso em novo plano de saúde poderá importar:
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              a) no cumprimento de novos períodos de carência, observado o
              disposto no inciso V do artigo 12, da Lei nº 9.656, de 3 de junho
              de 1998;
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              b) na perda do direito à portabilidade de carências, caso não
              tenha sido este o motivo do pedido, nos termos previstos na RN nº
              186, de 14 de janeiro de 2009, que dispõe, em especial, sobre a
              regulamentação da portabilidade das carências previstas no inciso
              V do art. 12 da Lei nº 9.656, de 3 de junho de 1998;
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              c) no preenchimento de nova declaração de saúde, e, caso haja
              doença ou lesão preexistente - DLP, no cumprimento de Cobertura
              Parcial Temporária - CPT, que determina, por um período
              ininterrupto de até 24 meses, a partir da data da contratação ou
              adesão ao novo plano, a suspensão da cobertura de Procedimentos de
              Alta Complexidade (PAC), leitos de alta tecnologia e procedimentos
              cirúrgicos;
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              d) na perda imediata do direito de remissão, quando houver,
              devendo o beneficiário arcar com o pagamento de um novo contrato
              de plano de saúde que venha a contratar;
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              II - efeito imediato e caráter irrevogável da solicitação de
              cancelamento do contrato ou exclusão de beneficiário, a partir da
              ciência da operadora ou administradora de benefícios;
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              III - as contraprestações pecuniárias vencidas e/ou eventuais
              coparticipações devidas, nos planos em pré-pagamento ou em
              pós-pagamento, pela utilização de serviços realizados antes da
              solicitação de cancelamento ou exclusão do plano de saúde são de
              responsabilidade do beneficiário;
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              IV - as despesas decorrentes de eventuais utilizações dos serviços
              pelos beneficiários após a data de solicitação de cancelamento ou
              exclusão do plano de saúde, inclusive nos casos de urgência ou
              emergência, correrão por sua conta;
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              {' '}
              V - a exclusão do beneficiário titular do contrato individual ou
              familiar não extingue o contrato, sendo assegurado aos dependentes
              já inscritos o direito à manutenção das mesmas condições
              contratuais, com a assunção das obrigações decorrentes; e
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              {' '}
              VI – a exclusão do beneficiário titular do contrato coletivo
              empresarial ou por adesão observará as disposições contratuais
              quanto à exclusão ou não dos dependentes, conforme o disposto no
              inciso II do parágrafo único do artigo 18, da RN nº 195, de 14 de
              julho de 2009, que dispõe sobre a classificação e características
              dos planos privados de assistência à saúde, regulamenta a sua
              contratação, institui a orientação para contratação de planos
              privados de assistência à saúde e dá outras providências.
            </p>
          </div>
        </>
      ),
      checkbox:
        'Li e confirmo estar ciente das consequências do cancelamento ou exclusão do Contrato do Plano de Saúde.',
    },
    {
      text: (
        <>
          <p>
            {' '}
            No caso de manutenção dos dependentes, o responsável financeiro tem
            acesso via sistema das informações dos atendimentos prestados até
            que os dependentes atinjam a maioridade; após este prazo, em razão
            da manutenção do sigilo das informações, o acesso à relação dos
            atendimentos prestados somente será franqueado após autorização do
            beneficiário.
          </p>

          <p>
            É de responsabilidade do beneficiário titular/titular financeiro a
            solicitação de cancelamento, a operadora não se responsabiliza caso
            outra pessoa solicite no lugar do mesmo.
          </p>
        </>
      ),
      checkbox:
        'Confirmo estar ciente e de acordo com minhas responsabilidades.',
    },
  ],
};

const processMotivo = (apiData) => {
  const array = [];

  if (apiData) {
    if (apiData.length > 0) {
      apiData.forEach((res) => {
        array.push({
          key: res.id_motivo,
          name: res.descricao,
          type: res.motivo_troca_operadora,
        });
      });
    }
  }
  return array;
};

export const renderDados = (dados) => ({
  header: {
    description: 'Informações para contato.',
  },
  form: [
    {
      label: 'Informações para contato',
      inputs: [
        [
          {
            elem_id: 'telefone',
            elem_val: dados.telefone,
            type: 'InputMask',
            maskOnRequest: false,
            typeMask: 'celular',
            label: 'Telefone',
            required: true,
          },
          {
            elem_id: 'email',
            elem_val: dados.email,
            type: 'InputText',
            label: 'E-mail',
            required: true,
          },
        ],
      ],
    },
  ],
});

export const renderCancelamento = (
  changeTipo,
  changeMotivo,
  changeDependente,
  dados,
) => {
  const operadora =
    dados.operadora.descricao.length > 0
      ? [
          {
            // Column
            elem_id: 'operadora',
            elem_val: dados.operadora.selecionado || 0,
            type: 'Autocomplete',
            label: 'Operadora',
            options: processData(
              dados.operadora.descricao,
              'id_operadora',
              'descricao',
            ),
            labelOption: (saida) => saida.name || 'Selecione uma opção',
            fullWidth: true,
          },
        ]
      : null;
  const dependentes =
    dados.dependentes.descricao.length > 0
      ? [
          {
            // Column
            elem_id: 'dependentes',
            elem_val: dados.dependentes.selecionado,
            type: 'Select',
            multiple: true,
            label: 'Dependentes',
            value: processData(dados.dependentes.descricao, 'id', 'nome'),
            onChange: () => changeDependente(),
          },
        ]
      : null;

  return {
    header: {
      description: 'Informações para o cancelamento.',
    },
    form: [
      {
        label: 'Informações para contato.',
        inputs: [
          [
            {
              // Column
              elem_id: 'motivo',
              elem_val: dados.motivo.selecionado,
              type: 'Select',
              label: 'Motivo',
              value: processMotivo(dados.motivo.descricao),
              onChange: (e) => changeMotivo(e, dados.motivo),
            },
          ],
          operadora,
          [
            {
              elem_id: 'tipo',
              elem_val: dados.tipo.selecionado,
              type: 'RadioButton',
              label: 'Selecione o tipo de cancelamento',
              value: processData(dados.tipo.descricao, 'tipo_flag', 'tipo'),
              onChange: () => changeTipo(dados.tipo),
            },
          ],
          dependentes,
        ].filter((elem) => !!elem),
      },
    ],
  };
};

export const dadosConfirmacao = (apiData) => ({
  table: {
    options: {
      title: 'Meu plano',
      type: 'headerAside',
      small: true,
    },
    data: [
      [
        {
          label: 'Solicitante',
          value: apiData.beneficiario.nome,
        },
      ],
      [
        {
          label: 'Número do contrato',
          value: apiData.numeroContrato,
        },
      ],
      [
        {
          label: 'Produto ANS',
          value: apiData.plano.registro_ans,
        },
      ],
      [
        {
          label: 'Nome do plano',
          value: apiData.plano.nome,
        },
      ],
    ],
  },
});
