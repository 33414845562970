/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import Grid from '@solussaude/core/Grid';
import { WarningIcon, ShowChartIcon } from '@solussaude/core/Icons';
import TableCRUD from '@solussaude/core/DataDisplay/TableCRUD';
import Page from '@solussaude/core/Surfaces/Page';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import { isPromise } from '@solussaude/core/Utils';
import WebContext from '@solussaude/core/WebContext';
import SolusAPI from '../../../services/SolusApi';

import defaultTheme from '../../../styles/themes/default';
import { TablePanel, TableBody } from './styles';
import { dadosCarencia } from './settings';

export default function PrazoCarencia() {
  const theme = defaultTheme;
  const context = React.useContext(WebContext);

  const [state, setState] = React.useState({
    loading: true,
    successApiCarencia: true,
    apiDataCarencia: [],
  });

  React.useEffect(() => {
    const search = async () => {
      const userAuth = await isPromise(context.module.beneficiario.userAuth);

      const dadosC = await SolusAPI.get('api/beneficiarios/carencias', {
        id: userAuth.id,
      });

      setState({
        loading: false,
        successApiCarencia: dadosC.success,
        apiDataCarencia: dadosC.data,
      });
    };

    search();
  }, []);

  const renderBodyTableCarencias = () => {
    if (state.apiDataCarencia.length === 0) {
      return (
        <TableCRUD
          inLoading={state.loading}
          error="Não há carências para mostrar!"
          settings={dadosCarencia(state.apiDataCarencia)}
        />
      );
    }
    if (state.apiDataCarencia.length > 0) {
      return (
        <TableCRUD
          inLoading={state.loading}
          settings={dadosCarencia(state.apiDataCarencia)}
        />
      );
    }

    return null;
  };

  const renderDefaultErrorCarencias = (
    <>
      <BodyInfo
        icon={<WarningIcon color="error" />}
        title="Opss..."
        body="Não foi possivel carregar suas carências, tente novamente mais tarde."
        noDivider
      />
    </>
  );

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page
            title="Prazo das carências"
            icon={ShowChartIcon}
            type="Borderless"
            style={{ maxWidth: '100vw' }}
          >
            <Grid item sm={12} md={12}>
              <TablePanel>
                <TableBody>
                  {state.successApiCarencia
                    ? renderBodyTableCarencias()
                    : renderDefaultErrorCarencias}
                </TableBody>
              </TablePanel>
            </Grid>
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
