/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable array-callback-return */

import React from 'react';

import Form from '@solussaude/core/CustomForm';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import ActionButtons from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import WebContext from '@solussaude/core/WebContext';
import { isPromise } from '@solussaude/core/Utils';

import {
  CloseIcon,
  CheckIcon,
  WarningIcon,
  TodayIcon,
  CheckCircleIcon,
} from '@solussaude/core/Icons';
import SolusAPI from '../../../services/SolusApi';

import { renderForm } from './settings';
import { ContainerWarning } from './styles';

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

let mapping;
const getMapping = () => mapping;
const setMapping = (newMapping) => {
  mapping = newMapping;
};

let mappingForm;
const getMappingForm = () => mappingForm;
const setMappingForm = (pGetMapping) => {
  mappingForm = pGetMapping;
};

export default function AdesaoBoletoDigital() {
  const [state, setState] = React.useState({
    loading: true,
    pdf: '',
  });
  const webContext = React.useContext(WebContext);
  setMapping({
    ...getMapping(),
    getMappingForm,
  });

  React.useEffect(() => {
    const init = async () => {
      const dados = await SolusAPI.post('api/settings/pdf', {
        pasta: '/pdf/adesaoboleto',
      });

      setState({
        loading: false,
        pdf: dados.data,
      });
    };
    init();
  }, []);

  const handleCloseModal = (reloadPage) => {
    webContext.showModal({
      open: false,
    });

    reloadPage && window.location.reload();
  };

  const clickTermos = () => {
    if (state.pdf.length > 0) {
      return window.open(state.pdf[0].url, '_blank');
    }
    return null;
  };

  let success = true;

  const handleBlur = (elem, mappingParameter, click) => {
    const { email, email_repetir, email_2, email_2_repetir, celular } =
      getFormValues();

    const {
      email_repetir: email_repetirMap,
      email_2_repetir: email_2_repetirMap,
      termos: termosMap,
    } = mappingParameter().getMappingForm()();

    if (click) {
      Object.values(mappingParameter().getMappingForm()()).map((item) => {
        if (
          (item.values === '' || item.values === false) &&
          item.self.elem_id !== 'email_2' &&
          item.self.elem_id !== 'email_2_repetir'
        ) {
          item.setError({
            is: true,
            helperText: 'Preenchimento obrigatório',
          });
          success = false;
        }
      });
      if (termosMap.values === true) {
        success = true;
      }
    } else {
      if (
        elem.mapping.email_repetir.self.elem_id === elem.self.elem_id ||
        elem.mapping.email.self.elem_id === elem.self.elem_id
      ) {
        if (!!email && !!email_repetir) {
          if (email !== email_repetir) {
            email_repetirMap.setError({
              is: true,
              helperText: 'Forneça o mesmo e-mail ',
            });
            success = false;
          } else {
            elem.setError({ is: false, helperText: '' });
            email_repetirMap.setError({ is: false, helperText: '' });
            success = true;
          }
        }
      }

      if (
        elem.mapping.email_2_repetir.self.elem_id === elem.self.elem_id ||
        elem.mapping.email_2.self.elem_id === elem.self.elem_id
      ) {
        if (email_2 || email_2_repetir) {
          if (email_2 !== email_2_repetir) {
            email_2_repetirMap.setError({
              is: true,
              helperText: 'Forneça o mesmo e-mail ',
            });
            success = false;
          } else {
            elem.setError({ is: false, helperText: '' });
            success = true;
          }
        } else {
          elem.setError({ is: false, helperText: '' });
          email_2_repetirMap.setError({ is: false, helperText: '' });
          success = true;
        }
      }

      if (elem.mapping.celular.self.elem_id === elem.self.elem_id) {
        if (!celular) {
          elem.setError({ is: true, helperText: 'Preenchimento obrigatório ' });
          success = false;
        } else {
          success = true;
        }
      }
    }
  };

  const handleClick = async () => {
    handleBlur(null, getMapping, true);
    const { email, email_2, celular } = getFormValues();
    let dados = [];

    if (success) {
      const userAuth = await isPromise(webContext.module.beneficiario.userAuth);

      dados = await SolusAPI.post('api/beneficiarios/boleto-digital', {
        id: userAuth.id,
        contrato: userAuth.id_contrato,
        email,
        email_2,
        celular,
      });

      if (dados.data.retMessage === null) {
        webContext.showModal({
          open: true,
          renderBody: (
            <>
              <ContainerWarning>
                <BodyInfo
                  icon={<CheckCircleIcon color="primary" />}
                  title="Adesão do boleto digital"
                  body="Sua adesão foi realizada com sucesso! Sua próxima fatura será enviada no endereço de e-mail fornecido."
                  noDivider
                />
                <ActionButtons direction="flex-end">
                  <Button
                    text="Concluir"
                    variant="contained"
                    color="secondary"
                    endIcon={<CheckIcon />}
                    fullWidth={false}
                    onClick={() => handleCloseModal(true)}
                  />
                </ActionButtons>
              </ContainerWarning>
            </>
          ),
        });
      } else {
        webContext.showModal({
          open: true,
          renderBody: (
            <>
              <ContainerWarning>
                <BodyInfo
                  icon={<WarningIcon color="error" />}
                  title="Opss..."
                  body={dados.data.retMessage}
                  noDivider
                />
                <ActionButtons direction="flex-end">
                  <Button
                    text="Fechar"
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    fullWidth={false}
                    onClick={() => handleCloseModal(webContext)}
                  />
                </ActionButtons>
              </ContainerWarning>
            </>
          ),
        });
      }
    }
  };

  return (
    <>
      <BodyInfo
        icon={<TodayIcon />}
        title="Adesão boleto digital"
        body="Preencha os dados abaixo para realizar a adesão do boleto digital"
      />
      <Form
        settings={renderForm(
          handleBlur,
          getMapping,
          clickTermos,
          handleCloseModal,
          handleClick,
        )}
        setValues={setFormValues}
        setMappingForm={setMappingForm}
      />
    </>
  );
}
