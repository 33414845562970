/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import Form from '@solussaude/core/CustomForm';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import ActionButtons from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import WebContext from '@solussaude/core/WebContext';
import { isPromise } from '@solussaude/core/Utils';
import {
  CloseIcon,
  CheckIcon,
  WarningIcon,
  TodayIcon,
  CheckCircleIcon,
} from '@solussaude/core/Icons';
import SolusAPI from '../../../services/SolusApi';

import { renderForm } from './settings';
import { ContainerWarning, Terms, TitleTerms, BodyTerms } from './styles';

let mapping;
const getMapping = () => mapping;
const setMapping = (newMapping) => {
  mapping = newMapping;
};

let mappingForm;
const getMappingForm = () => mappingForm;
const setMappingForm = (pGetMapping) => {
  mappingForm = pGetMapping;
};

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

export default function VencimentoMensalidade() {
  const webContext = React.useContext(WebContext);
  const [state, setState] = React.useState({
    loading: true,
    render: <></>,
    apiData: [],
    successApi: false,
  });

  React.useEffect(() => {
    const init = async () => {
      const dados = await SolusAPI.get('api/beneficiarios/lista-vencimentos');

      setState({
        ...state,
        loading: false,
        apiData: dados.data,
        successApi: dados.success,
      });
    };
    init();
  }, []);
  setMapping({
    ...getMapping(),
    getMappingForm,
    getFormValues,
  });

  const termos = () => (
    <Terms>
      <TitleTerms>
        Alertamos as consequências da alteração do vencimento da mensalidade:
      </TitleTerms>
      <BodyTerms>
        A alteração da data de vencimento do seu boleto poderá gerar cobrança de
        Pró-Rata, ou seja, o valor cobrado na próxima fatura será proporcional
        ao período utilizado, para mais ou para menos. Por exemplo: Se sua
        fatura fecha no dia 25 com data de vencimento para 10 do próximo mês, e
        você alterou o vencimento para o dia 15 o valor adicional cobrado será
        proporcional a cinco (05) dias de utilização.
      </BodyTerms>
    </Terms>
  );

  const clickTermos = () => {
    setState({ ...state, render: termos() });
  };

  const handleCloseModal = () => {
    webContext.showModal({
      open: false,
      // renderBody: webContext.modal.renderBody,
    });
  };

  const handleClick = async () => {
    const form = getFormValues();
    let dados = [];

    if (!form.termos) {
      getMapping()
        .getMappingForm()()
        .termos.setError({ is: true, helperText: 'Obrigatório' });
    }

    if (form.termos) {
      const userAuth = await isPromise(webContext.module.beneficiario.userAuth);
      const { diaVencimento, motivo } = getFormValues();
      dados = await SolusAPI.post('api/beneficiarios/alterar-vencimento', {
        diaVencimento,
        motivo,
        idContrato: userAuth.id_contrato,
      });

      if (dados.data.retMessage === '') {
        webContext.showModal({
          open: true,
          renderBody: (
            <>
              <ContainerWarning>
                <BodyInfo
                  icon={<CheckCircleIcon color="primary" />}
                  title="Solicitação enviada!"
                  body="Sua solicitação de alteração foi enviada, aguarde o retorno da operadora."
                  noDivider
                />
                <ActionButtons direction="flex-end">
                  <Button
                    text="Concluir"
                    variant="contained"
                    color="secondary"
                    endIcon={<CheckIcon />}
                    fullWidth={false}
                    onClick={() => handleCloseModal()}
                  />
                </ActionButtons>
              </ContainerWarning>
            </>
          ),
        });
      } else {
        webContext.showModal({
          open: true,
          renderBody: (
            <>
              <ContainerWarning>
                <BodyInfo
                  icon={
                    dados.data.retMessage ? (
                      <WarningIcon color="error" />
                    ) : (
                      <CheckCircleIcon color="primary" />
                    )
                  }
                  title={dados.data.retMessage ? 'Opss...' : 'Aguarde'}
                  body={
                    dados.data.retMessage
                      ? dados.data.retMessage
                      : 'Sua solicitação de alteração já foi enviada, aguarde o retorno da operadora.'
                  }
                  noDivider
                />
                <ActionButtons direction="flex-end">
                  <Button
                    text="Fechar"
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    fullWidth={false}
                    onClick={() => handleCloseModal()}
                  />
                </ActionButtons>
              </ContainerWarning>
            </>
          ),
        });
      }
    }
  };

  return (
    <>
      <BodyInfo
        icon={<TodayIcon />}
        title="Alteração da data de vencimento da mensalidade"
        body="Selecione uma data disponível abaixo para alterar a data de vencimento da sua mensalidade."
      />
      {state.successApi === true ? (
        <Form
          settings={renderForm(
            state.apiData,
            clickTermos,
            handleCloseModal,
            handleClick,
          )}
          setValues={setFormValues}
          setMappingForm={setMappingForm}
        />
      ) : (
        <CircularIndeterminateModal />
      )}
      {state.render}
    </>
  );
}
