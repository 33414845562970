import React from 'react';

import { Link } from '@solussaude/core/Router';
import Tooltip from '@solussaude/core/DataDisplay/Tooltip';
import { dateFormat, processData } from '@solussaude/core/Utils';

import {
  CallMadeIcon,
  SubjectIcon,
  InfoIcon,
  CalendarTodayIcon,
  ChatIcon,
  DescriptionIcon,
  TitleIcon,
  ContactPhoneIcon,
  PhoneIcon,
  EmailIcon,
} from '@solussaude/core/Icons';

const tipoChamado = [
  {
    key: '0',
    name: 'Fale Conosco',
  },
  {
    key: '1',
    name: 'Ouvidoria',
  },
];

const processAtendimento = (apiData) => {
  const array = [];

  if (apiData.length > 0) {
    apiData.forEach((atendimento) => {
      array.push({
        chamado: atendimento.id_atendimento,
        assunto: atendimento.titulo,
        origem: atendimento.origem,
        status: atendimento.situacao,
        criadoEm: dateFormat(atendimento.data_abertura),
      });
    });
  }

  return array;
};

export const renderTable = (apiData) => ({
  table: {
    options: {
      editableTable: false,
      pagination: true,
    },
    columns: [
      {
        title: 'Chamado',
        field: 'chamado',
        icon: <CallMadeIcon color="primary" />,
      },
      {
        title: 'Assunto',
        field: 'assunto',
        icon: <SubjectIcon color="primary" />,
      },
      {
        title: 'Origem',
        field: 'origem',
        icon: <SubjectIcon color="primary" />,
      },
      {
        title: 'Status',
        field: 'status',
        icon: <InfoIcon color="primary" />,
      },
      {
        title: 'Criado em',
        field: 'criadoEm',
        icon: <CalendarTodayIcon color="primary" />,
      },
      {
        title: 'Ações',
        field: 'action',
        render: (rowData) => (
          <>
            <Link
              to={{
                pathname: '/beneficiario/servicos/canais-atendimento/detalhes',
                state: { id: rowData.chamado },
              }}
            >
              <Tooltip title="Detalhes" icon={<ChatIcon />} />
            </Link>
          </>
        ),
      },
    ],
    data: processAtendimento(apiData),
  },
});

export const renderFormTipoChamado = (handleTipo, tipo) => ({
  header: {
    description: <p>Selecione o canal de atendimento:</p>,
  },
  form: [
    {
      label: 'Novo contato',
      inputs: [
        [
          // Row
          {
            // Column
            elem_id: 'web_tipoChamado',
            elem_val: tipo,
            type: 'RadioButton',
            value: tipoChamado,
            onChange: () => handleTipo(),
          },
        ],
      ],
    },
  ],
});

export const renderFormSolicita = (
  tipo,
  nroProtocolo,
  titulo,
  assunto,
  classificacao,
  subclassificacao,
  pessoaContato,
  telefoneContato,
  email,
  mensagem,
  changeClassificacao,
) => {
  const protocolo =
    tipo === 1
      ? [
          {
            // Column
            elem_id: 'protocolo',
            elem_val: nroProtocolo,
            type: 'InputText',
            icon: DescriptionIcon,
            label: 'Nº Protocolo',
            required: true,
          },
        ]
      : undefined;

  return {
    header: {
      description: (
        <p>Preencha os dados abaixo para solicitar um novo chamado no canal.</p>
      ),
    },
    form: [
      {
        label: 'Novo contato',
        inputs: [
          protocolo,

          [
            // Row
            {
              // Column
              elem_id: 'titulo',
              elem_val: titulo,
              type: 'InputText',
              icon: TitleIcon,
              label: 'Título',
              required: true,
            },
          ],
          [
            // Row
            {
              // Column
              elem_id: 'classificacao',
              elem_val: classificacao.selecionado,
              type: 'Select',
              label: 'Classificação',
              required: true,
              value: processData(
                classificacao.descricao,
                'id_classificacao',
                'descricao',
              ),
              onChange: (e) => changeClassificacao(e),
            },
          ],
          [
            // Row
            {
              // Column
              elem_id: 'subclassificacao',
              elem_val: subclassificacao.selecionado,
              type: 'Select',
              label: 'Sub-Classificação',
              required: true,
              value: processData(
                subclassificacao.descricao,
                'id_classificacao',
                'descricao',
              ),
            },
          ],
          [
            // Row
            {
              // Column
              elem_id: 'pessoaContato',
              elem_val: pessoaContato,
              type: 'InputText',
              icon: ContactPhoneIcon,
              label: 'Pessoa para contato',
              required: true,
              flex: 6,
            },
            {
              // Column
              elem_id: 'telefoneContato',
              elem_val: telefoneContato,
              type: 'InputMask',
              typeMask: 'celular',
              icon: PhoneIcon,
              label: 'Telefone para contato',
              required: true,
              flex: 6,
            },
          ],
          [
            // Row
            {
              // Column
              elem_id: 'email',
              elem_val: email,
              type: 'InputText',
              typeInput: 'email',
              icon: EmailIcon,
              label: 'E-mail',
              required: true,
            },
          ],
          [
            {
              elem_id: 'assunto',
              elem_val: mensagem,
              type: 'InputText',
              multiline: true,
              rows: 8,
              label: 'Mensagem',
              required: true,
              variant: 'outlined',
            },
          ],
        ].filter((elem) => !!elem),
      },
    ],
  };
};
