/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';

import Grid from '@solussaude/core/Grid';
import Form from '@solussaude/core/CustomForm';
import Button from '@solussaude/core/Inputs/Button';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import WebContext from '@solussaude/core/WebContext';
import { isPromise, formataBase64 } from '@solussaude/core/Utils';
import { AssignmentIcon, GetAppIcon } from '@solussaude/core/Icons';
import SolusAPI from '../../../services/SolusApi';

import { formTermos } from './settings';
import { ContainerPDF } from './styles';

let formTermosValues;
const getFormTermosValues = () => formTermosValues;
const setFormTermosValues = (newValue) => {
  formTermosValues = newValue;
};

let mappingTermos;
const getTermosMapping = () => mappingTermos;
const setTermosMapping = (newMapping) => {
  mappingTermos = newMapping;
};

export default function SolicitacaoProtocoloReembolso(props) {
  const webContext = React.useContext(WebContext);
  const { history } = props;

  const [disabled, setDisabled] = useState(true);

  const [state, setState] = React.useState({
    loading: true,
    termos: '',
    orientacao: {},
    beneficiario: {},
  });

  React.useEffect(() => {
    const init = async () => {
      const userAuth = await isPromise(webContext.module.beneficiario.userAuth);
      const orientacao = await SolusAPI.get(
        'v1/reembolso/configuracao',
        {},
        { appName: 'api_beneficiario' },
      );
      let pdf = {};

      if (orientacao.status === 200) {
        if (orientacao.data.folder) {
          if (orientacao.data.folder.length > 0) {
            pdf = formataBase64(
              orientacao.data.folder[0].extensao,
              orientacao.data.folder[0].arquivo,
            );
          }
        }
      }
      setState({
        ...state,
        loading: false,
        beneficiario: userAuth,
        orientacao: pdf,
      });
    };
    init();
  }, []);

  const handleCloseModal = () => {
    webContext.showModal({
      open: false,
    });
  };

  const check = (c) => {
    setDisabled(!c.checked);
    getTermosMapping()().btn.setState({ ...state, disabled });
  };

  const handleAvancar = () => {
    handleCloseModal();

    history.push(
      `${localStorage.getItem(
        'basename',
      )}/servicos/solicitacao-protocolo-reembolso`,
      {
        aceite: true,
      },
    );
  };

  const handleTermos = () => {
    const { termos } = getFormTermosValues();
    if (termos) {
      handleAvancar();
    } else {
      getTermosMapping()().termos.setError({
        is: true,
        helperText: 'Preenchimento obrigatório',
      });
    }
  };

  const downloadPDF = () => {
    const downloadLink = document.createElement('a');
    const fileName = 'declaracao.pdf';

    downloadLink.href = state.orientacao.formatado;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <ContainerPDF>
          <BodyInfo
            icon={<AssignmentIcon color="primary" />}
            title="Solicitação de protocolo de reembolso"
            noDivider
          />

          <object
            data={state.orientacao.formatado}
            width="100%"
            height="100%"
            type={state.orientacao.type}
          >
            <iframe
              src={state.orientacao.formatado}
              width="100%"
              height="80%"
            />
            <Button
              onClick={() => downloadPDF()}
              startIcon={<GetAppIcon />}
              text="Baixar pdf"
            />
          </object>
          <Form
            settings={formTermos(
              check,
              disabled,
              handleTermos,
              handleCloseModal,
            )}
            setValues={setFormTermosValues}
            setMappingForm={setTermosMapping}
          />
        </ContainerPDF>
      </Grid>
    </Grid>
  );
}
