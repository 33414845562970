import React from 'react';
import { Link } from '@solussaude/core/Router';
import SolusAPI from '../../services/SolusApi';
import { Container, Titulo, Descricao, ContainerLogo, Logo } from './styles';

export default function Page404() {
  const [values, setValues] = React.useState({
    loading: true,
    logo: null,
  });

  React.useEffect(() => {
    const init = async () => {
      const dados = await SolusAPI.post('api/settings/imagens', {
        pasta: '/logo',
      });

      setValues({
        loading: false,
        logo: dados.data,
      });
    };
    init();
  }, []);

  const logo = () => {
    if (values.loading === false) {
      if (values.logo.length > 0) {
        return (
          <ContainerLogo>
            <Link to="/login">
              <Logo src={values.logo[0].url} />
            </Link>
          </ContainerLogo>
        );
      }
    }
    return null;
  };

  return (
    <Container>
      <Titulo>404</Titulo>
      <Descricao>Página não encontrada!</Descricao>
      {logo()}
    </Container>
  );
}
