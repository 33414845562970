/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import { PrintIcon, WarningIcon, AssignmentIcon } from '@solussaude/core/Icons';

import Grid from '@solussaude/core/Grid';

import Table from '@solussaude/core/DataDisplay/TableSimple';
import TableCRUD from '@solussaude/core/DataDisplay/TableCRUD';
import Page from '@solussaude/core/Surfaces/Page';
import { isPromise, isURL } from '@solussaude/core/Utils';
import WebContext from '@solussaude/core/WebContext';
import ButtonInfo from '@solussaude/core/Inputs/ButtonInfo';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import Divider from '@solussaude/core/Divider';
import SolusAPI from '../../services/SolusApi';
import defaultTheme from '../../styles/themes/default';
import { TablePanel, TableBody } from './styles';

import { dadosCadastro, dadosDependentes } from './settings';

export default function CadastrarBeneficiario() {
  const context = React.useContext(WebContext);
  const theme = defaultTheme;
  const [state, setState] = React.useState({
    displayEnder: 'none',
    loading: true,
    successApiUsuario: true,
    apiDataUsuario: [],
    successApiPlano: true,
    apiDataDependentes: [],
    sucessApiDependentes: true,
    apiDataPlano: [],
    successApiTelefone: true,
    apiDataTelefone: [],
    successApiEmail: true,
    apiDataEmail: [],
    successApiEndereco: true,
    apiDataEndereco: [],
    apiDataTitu: [],
    apiRAnsOpe: '',
    apiSiteOpe: '',
    apiRazaOpme: '',
    apiFatnOpe: '',
    apiParamSac: '',
    contrato: '',
    reload: false,
  });

  const search = async () => {
    const userAuth = await isPromise(context.module.beneficiario.userAuth);
    const dadosP = await SolusAPI.post('api/beneficiarios/planos', {
      id: userAuth.id_plano,
    });
    const contratoFromApi = await SolusAPI.get(
      `api/beneficiarios/dados-contrato/${userAuth.id_contrato}`,
    );
    const contratoData = contratoFromApi.data[0].codigo;

    const dadosEn = await SolusAPI.get('api/beneficiarios/lista-endereco');
    const dadosT = await SolusAPI.post('api/beneficiarios/lista-telefones');
    const dadosE = await SolusAPI.post('api/beneficiarios/lista-email', {
      tipo: 'T',
    });
    const dependentes = await SolusAPI.post('api/beneficiarios/dependentes', {
      id: userAuth.id_titular,
    });
    const rAnsOpe = await SolusAPI.get('api/settings/registro-ans-operadora');
    const siteOpe = await SolusAPI.get('api/settings/site-operadora');
    const nomeOpe = await SolusAPI.get('api/settings/razao-operadora');
    const fantOpe = await SolusAPI.get('api/settings/fantasia-operadora');

    const paramSac = await SolusAPI.post('api/settings/parametro', {
      parametro: 'SAC',
    });
    setState({
      ...state,
      loading: false,
      apiDataUsuario: userAuth,
      successApiUsuario: userAuth.success === undefined,
      apiDataDependentes: dependentes.data,
      sucessApiDependentes: dependentes.success,
      apiDataPlano: dadosP.data[0],
      successApiPlano: dadosP.success,
      apiDataTelefone: dadosT.data,
      successApiTelefone: dadosT.success,
      apiDataEmail: dadosE.data,
      successApiEmail: dadosE.success,
      successApiEndereco: dadosEn.success,
      apiDataEndereco: dadosEn.data,
      apiSiteOpe: siteOpe.data,
      apiRAnsOpe: rAnsOpe.data,
      apiRazaOpme: nomeOpe.data,
      apiFantOpe: fantOpe.data,
      apiParamSac: paramSac.data,
      contrato: contratoData,
    });
  };

  React.useEffect(() => {
    search();
  }, [state.reload]);

  const renderTableDados = () => (
    <Table
      inLoading={state.loading}
      settings={dadosCadastro({
        contrato: state.contrato,
        apiDataUsuario: state.apiDataUsuario,
        apiDataPlano: state.apiDataPlano,
        apiDataEndereco: state.apiDataEndereco,
        apiDataTelefone: state.apiDataTelefone,
        apiDataEmail: state.apiDataEmail,
        apiSiteOpe: state.apiSiteOpe,
        apiFantOpe: state.apiFantOpe,
        apiParamSac: state.apiParamSac,
      })}
    />
  );

  const renderTableDependentes = () => (
    <TableCRUD
      inLoading={state.loading}
      settings={dadosDependentes(state.apiDataDependentes)}
    />
  );

  const renderDefaultError = () => (
    <>
      <BodyInfo
        icon={<WarningIcon color="error" />}
        title="Opss..."
        body="Não foi possivel carregar os dependentes, tente novamente mais tarde."
        noDivider
      />
    </>
  );

  const handleReturn = (dadosFromAPI, modelo) => {
    const settings = {
      Impressao: {
        messageSuccess: 'Componente cadastral gerado com sucesso!',
        messageError:
          'Não foi possível imprimir o componente cadastral, tente novamente mais tarde!',
      },
    };

    const dataReturn = {};
    if (dadosFromAPI.success && isURL(dadosFromAPI.data)) {
      dataReturn.message = settings[modelo].messageSuccess;
      dataReturn.variant = 'success';
      window.open(dadosFromAPI.data);
    } else {
      dataReturn.message = settings[modelo].messageError;
      dataReturn.variant = 'error';
    }

    return dataReturn;
  };

  const onHandleClick = async (action) => {
    setState((old) => ({
      ...old,
      inLoading: true,
    }));
    const dadosFromAPI = await SolusAPI.post(action, {
      api: 'S',
      usuario: JSON.stringify(state.apiDataUsuario),
      enderecos: JSON.stringify(state.apiDataEndereco),
      telefones: JSON.stringify(state.apiDataTelefone),
      planos: JSON.stringify(state.apiDataPlano),
      emails: JSON.stringify(state.apiDataEmail),
      nomeOperadora: state.apiRazaOpme,
      apelidoOperadora: state.apiFantOpe,
      registroAns: state.apiRAnsOpe,
    });

    setState((old) => ({
      ...old,
      ...handleReturn(dadosFromAPI, 'Impressao'),
      inLoading: false,
    }));
  };

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page
            title="Componente cadastral"
            icon={AssignmentIcon}
            type="Borderless"
          >
            <Grid item sm={12} md={12}>
              <TablePanel>
                <TableBody>{renderTableDados()}</TableBody>
                <Divider />
              </TablePanel>
            </Grid>
            <BodyInfo title="Dependentes" />
            {state.sucessApiDependentes
              ? renderTableDependentes()
              : renderDefaultError}
            <ButtonInfo
              title="Imprimir"
              icon={<PrintIcon />}
              click={() =>
                onHandleClick('api/beneficiarios/componente-cadastral/pdf')
              }
            />
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
