/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import Form from '@solussaude/core/CustomForm';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import { MonetizationOnIcon } from '@solussaude/core/Icons';

import SolusAPI from '../../../../../services/SolusApi';
import AutorizadoPagamento from '../AutorizadoPagamento';
import DadosBancarios from '../DadosBancarios';
import { formRecebimento, formValor } from './settings';

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

let formValorValues;
const getFormValorValues = () => formValorValues;
const setFormValorValues = (newValue) => {
  formValorValues = newValue;
};

let mappingValor;
const getMappingValor = () => mappingValor;
const setMappingValor = (newValue) => {
  mappingValor = newValue;
};

export default function Recebimento({ setMapping, getMapping }) {
  setMapping({
    ...getMapping(),
    getFormRecebimento: getFormValues,
    getFormValorReembolso: getFormValorValues,
    getMappingValor,
  });

  const [state, setState] = React.useState({
    formaPagamento: { selecionado: '', descricao: [] },
    render: <></>,
  });

  const ValoresPagamento = () => {
    const [valor, setValor] = React.useState({
      possuiNotaFiscal: 'S',
      valorSolicitado: '',
      nroNotaFiscal: '',
    });

    const handleNota = () => {
      const { possuiNotaFiscal, valorSolicitado, nroNotaFiscal } =
        getFormValorValues();

      setValor({ possuiNotaFiscal, valorSolicitado, nroNotaFiscal });
    };

    return (
      <>
        <BodyInfo
          icon={<MonetizationOnIcon />}
          title="Valores para recebimento"
          noDivider
        />
        <Form
          settings={formValor(valor, handleNota)}
          setValues={setFormValorValues}
          setMappingForm={setMappingValor}
        />
      </>
    );
  };

  React.useEffect(() => {
    const init = async () => {
      const dadosFormaPagamento = await SolusAPI.get(
        'v1/reembolso/configuracao',
        {},
        { appName: 'api_beneficiario' },
      );

      setState({
        ...state,
        formaPagamento: {
          selecionado: 'D',
          descricao: dadosFormaPagamento.data.formaPagamentoReembolso,
        },
        render: (
          <>
            <AutorizadoPagamento
              setMapping={setMapping}
              getMapping={getMapping}
            />
            <br />
            <DadosBancarios setMapping={setMapping} getMapping={getMapping} />
            <br />
            <ValoresPagamento />
          </>
        ),
      });
    };
    init();
  }, []);

  const changePagamento = (e) => {
    const { value } = e;

    if (value === 'D') {
      setState({
        ...state,
        formaPagamento: {
          selecionado: 'D',
          descricao: state.formaPagamento.descricao,
        },
        render: (
          <>
            <AutorizadoPagamento
              setMapping={setMapping}
              getMapping={getMapping}
            />
            <br />
            <DadosBancarios setMapping={setMapping} getMapping={getMapping} />
            <br />
            <ValoresPagamento />
          </>
        ),
      });
    } else {
      delete getMapping().getFormAutorizadoPagamento;
      delete getMapping().getFormDadosBancarios;
      setState({
        ...state,
        formaPagamento: {
          selecionado: value,
          descricao: state.formaPagamento.descricao,
        },
        render: (
          <>
            <ValoresPagamento />
          </>
        ),
      });
    }
  };

  return (
    <>
      <BodyInfo
        title="Forma de recebimento"
        icon={<MonetizationOnIcon />}
        noDivider
      />
      <Form
        settings={formRecebimento(state.formaPagamento, changePagamento)}
        setValues={setFormValues}
      />
      <br />
      {state.render}
    </>
  );
}
