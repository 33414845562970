import React from 'react';

import { useHistory } from '@solussaude/core/Router';

import ForgotPassword from '@solussaude/core/ForgotPassword';
import defaultTheme from '../../styles/themes/default';

const EsqueciSenha = () => {
  const theme = defaultTheme;
  const history = useHistory();

  return <ForgotPassword props={{ history }} theme={theme} />;
};

export default EsqueciSenha;
