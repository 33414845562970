/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import {
  RateReviewIcon,
  WarningIcon,
  CloseIcon,
  CheckCircleIcon,
} from '@solussaude/core/Icons';
import Grid from '@solussaude/core/Grid';
import Button from '@solussaude/core/Inputs/Button';
import Page from '@solussaude/core/Surfaces/Page';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import Stepper from '@solussaude/core/Navigation/Steppers';
import Step from '@solussaude/core/Navigation/Step';
import StepLabel from '@solussaude/core/Navigation/StepLabel';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import WebContext from '@solussaude/core/WebContext';
import SolusAPI from '../../../services/SolusApi';

import { solicitacao } from './settings';
import { ListaBeneficiarios, MotivosBeneficiario, Confirmacao } from './views';

import { ActionButtons } from './styles';
import defaultTheme from '../../../styles/themes/default';

let mapping;
const getMapping = () => mapping;
const setMapping = (newMapping) => {
  mapping = newMapping;
};

let selections = [];

function genKey() {
  return Math.random().toString(12).slice(-4);
}

function renderStepHeader(activeStep) {
  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel>
        {solicitacao.steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
}

function getStepContent(
  step,
  handleNext,
  handleBack,
  handleConfirm,
  onSelectionChange,
  state,
) {
  const renderArray = [];
  switch (step.active) {
    case 0:
      renderArray.push(
        <React.Fragment key={genKey()}>
          {renderStepHeader(step.active)}
          <ListaBeneficiarios
            apiData={state.apiData}
            onSelectionChange={onSelectionChange}
            handleNext={handleNext}
          />
        </React.Fragment>,
      );
      break;
    case 1:
      renderArray.push(
        <React.Fragment key={genKey()}>
          {renderStepHeader(step.active)}
          <MotivosBeneficiario
            beneficiarios={selections}
            motivos={state.apiMotivos}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </React.Fragment>,
      );
      break;
    case 2:
      renderArray.push(
        <React.Fragment key={genKey()}>
          {renderStepHeader(step.active)}
          <Confirmacao
            handleBack={handleBack}
            handleConfirm={handleConfirm}
            motivos={state.apiMotivos}
          />
        </React.Fragment>,
      );
      break;
    default:
      renderArray.push('Unknown state.active');
  }

  return renderArray;
}

export default function Carteirinha(props) {
  const theme = defaultTheme;
  const webContext = React.useContext(WebContext);

  const [step, setStep] = React.useState({
    active: 0,
  });

  const [state, setState] = React.useState({
    loading: true,
    apiData: [],
    successApi: [],
    apiMotivos: [],
    apiSelect: [],
  });

  React.useEffect(() => {
    const init = async () => {
      const dados = await SolusAPI.get('api/beneficiarios/lista-familia');
      const motivos = await SolusAPI.get(
        'api/beneficiarios/lista-motivo-carteirinha',
      );
      setState({
        ...state,
        loading: false,
        apiData: dados.data,
        successApi: dados.success,
        apiMotivos: motivos.data,
      });
    };
    init();
  }, []);

  const onSelectionChange = (row) => {
    selections = row;
  };

  setMapping({
    ...getMapping(),
    onSelectionChange,
  });

  const handleCloseModal = (context, redirect = '') => {
    context.showModal({
      open: false,
    });
    if (redirect !== '') props.history.push(redirect);
  };

  const handleNext = (params) => {
    setStep((old) => ({
      ...old,
      active: old.active + 1,
      [old.active + 1]: params || old[old.active + 1],
    }));
  };

  const handleBack = (params) => {
    if (step.active - 1 === 0) {
      while (selections.length) {
        selections.pop();
      }
    }
    setStep((old) => ({
      ...old,
      active: old.active - 1,
      [old.active - 1]: params || old[old.active - 1],
    }));
  };

  const handleConfirm = async (Dados) => {
    let count = 0;
    let cadastra = '';
    let errorLog = '';

    const beneficiarios = Dados.map(async (beneficiario) => {
      count += 1;
      cadastra = await SolusAPI.post('api/beneficiarios/solicita-carteirinha', {
        id: beneficiario.id,
        contrato: state.apiData[0].id_contrato,
        motivo: beneficiario.motivo,
        bo: beneficiario.bo,
        observacao: beneficiario.observacao,
      });
      if (cadastra.data.retMessage !== null) {
        errorLog = cadastra.data.retMessage;
      }
    });

    await Promise.all(beneficiarios);

    if (errorLog === '') {
      const mensagem =
        count > 1
          ? 'As carteirinhas foram solicitadas com sucesso, agora é só aguardar para receber as novas carteirinhas!'
          : 'A carteirinha do beneficiario indicado foi solicitada com sucesso, agora é só aguardar para receber a nova carteirinha!';
      webContext.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<CheckCircleIcon color="primary" />}
              title="Solicitação concluída"
              body={mensagem}
              noDivider
            />
            <ActionButtons direction="flex-end">
              <Button
                text="Fechar"
                variant="contained"
                color="secondary"
                startIcon={<CloseIcon />}
                fullWidth={false}
                onClick={() => handleCloseModal(webContext, '/dashboard')}
              />
            </ActionButtons>
          </>
        ),
      });
    } else {
      webContext.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<WarningIcon color="error" />}
              title="Opss.."
              body={<p>{errorLog}</p>}
            />
            <ActionButtons direction="flex-end">
              <Button
                text="Fechar"
                variant="contained"
                color="secondary"
                startIcon={<CloseIcon />}
                fullWidth={false}
                onClick={() => handleCloseModal(webContext, '/dashboard')}
              />
            </ActionButtons>
          </>
        ),
      });
    }
  };

  const renderLoading = () => <CircularIndeterminateModal />;

  const renderSolicitation = () => {
    if (selections.length === 0 && step.active === 1) {
      webContext.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<WarningIcon color="error" />}
              title="Atenção"
              body={
                <p>
                  Selecione um beneficiário para prosseguir com a solicitação!
                </p>
              }
              noDivider
            />
            <ActionButtons direction="flex-end">
              <Button
                text="Fechar"
                variant="contained"
                color="primary"
                startIcon={<CloseIcon />}
                fullWidth={false}
                onClick={() => handleCloseModal(webContext)}
              />
            </ActionButtons>
          </>
        ),
      });
      handleBack();
    } else {
      return getStepContent(
        step,
        handleNext,
        handleBack,
        handleConfirm,
        onSelectionChange,
        state,
      );
    }
  };

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page title="Solicitação de 2º via carteirinha" icon={RateReviewIcon}>
            <Grid item sm={12} md={12}>
              {state.loading ? renderLoading() : renderSolicitation()}
            </Grid>
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
