export const formTermos = () => ({
  form: [
    {
      inputs: [
        [
          // Row
          {
            elem_id: 'termos',
            type: 'Checkbox',
            label:
              'Estou ciente em relação ao prazo e a documentação necessária',
            required: true,
          },
        ],
      ],
    },
  ],
});
