const tipoPessoa = [
  {
    key: 'PF',
    name: 'Física',
  },
  {
    key: 'PJ',
    name: 'Jurídica',
  },
];

export const formAutorizadoPagamento = (state, handleTipoPessoa) => {
  return {
    form: [
      {
        inputs: [
          [
            {
              // Column
              elem_id: 'tipoPessoa',
              elem_val: state.tipoPessoa,
              type: 'RadioButton',
              label: 'Tipo de pessoa titular',
              value: tipoPessoa,
              required: true,
              variant: 'outlined',
              onChange: (e) => handleTipoPessoa(e),
              small: true,
              flex: 5,
            },
          ],
          [
            {
              // Column
              elem_id: 'titular',
              elem_val: state.nome,
              type: 'InputText',
              label: 'Titular da conta',
              required: true,
              variant: 'outlined',
              small: true,
              flex: 5,
            },
            // Row
            state.tipoPessoa === 'PF'
              ? {
                  elem_id: 'cpf',
                  elem_val: state.cpf,
                  type: 'InputMask',
                  label: 'CPF',
                  typeMask: 'cpf',
                  required: true,
                  variant: 'outlined',
                  small: true,
                  flex: 5,
              }
              : {
                  elem_id: 'cnpj',
                  elem_val: state.cnpj,
                  type: 'InputMask',
                  label: 'CNPJ',
                  typeMask: 'cnpj',
                  required: true,
                  variant: 'outlined',
                  small: true,
                  flex: 5,
              },
          ],
        ],
      },
    ],
  };
};
