import React from 'react';

import { DescriptionIcon, WarningIcon } from '@solussaude/core/Icons';
import Grid from '@solussaude/core/Grid';
import Page from '@solussaude/core/Surfaces/Page';
import TableCRUD from '@solussaude/core/DataDisplay/TableCRUD';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import SolusAPI from '../../../services/SolusApi';
import defaultTheme from '../../../styles/themes/default';
import { dadosMeusProtocolos } from './settings';

export default function MeusProtocolos() {
  const theme = defaultTheme;

  const [state, setState] = React.useState({
    loading: true,
    apiData: [],
    successApi: true,
  });

  React.useEffect(() => {
    const search = async () => {
      const dadosP = await SolusAPI.post('api/beneficiarios/protocolos');

      setState({
        loading: false,
        apiData: dadosP.data,
        successApi: dadosP.success,
      });
    };

    search();
  }, []);

  const renderBodyTableProtocolos = () => {
    if (state.successApi === true) {
      return (
        <TableCRUD
          settings={dadosMeusProtocolos(state.apiData)}
          inLoading={state.loading}
        />
      );
    }
    return null;
  };

  const renderDefaultErrorProtocolos = (
    <>
      <BodyInfo
        icon={<WarningIcon color="error" />}
        title="Opss..."
        body="Não foi possivel carregar seus protocolos, tente novamente mais tarde."
        noDivider
      />
    </>
  );

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page title="Meus Protocolos" icon={DescriptionIcon}>
            <Grid item sm={12} md={12} />
            {state.successApi
              ? renderBodyTableProtocolos()
              : renderDefaultErrorProtocolos}
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
