import Request from '../request';

class SolusAPI {
  static async get(path, params, config) {
    return Request({ method: 'get', path, config, params });
  }

  static async post(path, data, config) {
    return Request({ method: 'post', path, config, params: data });
  }

  static async put(path, data, config) {
    return Request({ method: 'put', path, config, params: data });
  }

  static async delete(path, config) {
    return Request({ method: 'delete', path, config });
  }
}

export default SolusAPI;
