/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import Grid from '@solussaude/core/Grid';
import Page from '@solussaude/core/Surfaces/Page';
import Table from '@solussaude/core/DataDisplay/TableSimple';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import { isPromise } from '@solussaude/core/Utils';
import WebContext from '@solussaude/core/WebContext';
import { FormatAlignLeftIcon, WarningIcon } from '@solussaude/core/Icons';
import SolusAPI from '../../../services/SolusApi';
import defaultTheme from '../../../styles/themes/default';
import { dadosLimites } from './settings';

export default function LimiteUtilizacao() {
  const webContext = React.useContext(WebContext);
  const theme = defaultTheme;
  const [state, setState] = React.useState({
    loading: true,
    apiData: [],
    successApi: true,
  });

  React.useEffect(() => {
    const init = async () => {
      const userAuth = await isPromise(webContext.module.beneficiario.userAuth);
      const dados = await SolusAPI.post(
        'api/beneficiarios/limites-utilizacao',
        { id: userAuth.id },
      );

      setState({
        loading: false,
        successApi: dados.success,
        apiData: dados.data,
      });
    };

    init();
  }, []);

  const renderBodyTable = () => (
    <Table settings={dadosLimites(state.apiData)} inLoading={state.loading} />
  );

  const renderError = () => (
    <BodyInfo
      icon={<WarningIcon color="error" />}
      title="Ops..."
      body="Não foi possível carregar seu limite de utilização, tente novamente mais tarde!"
      noDivider
    />
  );

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page
            title="Limites de utilização do plano"
            icon={FormatAlignLeftIcon}
          >
            <Grid item sm={12} md={12}>
              {state.successApi ? renderBodyTable() : renderError()}
            </Grid>
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
