export const TOKEN_KEY = localStorage.getItem('Authenticate');
const TOKEN_AUTHORIZATION = localStorage.getItem('Authorization');
const DEBUG = localStorage.getItem('DEBUG');

export const isAuthenticated = () => !!TOKEN_KEY && !!TOKEN_AUTHORIZATION;

export const logoff = () => {
  if (DEBUG !== 'S') {
    localStorage.removeItem('Authenticate');
    localStorage.removeItem('Authorization');
    window.location.href = '/beneficiario/login';
  }

  localStorage.removeItem('Authenticate');
  localStorage.removeItem('Authorization');
  window.location.href = '/beneficiario/login?authenticate=true';

};