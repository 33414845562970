/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import BodyFormModal from '@solussaude/core/Helpers/BodyFormModal';
import { isPromise } from '@solussaude/core/Utils';
import WebContext from '@solussaude/core/WebContext';
import SolusAPI from '../../../services/SolusApi';

import { renderForm } from './settings';

export default function DemonstrativoIR() {
  const { module } = React.useContext(WebContext);

  const [state, setState] = React.useState({
    inLoading: true,
    action: {
      verifyURL: 'api/beneficiarios/declaracaoIR/verify',
      verifyData: {},
      urlPDF: 'api/beneficiarios/declaracaoIR/pdf',
      dataPDF: {},
    },
    listaAno: [],
  });

  React.useEffect(() => {
    const getFromAPI = async () => {
      const userAuth = await isPromise(module.beneficiario.userAuth);

      let { cpf } = userAuth;

      const listaAno = await SolusAPI.post(
        'api/beneficiarios/lista-ano-demonstrativo-ir',
        {
          cpf,
        },
      );

      let anos = [];

      if (listaAno.status === 200) {
        anos = listaAno.data;
      }

      setState((old) => ({
        ...old,
        inLoading: true,
        action: {
          ...old.action,
          verifyData: {
            api: 'S',
            listar: 'S',
            id_usuario: userAuth.id,
            id_titular: userAuth.id_titular,
            id_contrato: userAuth.id_contrato,
          },
          dataPDF: {
            api: 'S',
            listar: 'S',
            id_usuario: userAuth.id,
            id_titular: userAuth.id_titular,
            id_contrato: userAuth.id_contrato,
          },
        },
        listaAno: anos,
      }));
    };
    getFromAPI();
  }, []);

  return (
    <>
      <BodyFormModal
        title="Demonstrativo para I.R.P.F."
        description="Escolha o ano que deseja emitir o demonstrativo."
        formSettings={renderForm(state.listaAno)}
        descriptionSuccess={<p>Seu demonstrativo foi impresso com sucesso!</p>}
        action={state.action}
        array={state.listaAno}
      />
    </>
  );
}
