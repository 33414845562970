import React, { useState, useEffect } from 'react';

import { Router, Switch, Route, Redirect } from '@solussaude/core/Router';

import Layout from '@solussaude/core/Templates/Solus_modelo';

import WebContext from '@solussaude/core/WebContext';
import { isPromise } from '@solussaude/core/Utils';
import VerifyBrowser from '@solussaude/core/pages/VerifyBrowser/isSupportedBrowser';

import { isAuthenticated } from '../services/auth';
import { Pages } from './pages';
import { menus, menusPJ } from './menu';
import { getDadosBeneficiario } from '../services/Beneficiario';

const Routes = () => {
  const { module } = React.useContext(WebContext);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const basename = process.env.PUBLIC_URL || '';
  localStorage.setItem('basename', basename);

  useEffect(() => {
    const check = async () => {
      if (isAuthenticated()) {
        const resp = await getDadosBeneficiario();

        if (resp.id !== '') {
          setUser({ id: resp.id, naturezaJuridica: resp.naturezaJuridica });
          module.beneficiario.userAuth = isPromise(resp);
        }
      }

      setLoading(true);
    };

    check();
  }, [module.beneficiario]);

  // Faz a verificação dos navegadores suportados, caso não seja, a função VerifyBrowser()
  // retorna true. Assim, mostrando a tela e bloqueia as rotas.
  if (VerifyBrowser()) {
    return <Layout />;
  }

  const PublicRoute = ({ id, path, component, exact }) => {
    if (isAuthenticated()) return <Redirect to={`${basename}/dashboard`} />;

    return (
      <Route
        key={`public_route${id}`}
        path={path}
        exact={exact}
        render={() => component}
      />
    );
  };

  const PrivateRoute = ({ id, path, component, exact }) => {
    if (isAuthenticated())
      return (
        <Route
          key={`private_route${id}`}
          path={path}
          exact={exact}
          render={() => (
            <Layout
              key={`layout${id}`}
              page={component}
              modulo="BENEFICIARIO"
              menus={user.naturezaJuridica === 'PJ' ? menusPJ : menus}
              id={user.id}
            />
          )}
        />
      );

    return <Redirect to={`${basename}/login`} />;
  };

  if (!loading) return <></>;

  return (
    <Router>
      <Switch basename={basename}>
        {Object.values(Pages).map((p) => {
          const page = Object(p);

          if (page.private) {
            return (
              <PrivateRoute
                key={page.id}
                id={page.id}
                exact
                path={page.route}
                component={page.component}
              />
            );
          }

          return (
            <PublicRoute
              key={page.id}
              id={page.id}
              path={page.route}
              exact
              component={React.createElement(page.component)}
            />
          );
        })}
        <Redirect from="/" to={`${basename}/dashboard`} />
      </Switch>
    </Router>
  );
};

export default Routes;
