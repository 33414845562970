import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0px;
    padding: 0px;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    min-height: 100%;
  }

  p{
    margin: 0px;
  }
  @media(max-width: 600px){
    td{
      /* max-width: 130px; */
      padding: 0;
      margin: 0 10px;
    }
    .MuiPaper-elevation1{
      max-width: 100vw
    }
    div.MuiDialog-paper{
      margin: 15px;
    }
    div.MuiGrid-spacing-xs-3{ 
      margin: 0;
      width: 100%;
    }
    div.MuiGrid-spacing-xs-3 > .MuiGrid-item{
      padding: 15px 0;
      max-width: 100%;
      width: 100%;
    }
    div.MuiDialog-paperWidthMd.MuiDialog-paperScrollBody {
        max-width: calc(100% - 30px);
        width: 100%;
    }
    div.bLhTnC{
      width: 100%;
    }
  
  }
  body {
    background: #f5f8fa;
    font-family: Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif !important;
    color: #86939e;
    line-height: 1.5;
    font-weight: 300 !important;
    letter-spacing: .2px;            
    -webkit-font-smoothing: antialiased !important;
  }

  .shadow{
    /* box-shadow: 0 0 6px rgba(0,0,0,.1) !important;         */
    box-shadow: 0 5px 5px 0 rgba(0,0,0,.26) !important;
  }
  .withoutShadow{
    box-shadow: none !important;
  }

  .auth_logo{
    width: 175px;
  }

  .contentCenter{
    display:flex;
    justify-content: center;
    align-items: center;    
  }

  .MuiButton-textSizeSmall{
    font-size:11px !important;
  }

  .MuiSvgIcon-colorPrimary{
    color: ${(props) => props.theme.palette.background.colorIcons} !important;
  }
  
  .MuiInputAdornment-positionStart{
    color: ${(props) => props.theme.palette.background.colorIcons} !important;
  }

  .Mui-disabled {
    cursor: not-allowed !important;
  }

  div[class*="Mui-disabled"] {
    cursor: not-allowed !important;
  }

  .swiper-container { width: 95%; height: 95%; padding: 50px; }

`;
