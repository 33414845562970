/* eslint-disable import/no-cycle */

import React from 'react';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import Form from '@solussaude/core/CustomForm';
import SolusAPI from '../../../../../services/SolusApi';
import WebContext from '@solussaude/core/WebContext';
import ActionButton from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import { LocalHospitalIcon, WarningIcon } from '@solussaude/core/Icons';
import { formItem } from '../settings';
import { ContainerForm, Container } from './styles';
import {
  validaQuantidadeCampoItem,
  validaQuantidadeItems,
  limpaCamposItem,
} from '../';

let formProcedimentosValues = [{ codigo: '', item: '' }];

let formMappingProcedimentosValues;
const getMappingFormProcedimentos = () => formMappingProcedimentosValues;
const setMappingFormProcedimentos = (newValue) => {
  formMappingProcedimentosValues = newValue;
};

export default function Procedimentos({ onClick, setMapping, getMapping }) {
  setMapping({
    ...getMapping(),
    getMappingFormProcedimentos,
    getProcedimentosFormValues: formProcedimentosValues,
  });

  const webContext = React.useContext(WebContext);
  const [state, setState] = React.useState({
    codigo: '',
    item: { label: '', value: '' },
    items: [],
  });

  const getProcedimentosFormValues = () => formProcedimentosValues;
  const setProcedimentosFormValues = (newValue, i) => {
    if (newValue.qtde < 0) {
      validaQuantidadeItems(formProcedimentosValues, i, state, setState);
      return;
    }
    formProcedimentosValues[i] = newValue;
  };

  const handleCloseModal = () => {
    webContext.showModal({
      open: false,
    });
  };

  const handleErroCodigo = () => {
    webContext.showModal({
      open: true,
      renderBody: (
        <>
          <BodyInfo
            icon={<WarningIcon color="error" />}
            title="Opss..."
            body="Código do procedimento não encontrado!"
            noDivider
          />
          <ActionButton>
            <Button
              variant="contained"
              color="secondary"
              text="Fechar"
              onClick={() => handleCloseModal()}
            />
          </ActionButton>
        </>
      ),
    });
  };

  const handleCodigo = async (i) => {
    const { codigo } = getProcedimentosFormValues()[i];

    if (codigo) {
      const dados = await SolusAPI.get(
        'v1/procedimento',
        {
          somenteReembolso: 'S',
          tipobusca: 'codigo',
          parametro: codigo,
        },
        { appName: 'api_webbeneficiario' },
      );

      if (
        dados.status === 200 &&
        !(dados.data?.length > 1 || dados.data?.length === 0)
      ) {
        formProcedimentosValues[i].item = dados.data[0].descricao;
        formProcedimentosValues[i].codigo = dados.data[0].codigo;
        setState({ ...state, items: formProcedimentosValues });
      } else {
        limpaCamposItem(formProcedimentosValues, i, state, setState);
        handleErroCodigo();
      }
    } else {
      limpaCamposItem(formProcedimentosValues, i, state, setState);
      handleErroCodigo();
    }
  };

  const handleAdiciona = () => {
    formProcedimentosValues.push(getProcedimentosFormValues());
    setState({ ...state, items: formProcedimentosValues });
  };

  const onClickRemover = (i) => {
    formProcedimentosValues.splice(i, 1);
    setState({ ...state, items: formProcedimentosValues });

    if (validaQuantidadeCampoItem(formProcedimentosValues.length)) {
      handleAdiciona();
    }
  };

  return (
    <Container>
      <BodyInfo title="Procedimentos" icon={<LocalHospitalIcon />} noDivider />
      {formProcedimentosValues.map((data, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ContainerForm key={i}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Form
              settings={formItem(
                formProcedimentosValues[i],
                () => onClick(i),
                () => handleAdiciona(),
                () => onClickRemover(i),
                () => handleCodigo(i),
                'Procedimentos',
              )}
              setValues={(newValue) => setProcedimentosFormValues(newValue, i)}
              setMappingForm={setMappingFormProcedimentos}
            />
          </form>
        </ContainerForm>
      ))}
    </Container>
  );
}
