import { processData } from '@solussaude/core/Utils';
import { InfoIcon } from '@solussaude/core/Icons';

export const formIdenticacoBeneficiario = (
  state,
  beneficiario,
  familia,
  changeSolicitacao,
  blurAtendimento,
  modalInfoProtocoloInicial,
) => ({
  form: [
    {
      inputs: [
        [
          {
            // Column
            elem_id: 'protocoloCrmInicial',
            elem_val: state.protocoloCrmInicial,
            label: 'Protocolo de atendimento inicial',
            type: 'Input',
            variant: 'outlined',
            flex: 5,
            small: true,
            icon: InfoIcon,
            onClick: () => modalInfoProtocoloInicial(),
          },
          {
            // Column
            elem_id: 'tipoClassificacaoCRM',
            elem_val: state.tipoClassificacaoCRM.selecionado,
            type: 'Select',
            label: 'Sua solicitação se refere à',
            required: true,
            variant: 'outlined',
            small: true,
            flex: 5,
            value: processData(
              state.tipoClassificacaoCRM.descricao,
              'tipoClassificacaoCrm',
              'descricao',
            ),
            onChange: (e) => changeSolicitacao(e),
          },
        ],
        [
          // Row
          {
            // Column
            elem_id: 'numeroCarteirinha',
            elem_val: beneficiario.carteirinha
              ? beneficiario.carteirinha
              : beneficiario.codigo,
            type: 'InputText',
            label: 'Número carteirinha',
            variant: 'outlined',
            flex: 5,
            small: true,
            required: true,
            disabled: true,
          },
          // Row
          {
            // Column
            elem_id: 'nomeBeneficiario',
            elem_val: beneficiario.nome,
            type: 'InputText',
            label: 'Nome do beneficiário',
            variant: 'outlined',
            flex: 5,
            small: true,
            required: true,
            disabled: true,
          },
        ],
        [
          {
            // Column
            elem_id: 'pagamentoNominal',
            type: 'Select',
            label: 'Pagamento nominal a',
            variant: 'outlined',
            small: true,
            flex: 5,
            value: processData(familia, 'id', 'nome'),
          },
          {
            // Column
            elem_id: 'nominalNaoBeneficiario',
            type: 'InputText',
            label: 'Pagamento a não beneficiário',
            variant: 'outlined',
            flex: 5,
            small: true,
          },
        ],
        [
          {
            // Column
            elem_id: 'email',
            elem_val: beneficiario.email,
            type: 'InputText',
            label: 'E-mail',
            variant: 'outlined',
            small: true,
            flex: 5,
            required: true,
          },
          {
            // Column
            elem_id: 'celular',
            elem_val: beneficiario.celular,
            type: 'InputMask',
            typeMask: 'celular',
            variant: 'outlined',
            small: true,
            required: true,
            label: 'Celular',
            flex: 3,
          },
          {
            // Column
            elem_id: 'outroCelular',
            type: 'InputMask',
            typeMask: 'celular',
            label: 'Outro celular',
            variant: 'outlined',
            small: true,
            flex: 2,
          },
        ],
        [
          {
            // Column
            elem_id: 'dataAtendimento',
            type: 'InputText',
            typeInputText: 'date',
            shrink: true,
            variant: 'outlined',
            label: 'Data do atendimento',
            required: true,
            flex: 5,
            small: true,
            onBlur: () => blurAtendimento(),
          },
        ],
      ],
    },
  ],
});
