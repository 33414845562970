/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { CloseIcon, SearchIcon } from '@solussaude/core/Icons';
import Grid from '@solussaude/core/Grid';
import Form from '@solussaude/core/CustomForm';
import Button from '@solussaude/core/Inputs/Button';
import TableModal from '@solussaude/core/Helpers/TableModal';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import ActionButton from '@solussaude/core/Helpers/ActionButtons';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import WebContext from '@solussaude/core/WebContext';
import SolusAPI from '../../../../services/SolusApi';
import defaultTheme from '../../../../styles/themes/default';
import Anexos from '../Anexos';
import DadosMedico from '../DadosMedico';
import DadosInstrumentador from './DadosInstrumentador';
import TipoItem from '../TipoItem';
import {
  formInstrumentacaoCirurgica,
  formHospital,
  tableHospital,
} from './settings';
import { Container } from './styles';

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

let formHospitalValues;
const getHospitalFormValues = () => formHospitalValues;
const setHospitalFormValues = (newValue) => {
  formHospitalValues = newValue;
};

let mappingInstrumentacaoCirurgica;
const getMappingInstrumentacaoCirurgica = () => mappingInstrumentacaoCirurgica;
const setMappingInstrumentacaoCirurgica = (pGetMapping) => {
  mappingInstrumentacaoCirurgica = pGetMapping;
};

export default function FormularioInstrumentacaoCirurgica({
  setMapping,
  getMapping,
  tipoSolicitacao,
}) {
  const theme = defaultTheme;
  const webContext = React.useContext(WebContext);
  const [state] = React.useState({
    nomeHospital: '',
  });

  setMapping({
    ...getMapping(),
    getMappingInstrumentacaoCirurgica,
    getFormInstrumentacaoCirurgica: getFormValues,
  });

  const ListagemHospital = () => {
    const [listagem, setListagem] = React.useState({
      prestador: { selecionado: '', descricao: [] },
      nomeHospital: '',
      cpf: '',
      render: <></>,
      loading: true,
    });

    React.useEffect(() => {
      const init = async () => {
        const dadosHospital = await SolusAPI.get(
          'v1/prestador',
          {},
          { appName: 'api_prestador' },
        );

        setListagem({
          ...listagem,
          prestador: { selecionado: '', descricao: dadosHospital.data },
          loading: false,
          render: (
            <>
              <TableModal
                tableSettings={tableHospital(dadosHospital.data)}
                refGetMapping={getMapping}
                idElemChange="nomeHospital"
                element="nome"
                handleClick={(row) => handleClickHospital(row)}
              />
            </>
          ),
        });
      };
      init();
    }, []);

    const handleClose = () => {
      webContext.showModal({
        open: false,
      });
    };

    const handleClickHospital = (row) => {
      const formMap = getMapping().getMappingInstrumentacaoCirurgica()();

      formMap.nomeHospital.setValues(row.nome);

      webContext.showModal({
        open: false,
      });
    };

    const handleHospital = async () => {
      const { parametro } = getHospitalFormValues();
      const dadosHospital = await SolusAPI.get(
        'v1/prestador',
        {
          nomeContendo: parametro,
        },
        { appName: 'api_prestador' },
      );

      setListagem({
        ...listagem,
        prestador: { selecionado: '', descricao: dadosHospital.data },
        render: (
          <>
            <TableModal
              tableSettings={tableHospital(dadosHospital.data)}
              refGetMapping={getMapping}
              idElemChange="nomeHospital"
              element="nome"
              handleClick={(row) => handleClickHospital(row)}
            />
          </>
        ),
      });
    };

    if (listagem.loading) return <CircularIndeterminateModal />;

    return (
      <>
        <Container>
          <BodyInfo
            icon={<SearchIcon />}
            title="Pesquise pelo hospital/clínica"
          />
          <br />
          <form onSubmit={(e) => e.preventDefault()}>
            <Form
              settings={formHospital(state)}
              setValues={setHospitalFormValues}
            />
          </form>
          <br />
          <br />
          <ActionButton direction="space-between">
            <Button
              variant="outlined"
              color="secondary"
              text="Fechar"
              startIcon={<CloseIcon />}
              onClick={() => handleClose()}
            />
            <Button
              variant="contained"
              color="primary"
              text="Procurar"
              startIcon={<SearchIcon />}
              onClick={() => handleHospital()}
            />
          </ActionButton>
          {listagem.render}
        </Container>
      </>
    );
  };

  const onClick = () => {
    webContext.showModal({
      open: true,
      renderBody: <ListagemHospital />,
    });
  };

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Grid item sm={12} md={12}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Form
                settings={formInstrumentacaoCirurgica(state, onClick)}
                setValues={setFormValues}
                setMappingForm={setMappingInstrumentacaoCirurgica}
              />
            </form>
            <br />
            <DadosMedico setMapping={setMapping} getMapping={getMapping} />
            <br />
            <DadosInstrumentador
              setMapping={setMapping}
              getMapping={getMapping}
            />
            <br />
            <TipoItem setMapping={setMapping} getMapping={getMapping} />
            <br />
            <Anexos
              setMapping={setMapping}
              getMapping={getMapping}
              tipoSolicitacao={tipoSolicitacao}
            />
            <br />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
