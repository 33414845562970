import React from 'react';
import { SendIcon } from '@solussaude/core/Icons';

const handleClick = (endereco) =>
  window.open(`http://maps.google.com.br/maps?q=${endereco}`, '_blank');

export const prestadores = (apiData) => {
  const array = [];

  apiData.forEach((prestador) => {
    let enderecoP = '';

    prestador.enderecosprestador.forEach((endereco) => {
      if (endereco.principal === 'S') {
        enderecoP = `${endereco.endereco}, ${endereco.numero} - ${endereco.complemento}+${endereco.cidade}`;
      }
    });

    if (enderecoP === '') {
      const primeiroEndereco = prestador.enderecosprestador[0];
      enderecoP = `${primeiroEndereco.endereco}, ${primeiroEndereco.numero} - ${primeiroEndereco.complemento} + ${primeiroEndereco.cidade}`;
    }
    array.push({
      nome: prestador.nome,
      conselho: prestador.conselho,
      docConselho: prestador.docConselho,
      ufConselho: prestador.ufConselho,
      rede: prestador.redescredenciadas,
      tipo: prestador.grupo_dominio,
      especialidades: prestador.especialidades,
      endereco: prestador.enderecosprestador,
      telefone: prestador.enderecosprestador,
      onClick: () => handleClick(enderecoP),
    });
  });

  return array;
};

const processData = (apiData) => {
  const array = [];
  if (apiData.length > 0) {
    apiData.forEach((img) => {
      array.push({
        img: img.img,
        label: img.label,
      });
    });
  }

  return array;
};

export const legendas = (apiData) => ({
  table: {
    options: {
      type: 'headerTop',
      small: true,
    },
    data: processData(apiData),
  },
});

const processEspecialidade = (apiData) => {
  const array = [
    {
      key: 0,
      name: 'Todas as especialidas',
    },
  ];

  apiData.forEach((especialidade) => {
    array.push({
      key: especialidade.id,
      name: especialidade.descricao,
    });
  });

  return array;
};

const processEstado = (apiData) => {
  const array = [
    {
      key: '',
      name: 'Todos os estados',
    },
  ];

  apiData.forEach((estado) => {
    array.push({
      key: estado.uf,
      name: estado.uf,
    });
  });

  return array;
};

const processCidade = (apiData) => {
  const array = [
    {
      key: '',
      name: 'Todas as cidades',
    },
  ];

  if (apiData) {
    if (apiData.length > 0) {
      apiData.forEach((c) => {
        array.push({
          key: c.cidade,
          name: c.cidade,
        });
      });
    }
  }
  return array;
};

const processPrestador = (apiData) => {
  const array = [
    {
      key: '',
      name: 'Todos os tipos de prestador',
    },
  ];

  apiData.forEach((prestador) => {
    array.push({
      key: prestador.id,
      name: prestador.descricao,
    });
  });

  return array;
};

export const renderForm = (
  nomePrestador,
  tipoPrestador,
  estado,
  cidade,
  especialidade,
  changeEstado,
  changeTipo,
  onHandleClick,
) => {
  const label = 'Cadastrar senha';
  return {
    header: {
      description:
        'Selecione os filtros abaixo para realizar a busca no Guia Médico.',
    },
    form: [
      {
        label,
        inputs: [
          [
            {
              // Column
              elem_id: 'uf',
              elem_val: estado.selecionado,
              type: 'Select',
              label: 'Estado',
              value: processEstado(estado.descricao),
              onChange: (e) =>
                changeEstado(e, estado, tipoPrestador, especialidade),
            },
          ],
          [
            {
              // Column
              elem_id: 'cidade',
              elem_val: cidade.selecionado,
              type: 'Select',
              label: 'Cidade',
              value: processCidade(cidade.descricao),
            },
          ],
          [
            {
              // Column
              elem_id: 'tipoPrestador',
              elem_val: tipoPrestador.selecionado,
              type: 'Select',
              label: 'Tipo Prestador',
              value: processPrestador(tipoPrestador.descricao),
              onChange: (e) => changeTipo(e, tipoPrestador, cidade, estado),
            },
          ],
          [
            {
              // Column
              elem_id: 'especialidade',
              elem_val: especialidade.selecionado,
              type: 'Select',
              label: 'Especialidade',
              value: processEspecialidade(especialidade.descricao),
            },
          ],
          [
            {
              elem_id: 'btnSubmit',
              type: 'Button',
              text: 'Pesquisar',
              onClick: () => onHandleClick(),
              fullWidth: true,
              color: 'primary',
              variant: 'contained',
              endIcon: <SendIcon />,
            },
          ],
        ],
      },
    ],
  };
};
