import React from 'react';

import { useHistory } from '@solussaude/core/Router';

import Login from '@solussaude/core/Login';
import { renderForm } from './settings';
import defaultTheme from '../../styles/themes/default';

const Main = () => {
  const theme = defaultTheme;

  const history = useHistory();

  return (
    <>
      <Login
        theme={theme}
        props={{ history, location: { state: 0 } }}
        module="BENEFICIARIO"
        title="Acesso ao portal de beneficiários"
        settings={renderForm}
      />
    </>
  );
};

export default Main;
