import styled from 'styled-components';

export const ContainerForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

export const ContainerAnexo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  padding: 15px;

  div > form > div > img {
    padding: 0 25px;
  }

  & > form > button {
    margin-top: 0 !important;
  }
`;

export const ContainerModalAnexo = styled.div`
  width: 650px;
`;
