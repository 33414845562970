import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;
export const ContainerMessageO = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;
export const ContainerMessageU = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
`;
export const Message = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;
  background: ${(props) =>
    props.operador ? props.theme.palette.background.linearGradient : '#ddd'};
  color: ${(props) =>
    props.operador ? props.theme.palette.primary.contrastText : '#000'};
  opacity: 0.95;
  padding: 15px;
  margin: 10px;
  border-radius: 325px;
`;
export const HeaderMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 17px;
  font-weight: 400;
`;
export const ContentMessage = styled.div`
  font-size: 15px;
  margin-top: 10px;
`;

export const ContainerInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;

  div {
    width: 100%;
    max-width: 500px;
  }

  input {
    border-radius: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    color: #333;
    font-size: 15px;
    width: 100%;
    max-width: 500px;
  }

  fieldset {
    display: none;
  }
`;
