import { SendIcon } from '@solussaude/core/Icons';
import { ContainerOption } from '@solussaude/core/RegisterPassword/NewPassword/styles';

export const temp = {};
const customComp = () => <ContainerOption key={0} />;
export const renderForm = ({ onHandleClick, onHandleClickBack }, id) => {
  const label = 'Cadastrar senha';
  return {
    header: {
      description: 'Insira seus dados para finalizar o cadastro da senha',
    },
    form: [
      {
        label,
        inputs: [
          [
            {
              elem_id: 'userID',
              elem_val: id,
            },
          ],
          [
            {
              elem_id: 'cpf',
              type: 'InputMask',
              label: 'CPF',
              required: true,
              typeMask: 'cpf',
              maskOnRequest: false,
            },
          ],
          [
            {
              elem_id: 'dataNascimento',
              type: 'InputText',
              label: 'Data de Nascimento',
              required: true,
              shrink: true,
              typeInputText: 'date',
            },
          ],
          [
            {
              elem_id: 'senha',
              type: 'InputPassword',
              label: 'Senha',
              required: true,
              fullWidth: true,
              helperText:
                'A senha deve possuir uma letra maiúscula, uma minúscula e um caractere especial!',
            },
          ],
          [
            {
              elem_id: 'repetir_senha',
              type: 'InputPassword',
              label: 'Repetir Senha',
              required: true,
              fullWidth: true,
              helperText:
                'A senha deve possuir uma letra maiúscula, uma minúscula e um caractere especial!',
            },
          ],
          [customComp()],
          [
            {
              elem_id: 'voltar',
              type: 'Button',
              text: 'Voltar',
              onClick: () => onHandleClickBack(),
              fullWidth: true,
              variant: 'outlined',
              color: 'primary',
            },
            {
              elem_id: 'enviar',
              type: 'Button',
              text: 'Cadastrar senha',
              onClick: () => onHandleClick(),
              fullWidth: true,
              color: 'primary',
              variant: 'contained',
              endIcon: <SendIcon />,
            },
          ],
        ],
      },
    ],
  };
};
