/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */

import { dateFormat, displayDate, processData } from '@solussaude/core/Utils';
import InputMask from '@solussaude/core/Inputs/InputMask';
import { LockIcon, VpnKeyIcon } from '@solussaude/core/Icons';

import { MTableEditField } from 'material-table';

const principal = { N: 'Não', S: 'Sim' };

export const renderFormSenha = {
  form: [
    {
      label: 'Esqueceu sua senha?',
      inputs: [
        [
          {
            elem_id: 'senhaAtual',
            type: 'InputText',
            label: 'Senha atual',
            required: true,
            fullWidth: true,
            typeInputText: 'password',
            icon: VpnKeyIcon,
          },
        ],
        [
          {
            elem_id: 'novaSenha',
            type: 'InputText',
            label: 'Nova senha',
            fullWidth: true,
            typeInputText: 'password',
            icon: LockIcon,
          },
        ],
        [
          {
            elem_id: 'confirmNovaSenha',
            type: 'InputText',
            label: 'Repetir nova senha',
            fullWidth: true,
            typeInputText: 'password',
            icon: LockIcon,
          },
        ],
        [
          {
            elem_id: 'btnSubmit',
            type: 'Button',
            text: 'Alterar',
            fullWidth: true,
            color: 'primary',
            variant: 'contained',
          },
        ],
      ],
    },
  ],
};

const tipos = (tipoTelefone) => {
  if (tipoTelefone) {
    return tipoTelefone.reduce(
      (obj, item) => Object.assign(obj, { [item.codigo]: item.descricao }),
      {},
    );
  }
  return null;
};
const processDataPlano = (plano, titular) => {
  const array = [];

  array.push(
    [
      {
        label: 'Titular',
        value: titular ? titular.nome : '',
      },
    ],
    [
      {
        label: 'Nome',
        value: plano.nome,
      },
    ],
    [
      {
        label: 'CPF',
        value: plano.cpf,
      },
    ],
    [
      {
        label: 'CNS - Cartão Nacional de Saúde',
        value: plano.cartao_sus,
      },
    ],
    [
      {
        label: 'Data de nascimento',
        value: dateFormat(plano.nascimento),
      },
    ],
    [
      {
        label: 'Sexo',
        value: plano.sexo === 'F' ? 'Feminino' : 'Masculino',
      },
    ],
    [
      {
        label: 'Código do beneficiário',
        value: plano.codigo,
      },
    ],
    [
      {
        label: 'Matrícula',
        value: plano.matricula,
      },
    ],
    [
      {
        label: 'Estado Civil',
        value: plano.estado_civil_extenso,
      },
    ],
    [
      {
        label: 'Categoria',
        value: plano.tipo,
      },
    ],
    [
      {
        label: 'RG',
        value: plano.rg,
      },
    ],
    [
      {
        label: 'Orgão Emissor',
        value: plano.rg_orgao_exped,
      },
    ],
  );

  return array;
};

export const dadosPlano = (apiData, apiDataTitu) => ({
  table: {
    options: {
      type: 'headerAside',
      small: true,
    },

    data: processDataPlano(apiData, apiDataTitu),
  },
});

const descricaoMeusDados = (apiData, apiAttDados = true) => {
  const fullName = apiData.nome || 'Não localizado'.split(' ');
  const firstName = fullName ? fullName.split(' ') : apiData;
  if (apiAttDados) {
    return (
      <p key={0}>
        <b style={{ fontWeight: 500 }}>{firstName[0]}, </b>
        se necessário você pode solicitar a alteração de alguns dados
        cadastrais.
      </p>
    );
  }
  return apiData.natureza_juridica === 'PJ' ? (
    <p key={0}>
      <b style={{ fontWeight: 500 }}>{firstName[0]}, </b>
      se for necessário alterar alguns de seus dados cadastrais, entre em
      contato com o RH da sua empresa.
    </p>
  ) : (
    <p key={0}>
      <b style={{ fontWeight: 500 }}>{firstName[0]}, </b>
      se for necessário alterar alguns de seus dados cadastrais, entre em
      contato com a operadora.
    </p>
  );
};

const processDataM = (
  apiData,
  apiDataE,
  apiDataN,
  apiDataOe,
  apiDataAttDados,
) => {
  const array = [];
  if (apiData) {
    array.push({
      label: 'Font custom',
      inputs: [
        [
          // Row
          {
            // Column
            elem_id: 'nomeMae',
            elem_val: apiData.nome_mae,
            type: 'InputText',
            label: 'Nome da mãe',
            required: true,
            disabled: !apiDataAttDados,
          },
        ],
        [
          // Row
          {
            // Column
            elem_id: 'nomePai',
            elem_val: apiData.nome_pai,
            type: 'InputText',
            label: 'Nome do pai',
            required: true,
            disabled: !apiDataAttDados,
          },
        ],
        [
          // Row
          {
            // Column
            elem_id: 'pis',
            elem_val: apiData.pis,
            type: 'InputMask',
            label: 'PIS / PASEP',
            typeMask: 'pis',
            maskOnRequest: false,
            required: true,
            disabled: !apiDataAttDados,
          },
          {
            // Column
            elem_id: 'cpf',
            elem_val: apiData.cpf,
            type: 'InputMask',
            label: 'CPF',
            typeMask: 'cpf',
            maskOnRequest: false,
            required: true,
            disabled: !apiDataAttDados,
          },
        ],
        [
          // Row
          {
            // Column
            elem_id: 'rg',
            elem_val: apiData.rg,
            type: 'InputMask',
            maskOnRequest: false,
            label: 'RG - Documento de identidade',
            typeMask: 'rg',
            required: true,
            disabled: !apiDataAttDados,
          },
          !apiDataAttDados
            ? {
                // Column
                elem_id: 'orgaoEmissor',
                elem_val: apiData.rg_orgao_exped,
                type: 'InputText',
                label: 'Órgão emissor',
                disabled: true,
              }
            : {
                // Column
                elem_id: 'orgaoEmissor',
                elem_val: apiData.rg_orgao_exped,
                type: 'Autocomplete',
                options: processData(apiDataOe, 'codigo', 'descricao'),
                label: 'Órgão emissor',
                labelOption: (saida) => saida.name || 'Selecione uma opção',
                required: true,
                fullWidth: true,
              },
        ],
        [
          {
            // Column
            elem_id: 'dataEmissao',
            elem_val: displayDate(apiData.rg_data_exped),
            type: 'InputText',
            typeInputText: 'date',
            shrink: true,
            label: 'Data de emissão',
            required: true,
            disabled: !apiDataAttDados,
          },
        ],
        [
          // Row
          !apiDataAttDados
            ? {
                // Column
                elem_id: 'paisEmissor',
                elem_val: apiData.rg_pais_exped_extenso,
                type: 'InputText',
                label: 'País de emissão do RG',
                disabled: true,
              }
            : {
                elem_id: 'paisEmissor',
                elem_val: apiData.rg_pais_exped
                  ? apiData.rg_pais_exped.toString()
                  : '',
                type: 'Autocomplete',
                options: processData(apiDataN, 'codigo', 'descricao'),
                label: 'País de emissão do RG',
                labelOption: (saida) => saida.name || 'Selecione uma opção',
                fullWidth: true,
              },
          {
            // Column
            elem_id: 'estadoCivil',
            elem_val: apiData.estado_civil,
            type: 'Select',
            label: 'Estado civil',
            value: processData(apiDataE, 'codigo', 'descricao'),
            required: true,
            disabled: !apiDataAttDados,
          },
        ],
      ],
    });
  }

  return array;
};

export const formMeusDados = (
  { onClickEnviarMeusDados },
  apiData,
  apiDataE,
  apiDataN,
  apiDataOe,
  apiDataAttDados = true,
) => ({
  header: {
    description: [descricaoMeusDados(apiData, apiDataAttDados)],
  },
  form: processDataM(apiData, apiDataE, apiDataN, apiDataOe, apiDataAttDados),
  footer: {
    inputs: apiDataAttDados
      ? [
          [
            // Row
            {
              // Column
              elem_id: 'btnSubmit',
              type: 'Button',
              text: 'Solicitar Alteração',
              fullWidth: true,
              color: 'primary',
              variant: 'contained',
              onClick: () => onClickEnviarMeusDados(),
            },
          ],
        ]
      : [],
  },
});

const processDataTelefone = (apiData) => {
  const array = [];
  if (apiData.length > 0) {
    apiData.forEach((telefone) => {
      array.push({
        telefone: telefone.telefone,
        tipo: telefone.tipo,
        idTelefone: telefone.id_telefone,
        origem: telefone.origem,
      });
    });
  }

  return array;
};

export const tableTelefone = (apiData, tipoTelefone, click) => ({
  table: {
    options: {
      idTable: 'T',
      titleTable: 'Telefones',
      titleButton: 'ADICIONAR TELEFONE',
      editableTable: true,
      onInsert: () => click(),
      actions: {
        insert: {},
        destroy: {},
      },
      messages: {
        insert: {
          success: 'Telefone adicionado com sucesso!',
          error: 'Erro ao adicionar o telefone!',
        },
        destroy: {
          success: 'Telefone deletado com sucesso!',
          error: 'Erro ao deletar o telefone!',
        },
      },
      add: {
        label: 'Adicionar telefone',
        idButton: 'add_telefone',
        color: 'primary',
      },
      editField: (props, get, set) => {
        const { columnDef } = props;
        if (columnDef.field === 'telefone') {
          return (
            <>
              <InputMask
                data={{
                  elem_id: 'telefone',
                  maskOnRequest: false,
                  elem_val: props.value,
                  type: 'InputMask',
                  typeMask: 'celular',
                }}
                refGetValues={get}
                refSetValues={set}
                styles={{ marginTop: 0 }}
              />
            </>
          );
        }
        return <MTableEditField {...props} />;
      },
    },
    columns: [
      {
        title: 'Telefone',
        field: 'telefone',
        custom: true,
      },
      {
        title: 'Tipo',
        field: 'tipo',
        lookup: tipos(tipoTelefone),
      },
    ],
    data: processDataTelefone(apiData),
  },
});

const processDataEmail = (apiData) => {
  const array = [];

  const tipoEmail = (tipo) => {
    if (tipo) {
      if (tipo === 'P') {
        return 'S';
      }
    }
    return 'N';
  };

  if (apiData.length > 0) {
    apiData.forEach((res) => {
      array.push({
        email: res.email,
        principal: tipoEmail(res.tipo_flag),
      });
    });
  }

  return array;
};

export const tableEmail = (apiData) => ({
  table: {
    options: {
      idTable: 'E',
      titleTable: 'Email',
      titleButton: 'ADICIONAR EMAIL',
      editableTable: true,
      add: {
        idButton: 'add_email',
        label: 'Adicionar email',
        color: 'primary',
      },
      editField: (props) => <MTableEditField {...props} />,
      actions: {
        insert: {},
      },
      messages: {
        insert: {
          success: 'E-mail adicionado com sucesso!',
          error: 'Erro ao adicionar o e-mail!',
        },
      },
    },
    columns: [
      {
        title: 'Email',
        field: 'email',
      },
      {
        title: 'Principal',
        field: 'principal',
        lookup: principal,
      },
    ],
    data: processDataEmail(apiData || []),
  },
});
