import { FormatDate } from '../../../utils';
import {
  DescriptionIcon,
  CalendarTodayIcon,
  EventIcon,
} from '@solussaude/core/Icons';
import Button from '@solussaude/core/Inputs/Button';
import { formataBase64 } from '@solussaude/core/Utils';
import * as Styled from './styles';

export const temp = {};

const processDataComunicados = (apiData) => {
  const array = [];
  if (apiData.length > 0) {
    apiData.forEach((comunicado) => {
      array.push({
        idComunicado: comunicado.idComunicadoWeb,
        data: FormatDate(comunicado.data),
        titulo: comunicado.titulo,
        mensagem: (
          <div dangerouslySetInnerHTML={{ __html: comunicado.mensagem }} />
        ),
        vigencia: FormatDate(comunicado.vigencia),
        exigeCiencia: comunicado.exigeCiencia,
        dataVisualizacao: FormatDate(comunicado.comunicadoCienciaALL[0]?.data),
        siglaStatus: comunicado.comunicadoCienciaALL[0]?.siglaStatus,
        anexos: comunicado.anexoComunicado,

        //caso não tenha anexo fica como false;
      });
    });
  }

  return array;
};

const Detail = ({
  row,
  handleIndicaCiencia,
  handleRetiraCiencia,
  handleHistoricoCiencia,
  handleAbrirArquivo,
}) => (
  <Styled.Container>
    {row.mensagem}

    {row.anexos.length !== 0 && (
      <>
        {row.anexos.map((anexo) => {
          const imagesExtension = ['GIF', 'PNG', 'JPEG', 'JPG'];

          if (imagesExtension.includes(anexo.anexo[0].extensao)) {
            const image = formataBase64(
              anexo.anexo[0].extensao,
              anexo.imagem[0].imagem,
            );
            return (
              <Styled.Padding paddingDirection="top" paddingValue="10">
                <img src={image.formatado} width="100%" height="100%" />
              </Styled.Padding>
            );
          }
        })}

        <Styled.Padding paddingDirection="top" paddingValue="20">
          <Styled.FlexDirection>
            <Button
              text="Abrir arquivos"
              color="primary"
              onClick={() => handleAbrirArquivo(row.anexos, row.titulo)}
            />
          </Styled.FlexDirection>
        </Styled.Padding>
      </>
    )}

    {row.exigeCiencia === 'S' && (
      <Styled.Padding paddingDirection="top">
        <Styled.FlexDirection>
          <Button
            text="Histórico de ciência"
            color="primary"
            fullWidth={false}
            onClick={() => handleHistoricoCiencia(row.idComunicado)}
          />
          {row.siglaStatus === 'C' ? (
            <Button
              text="Desmarcar Ciência"
              variant="outlined"
              color="primary"
              fullWidth={false}
              onClick={() => handleRetiraCiencia(row.idComunicado)}
            />
          ) : (
            <Button
              text="Indicar Ciência"
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={() => handleIndicaCiencia(row.idComunicado)}
            />
          )}
        </Styled.FlexDirection>
      </Styled.Padding>
    )}
  </Styled.Container>
);

export const dadosComunicados = (
  apiData,
  handleIndicaCiencia,
  handleRetiraCiencia,
  handleHistoricoCiencia,
  handleAbrirArquivo,
) => ({
  table: {
    options: {
      editableTable: false,
      pagination: true,
      search: true,
      pageSize: 10,
      detailPanel: (rowData) => (
        <Detail
          row={rowData}
          handleIndicaCiencia={handleIndicaCiencia}
          handleRetiraCiencia={handleRetiraCiencia}
          handleHistoricoCiencia={handleHistoricoCiencia}
          handleAbrirArquivo={handleAbrirArquivo}
        />
      ),
    },
    columns: [
      {
        title: 'Data do comunicado',
        field: 'data',
        icon: <CalendarTodayIcon color="primary" />,
      },
      {
        title: 'Título',
        field: 'titulo',
        icon: <DescriptionIcon color="primary" />,
      },
      {
        title: 'Data de visualização',
        field: 'dataVisualizacao',
        icon: <EventIcon color="primary" />,
      },
    ],
    data: processDataComunicados(apiData || []),
  },
});
