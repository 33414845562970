/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import Form from '@solussaude/core/CustomForm';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import ActionButton from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import WebContext from '@solussaude/core/WebContext';
import {
  AccountCircleIcon,
  CheckCircleIcon,
  WarningIcon,
  CloseIcon,
  InfoIcon,
} from '@solussaude/core/Icons';
import SolusAPI from '../../../../../services/SolusApi';
import { formIdenticacoBeneficiario } from './settings';
import FormularioOutros from '../../FormularioOutros';
import FormularioDeslocamento from '../../FormularioDeslocamento';
import FormularioInstrumentacaoCirurgica from '../../FormularioInstrumentacaoCirurgica';
import Recebimento from '../Recebimento';
import { dateFormat } from '@solussaude/core/Utils';
import * as Styled from './styles';

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

let mappingIdenticacoBeneficiario;
const getMappingIdenticacoBeneficiario = () => mappingIdenticacoBeneficiario;
const setMappingIdenticacoBeneficiario = (newValue) => {
  mappingIdenticacoBeneficiario = newValue;
};

export default function IdenticacoBeneficiario({
  setMapping,
  getMapping,
  beneficiario,
  familia,
}) {
  setMapping({
    ...getMapping(),
    getFormIdenticacoBeneficiario: getFormValues,
    getMappingIdenticacoBeneficiario,
  });

  const webContext = React.useContext(WebContext);

  const [state, setState] = React.useState({
    render: <></>,
    tipoClassificacaoCRM: { selecionado: '', descricao: [] },
    protocoloCrmInicial: '',
    idBeneficiario: beneficiario.id,
  });

  React.useEffect(() => {
    const init = async () => {
      const dadosSolicitacao = await SolusAPI.get(
        'v1/reembolso/classificacaoprotocolocrm',
        {},
        { appName: 'api_beneficiario' },
      );

      setState({
        ...state,
        tipoClassificacaoCRM: {
          selecionado: '',
          descricao: dadosSolicitacao.data,
        },
      });
    };
    init();
  }, []);

  const handleCloseModal = () => {
    webContext.showModal({
      open: false,
    });
  };

  const changeSolicitacao = (e) => {
    webContext.showModal({
      open: false,
    });
    const { value } = e;

    if (webContext.tipoSolicitacao === undefined) {
      webContext.tipoSolicitacao = {};
    }
    webContext.tipoSolicitacao = value;

    const { protocoloCrmInicial } = getFormValues();

    if (value === 'SR') {
      delete getMapping().getFormDeslocamento;
      delete getMapping().getFormDeslocamentoData;
      delete getMapping().getFormDadosInstrumentador;
      delete getMapping().getFormInstrumentacaoCirurgica;
      delete getMapping().getMappingFormInstrumentador;
      delete getMapping().getFormAnexos;

      setState({
        ...state,
        render: (
          <>
            <FormularioOutros
              setMapping={setMapping}
              getMapping={getMapping}
              tipoSolicitacao={value}
            />
            <Recebimento setMapping={setMapping} getMapping={getMapping} />
          </>
        ),
        tipoClassificacaoCRM: {
          ...state.tipoClassificacaoCRM,
          selecionado: value,
        },
        protocoloCrmInicial,
      });
    } else if (value === 'RD') {
      delete getMapping().getFormDadosInstrumentador;
      delete getMapping().getFormInstrumentacaoCirurgica;
      delete getMapping().getMappingFormInstrumentador;
      delete getMapping().getFormDadosMedico;
      delete getMapping().getFormOutros;
      delete getMapping().getMappingFormMatMed;
      delete getMapping().getMappingFormOPME;
      delete getMapping().getMappingFormProcedimentos;
      delete getMapping().getMappingFormTaxas;
      delete getMapping().getMatMedFormValues;
      delete getMapping().getOPMEFormValues;
      delete getMapping().getProcedimentosFormValues;
      delete getMapping().getTaxasFormValues;
      delete getMapping().getFormAnexos;

      setState({
        ...state,
        render: (
          <>
            <FormularioDeslocamento
              setMapping={setMapping}
              getMapping={getMapping}
              tipoSolicitacao={value}
            />
            <Recebimento setMapping={setMapping} getMapping={getMapping} />
          </>
        ),
        tipoClassificacaoCRM: {
          ...state.tipoClassificacaoCRM,
          selecionado: value,
        },
        protocoloCrmInicial,
      });
    } else if (value === 'RI') {
      delete getMapping().getFormDeslocamento;
      delete getMapping().getFormDeslocamentoData;
      delete getMapping().getFormOutros;
      delete getMapping().getFormAnexos;

      setState({
        ...state,
        render: (
          <>
            <FormularioInstrumentacaoCirurgica
              setMapping={setMapping}
              getMapping={getMapping}
              tipoSolicitacao={value}
            />
            <Recebimento setMapping={setMapping} getMapping={getMapping} />
          </>
        ),
        tipoClassificacaoCRM: {
          ...state.tipoClassificacaoCRM,
          selecionado: value,
        },
        protocoloCrmInicial,
      });
    } else {
      setState({
        ...state,
        render: <></>,
        tipoClassificacaoCRM: {
          ...state.tipoClassificacaoCRM,
          selecionado: value,
        },
        protocoloCrmInicial,
      });
    }
  };

  const blurAtendimento = async () => {
    const dataAtendimento = dateFormat(
      getMappingIdenticacoBeneficiario()().dataAtendimento.values,
    );

    if (dataAtendimento) {
      const existeReembolsoAtendimento = await SolusAPI.get(
        `v1/beneficiario/${state.idBeneficiario}/reembolsoatendimento`,
        { dataAtendimento },
        { appName: 'api_beneficiario' },
      );

      if (existeReembolsoAtendimento.status === 200) {
        webContext.showModal({
          open: true,
          renderBody: (
            <>
              <BodyInfo
                icon={<WarningIcon color="primary" />}
                title="Alerta"
                body={
                  <>
                    {existeReembolsoAtendimento.data.mensagem}
                    <p>Deseja Continuar?</p>
                  </>
                }
              />
              <ActionButton direction="space-between">
                <Button
                  variant="outlined"
                  color="secondary"
                  text="Ver Protocolos"
                  onClick={() =>
                    window.open('beneficiario/info/meus-protocolos')
                  }
                />
                <Button
                  variant="contained"
                  color="primary"
                  text="Continuar"
                  onClick={() => handleCloseModal()}
                />
              </ActionButton>
            </>
          ),
          disableEscapeKeyDown: true,
          disableBackdropClick: true,
        });
      }
    }
  };

  const handleAceita = (e) => {
    if (state.tipoClassificacaoCRM.selecionado !== '') {
      webContext.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<WarningIcon color="primary" />}
              title="Alerta"
              body="Ao trocar o tipo de solicitação, os dados preenchidos serão perdidos!"
              noDivider
            />
            <ActionButton direction="space-between">
              <Styled.ContainerButton paddingDirection="right">
                <Button
                  variant="outlined"
                  color="secondary"
                  text="Fechar"
                  startIcon={<CloseIcon />}
                  fullWidth={true}
                  onClick={() => {
                    getMappingIdenticacoBeneficiario()().tipoClassificacaoCRM.setValues(
                      state.tipoClassificacaoCRM.selecionado,
                    );
                    handleCloseModal();
                  }}
                />
              </Styled.ContainerButton>
              <Styled.ContainerButton paddingDirection="left">
                <Button
                  variant="contained"
                  color="primary"
                  text="Aceitar"
                  fullWidth={true}
                  startIcon={<CheckCircleIcon />}
                  onClick={() => changeSolicitacao(e)}
                />
              </Styled.ContainerButton>
            </ActionButton>
          </>
        ),
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
      });
    } else {
      changeSolicitacao(e);
    }
  };

  const modalInfoProtocoloInicial = () => {
    webContext.showModal({
      open: true,
      renderBody: (
        <>
          <BodyInfo
            icon={<InfoIcon color="secondary" />}
            title="O que é o protocolo de atendimento inicial?"
            body="Quando houver um protocolo de um atendimento anterior, o mesmo deve ser informado neste campo.
            Por exemplo, caso anteriormente foi solicitado um reembolso de instrumentação cirúrgica e nesse momento necessita solicitar um reembolso de deslocamento, referente ao primeiro reembolso citado,
            deverá ser informado o número do protocolo anterior."
            noDivider
          />
          <ActionButton direction="flex-end">
            <Button
              variant="contained"
              color="primary"
              text="Concluído"
              onClick={() => handleCloseModal()}
            />
          </ActionButton>
        </>
      ),
    });
  };

  return (
    <>
      <BodyInfo
        icon={<AccountCircleIcon />}
        title="Identificação do beneficiário"
        noDivider
      />
      <Form
        settings={formIdenticacoBeneficiario(
          state,
          beneficiario,
          familia,
          handleAceita,
          blurAtendimento,
          modalInfoProtocoloInicial,
        )}
        setValues={setFormValues}
        setMappingForm={setMappingIdenticacoBeneficiario}
      />
      {state.render}
      <br />
    </>
  );
}
