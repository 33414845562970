import { dateFormat } from '@solussaude/core/Utils';

const processMensagem = (apiData) => {
  const array = [];

  if (apiData.length > 0) {
    apiData.forEach((mensagem) => {
      array.push({
        type: mensagem.tipo_mensagem_flag === '0' ? 'operador' : 'usuario',
        title: mensagem.operador,
        date: dateFormat(mensagem.data_mensagem),
        message: mensagem.texto,
      });
    });
  }

  return array;
};

export const renderMensagens = (apiData) => ({
  items: processMensagem(apiData),
});

const processDataAtendimento = (apiData) => {
  const array = [];

  if (apiData.length > 0) {
    apiData.forEach((atendimento) => {
      array.push(
        [
          {
            label: 'Título',
            value: atendimento.titulo,
          },
        ],
        [
          {
            label: 'Protocolo',
            value: atendimento.protocolo,
          },
        ],
        [
          {
            label: 'Data abertura',
            value: dateFormat(atendimento.data_abertura),
          },
        ],
        [
          {
            label: 'Situação',
            value: atendimento.situacao,
          },
        ],
        [
          {
            label: 'Origem do atendimento',
            value: atendimento.origem,
          },
        ],
      );
    });
  }
  return array;
};

export const dadosAtendimento = (apiData) => ({
  table: {
    options: {
      type: 'headerAside',
      small: true,
      bold: true,
    },
    data: processDataAtendimento(apiData || []),
  },
});
