/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import TableModal from '@solussaude/core/Helpers/TableModal';
import Form from '@solussaude/core/CustomForm';
import Button from '@solussaude/core/Inputs/Button';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import ActionButton from '@solussaude/core/Helpers/ActionButtons';
import WebContext from '@solussaude/core/WebContext';
import { CloseIcon, SearchIcon } from '@solussaude/core/Icons';
import SolusAPI from '../../../../services/SolusApi';
import Procedimentos from './Procedimentos';
import MatMed from './MatMed';
import Taxas from './Taxas';
import OPME from './OPME';
import { formPesquisa, tableItem } from './settings';
import { Container } from './styles';

let formPesquisaValues;
const getFormPesquisaValues = () => formPesquisaValues;
const setFormPesquisaValues = (newValue) => {
  formPesquisaValues = newValue;
};

// Valida a quantidade de campos que tem cada item.
export const validaQuantidadeCampoItem = (qtdCampo) => {
  if (qtdCampo === 0) {
    return true;
  }
  return false;
};

//Valida a quantidade de cada item.
export const validaQuantidadeItems = (formName, i, state, setState) => {
  formName[i].qtde = 0;
  setState({ ...state, items: formName });
};

export const limpaCamposItem = (form, i, state, setState) => {
  form[i].item = '';
  form[i].codigo = '';
  form[i].qtde = '';
  form[i].valor = '';
  form[i].id = '';
  setState({ ...state, items: form });
};

export default function TipoItem({ setMapping, getMapping }) {
  const webContext = React.useContext(WebContext);

  const handleClose = () => {
    webContext.showModal({
      open: false,
    });
  };

  const handleTipoItem = (tipo) => {
    let formMap;
    let formValues;

    if (tipo === 'Procedimentos') {
      formMap = getMapping().getMappingFormProcedimentos()();
      formValues = getMapping().getProcedimentosFormValues;
    }
    if (tipo === 'MatMed') {
      formMap = getMapping().getMappingFormMatMed()();
      formValues = getMapping().getMatMedFormValues;
    }
    if (tipo === 'Taxas') {
      formMap = getMapping().getMappingFormTaxas()();
      formValues = getMapping().getTaxasFormValues;
    }
    if (tipo === 'OPME') {
      formMap = getMapping().getMappingFormOPME()();
      formValues = getMapping().getOPMEFormValues;
    }

    return { formMap, formValues };
  };

  const handleClickItem = (row, tipo) => {
    const { formMap } = handleTipoItem(tipo);
    formMap.codigo.setValues(row.codigo);
    formMap.item.setValues(row.descricao);
    formMap.id.setValues(row.id);

    webContext.showModal({
      open: false,
    });
  };

  const reqItem = async ({
    tipo,
    parametro,
    tipoBusca,
    quantidaderegistro,
  }) => {
    let dadosItem = {};
    if (tipo === 'Procedimentos') {
      dadosItem = await SolusAPI.get(
        'v1/procedimento',
        {
          parametro,
          tipobusca: tipoBusca,
          quantidaderegistro,
        },
        { appName: 'api_webbeneficiario' },
      );
    }
    if (tipo === 'MatMed') {
      dadosItem = await SolusAPI.get(
        'v1/produto',
        {
          tipoSigla: 'M',
          parametro: 'AD',
          tipoBusca: tipoBusca,
          limit: quantidaderegistro,
        },
        { appName: 'api_webbeneficiario' },
      );
    }
    if (tipo === 'Taxas') {
      dadosItem = await SolusAPI.get(
        'v1/taxa',
        {
          parametro,
          tipobusca: tipoBusca,
          quantidaderegistro,
        },
        { appName: 'api_webbeneficiario' },
      );
    }
    if (tipo === 'OPME') {
      dadosItem = await SolusAPI.get(
        'v1/produto',
        {
          tipoSigla: 'O',
          parametro: 'AD',
          tipoBusca: tipoBusca,
          limit: quantidaderegistro,
        },
        { appName: 'api_webbeneficiario' },
      );
    }

    return dadosItem;
  };

  const ListagemItem = ({ tipo }) => {
    const [listagem, setListagem] = React.useState({
      codigo: '',
      item: { label: '', value: '' },
      render: <></>,
      loading: true,
      qtdRegistro: 10,
      qtdRequisicao: 0,
      tipoBusca: 'descricao',
      parametro: '',
    });

    React.useEffect(
      () => setListagem({ ...listagem, qtdRegistro: 0, loading: false }),
      [],
    );

    React.useEffect(
      () =>
        setListagem({
          ...listagem,
          loading: false,
          qtdRegistro: 10,
          qtdRequisicao: 0,
        }),
      [listagem.tipoBusca],
    );

    const changePesquisa = () => {
      const { tipoBusca, parametro } = getFormPesquisaValues();

      setListagem({ ...listagem, tipoBusca, parametro });
    };

    const handleItem = async () => {
      const { parametro, tipoBusca } = getFormPesquisaValues();

      const dadosItem = await reqItem({
        tipo,
        parametro,
        tipoBusca,
        quantidaderegistro: listagem.qtdRegistro,
      });

      setListagem({
        ...listagem,
        tipoBusca,
        qtdRequisicao: listagem.qtdRequisicao + 1,
        qtdRegistro: (listagem.qtdRegistro += 10),
        item: { selecionado: '', descricao: dadosItem.data },
        render: (
          <>
            <TableModal
              tableSettings={tableItem(dadosItem.data, tipo)}
              refGetMapping={getMapping}
              idElemChange="nomeInstrumentador"
              element="nome"
              handleClick={(row) => handleClickItem(row, tipo)}
            />
          </>
        ),
      });
    };

    if (listagem.loading) return <CircularIndeterminateModal />;

    return (
      <>
        <Container>
          <BodyInfo
            icon={<SearchIcon />}
            title="Pesquise pelo item utilizado"
          />
          <br />
          <Form
            settings={formPesquisa(listagem, changePesquisa)}
            setValues={setFormPesquisaValues}
          />
          <br />
          <br />
          <ActionButton direction="space-between">
            <Button
              variant="outlined"
              color="secondary"
              text="Fechar"
              startIcon={<CloseIcon />}
              onClick={() => handleClose()}
            />
            <Button
              variant="contained"
              color="primary"
              text={
                listagem.qtdRequisicao === 0 ? 'Procurar' : 'Mais registros'
              }
              startIcon={<SearchIcon />}
              onClick={() => handleItem()}
            />
          </ActionButton>
          {listagem.render}
        </Container>
      </>
    );
  };

  const onClick = async (tipo, i) => {
    const { formValues } = handleTipoItem(tipo);

    if (formValues[i].codigo) {
      const dadosItem = await reqItem({
        tipo,
        parametro: formValues[i].codigo,
        tipoBusca: 'codigo',
        quantidaderegistro: 1,
      });

      if (dadosItem === 200) {
        handleClickItem(dadosItem.data[0], tipo);
      }
    }

    webContext.showModal({
      open: true,
      renderBody: <ListagemItem tipo={tipo} />,
    });
  };

  return (
    <>
      <Procedimentos
        onClick={(i) => onClick('Procedimentos', i)}
        setMapping={setMapping}
        getMapping={getMapping}
      />
      <MatMed
        onClick={(i) => onClick('MatMed', i)}
        setMapping={setMapping}
        getMapping={getMapping}
      />
      <Taxas
        onClick={(i) => onClick('Taxas', i)}
        setMapping={setMapping}
        getMapping={getMapping}
      />
      <OPME
        onClick={(i) => onClick('OPME', i)}
        setMapping={setMapping}
        getMapping={getMapping}
      />
    </>
  );
}
