/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import {
  DashboardIcon,
  PaymentIcon,
  MessageIcon,
  AssignmentLateIcon,
  AssignmentIcon,
  WarningIcon,
  DescriptionIcon,
  CheckIcon,
  ReceiptIcon,
} from '@solussaude/core/Icons';

import { Link } from '@solussaude/core/Router';
import Grid from '@solussaude/core/Grid';
import Table from '@solussaude/core/DataDisplay/TableSimple';
import Page from '@solussaude/core/Surfaces/Page';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import Button from '@solussaude/core/Inputs/Button';
import ButtonInfo from '@solussaude/core/Inputs/ButtonInfo';
import ActionButton from '@solussaude/core/Helpers/ActionButtons';
import Slide from '@solussaude/core/Slide';
import MensalidadeCard from '@solussaude/core/Helpers/MensalidadeCard';
import Skeleton from '@solussaude/core/Lab/Skeleton';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import WebContext from '@solussaude/core/WebContext';
import {
  isPromise,
  dateFormat2,
  formatMoney,
  formataBase64,
} from '@solussaude/core/Utils';

import DemonstrativoIR from '../Relatorio/DemonstrativoIR';
import SolusAPI from '../../services/SolusApi';
import defaultTheme from '../../styles/themes/default';
import { NoStylesLink } from './styles';
import { slider, tableDadosComunicados } from './settings';

export default function Dashboard() {
  const context = React.useContext(WebContext);
  const theme = defaultTheme;
  const [state, setState] = React.useState({
    loading: true,
    apiDataContas: [],
    apiDataComunicados: [],
    successApiComunicados: true,
    apiDataImagem: [],
    successApiImagem: true,
    naturezaJuridica: '',
    guiaMedico: 'N',
    demonstrativoIR: false,
    existePendenciaComunicados: false,
  });

  const handleDadosComunicadosPendentes = async () => {
    const userAuth = await isPromise(context.module.beneficiario.userAuth);

    const dadosComunicadosPendentes = await SolusAPI.get(
      `v1/beneficiario/${userAuth.id}/comunicadoweb`,
      {
        todosComunicados: 'S',
        comunicadosPendente: 'S',
        obrigaCiencia: 'S',
        vigencia: 'S',
      },
      { appName: 'api_webbeneficiario' },
    );

    return dadosComunicadosPendentes;
  };

  React.useEffect(() => {
    const search = async () => {
      const userAuth = await isPromise(context.module.beneficiario.userAuth);

      const dadosImagem = await SolusAPI.post('api/settings/imagens', {
        pasta: '/slider',
      });

      const dadosContas = await SolusAPI.post(
        'api/beneficiarios/contasReceber',
        {
          regInicial: 0,
          regFinal: 1,
          dataInicial: '01/01/1900',
          dataFinal: '31/12/2100',
          contrato: userAuth.id_contrato,
          status: 'A',
        },
      );

      const dadosComunicados = await SolusAPI.get(
        `v1/beneficiario/${userAuth.id}/comunicadoweb`,
        {
          todosComunicados: 'S',
        },
        { appName: 'api_webbeneficiario' },
      );

      //Lista apenas os comunicados pendentes;
      const dadosComunicadosPendentes = await handleDadosComunicadosPendentes();

      const guiaMedico = await SolusAPI.get('api/settings/rede');

      const dadosOperador = await SolusAPI.get(
        'v1/operador',
        {
          login: 'OPERADOR_API',
        },
        { appName: 'api_webbeneficiario' },
      );

      const { data } = await SolusAPI.get('api/recupera-dados-config');

      setState({
        ...state,
        loading: false,
        apiDataContas: dadosContas.data[0],
        apiDataComunicados: dadosComunicados.data,
        successApiComunicados: dadosComunicados.success,
        apiDataImagem: dadosImagem.data,
        successApiImagem: dadosImagem.success,
        naturezaJuridica: userAuth.natureza_juridica,
        guiaMedico: guiaMedico.data === 'S',
        demonstrativoIR: data !== undefined && data.demonstrativo_irpf === 'S',
        existePendenciaComunicados: dadosComunicadosPendentes.data?.length > 0,
        apiDataComunicadosPendentes: dadosComunicadosPendentes.data,
        idOperador: dadosOperador.data.idOperador,
        idBeneficiario: userAuth.id,
        idContrato: userAuth.id_contrato,
      });
    };

    search();
  }, []);

  const nameCapitalized = (name) =>
    name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

  const handleCloseModal = async () => {
    const dadosComunicadosPendentes = await handleDadosComunicadosPendentes();
    if (dadosComunicadosPendentes.data?.length > 0) {
      setState({
        ...state,
        existePendenciaComunicados: dadosComunicadosPendentes.data?.length > 0,
        apiDataComunicadosPendentes: dadosComunicadosPendentes.data,
      });
    } else {
      context.showModal({
        open: false,
      });
    }
  };

  const renderMensalidade = () => {
    if (state.apiDataContas) {
      if (state.apiDataContas.situacao !== 'N') {
        return <></>;
      }

      return (
        <MensalidadeCard
          dia={dateFormat2(state.apiDataContas.vencimento).day}
          mes={nameCapitalized(
            dateFormat2(state.apiDataContas.vencimento).month,
          )}
          valor={formatMoney(state.apiDataContas.valor).replace(',', '')}
          situacao="Em aberto"
          documento={state.apiDataContas.numero_documento}
          to="/beneficiario/documents"
        />
      );
    }
    return <></>;
  };

  const renderErrorComunicados = () => (
    <BodyInfo
      icon={<WarningIcon color="error" />}
      title="Opss..."
      body="Não foi possivel carregar seus comunicados tente novamente mais tarde!"
    />
  );

  const handleSlider = () => {
    if (state.loading === false) {
      if (state.apiDataImagem.length > 0) {
        return <Slide settings={slider(state.apiDataImagem)} />;
      }
      return null;
    }

    return <CircularIndeterminateModal />;
  };

  const handleIndicaCiencia = async (idComunicado) => {
    const dadosIndicaCiencia = await SolusAPI.post(
      `v1/beneficiario/${state.idBeneficiario}/comunicadoweb/${idComunicado}/ciencia`,
      {
        idComunicado: idComunicado,
        idOperador: state.idOperador,
        idBeneficiario: state.idBeneficiario,
        idContrato: state.idContrato,
        siglaStatus: 'C',
      },
      { appName: 'api_webbeneficiario' },
    );

    if (dadosIndicaCiencia.status === 201) {
      //Ciencia indicada com sucesso
      context.showModal({
        open: true,
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<CheckIcon color="primary" />}
              title="Comunicado"
              body="Ciência do comunicado indicada com sucesso!"
              noDivider
            />
            <ActionButton>
              <Button
                variant="contained"
                color="primary"
                text="Concluir"
                endIcon={<CheckIcon />}
                onClick={() => handleCloseModal()}
              />
            </ActionButton>
          </>
        ),
      });
    } else {
      //Erro ao indicar ciencia
      context.showModal({
        open: true,
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<WarningIcon color="secondary" />}
              title="Ops..."
              body="Não foi possivel indicar a ciência no comunicado, tente novamente mais tarde!"
              noDivider
            />
            <ActionButton>
              <Button
                variant="contained"
                color="primary"
                text="Concluir"
                endIcon={<CheckIcon />}
                onClick={() => handleCloseModal()}
              />
            </ActionButton>
          </>
        ),
      });
    }
  };

  const handleAbrirArquivo = (anexos, titulo) => {
    anexos.map((anexo) => {
      const archive = formataBase64(
        anexo.anexo[0].extensao,
        anexo.imagem[0].imagem,
      );

      if (archive?.formatado) {
        let pdfWindow = window.open(archive.formatado, '_blank');
        pdfWindow.document.write(
          `<html><title>${titulo}</title><iframe style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;" src=
          ${encodeURI(archive.formatado)}></iframe></html>`,
        );
      }
    });
  };

  const renderComunicados = () => {
    if (state.loading === false) {
      if (state.existePendenciaComunicados) {
        state.apiDataComunicadosPendentes.map((comunicado) => {
          context.showModal({
            open: true,
            disableEscapeKeyDown: true,
            disableBackdropClick: true,
            renderBody: (
              <>
                <BodyInfo
                  icon={<DescriptionIcon color="primary" />}
                  title={comunicado.titulo}
                  body={
                    <div
                      dangerouslySetInnerHTML={{ __html: comunicado.mensagem }}
                    />
                  }
                  noDivider
                />

                {comunicado.anexoComunicado.map((anexo) => {
                  const imagesExtension = ['GIF', 'PNG', 'JPEG', 'JPG'];
                  if (imagesExtension.includes(anexo.anexo[0].extensao)) {
                    const image = formataBase64(
                      anexo.anexo[0].extensao,
                      anexo.imagem[0].imagem,
                    );

                    return (
                      <img src={image.formatado} width="100%" height="100%" />
                    );
                  }
                })}

                {comunicado.anexoComunicado.length > 0 && (
                  <ActionButton direction="flex-start">
                    <Button
                      text="Abrir arquivos"
                      onClick={() =>
                        handleAbrirArquivo(
                          comunicado.anexoComunicado,
                          comunicado.titulo,
                        )
                      }
                    />
                  </ActionButton>
                )}
                <ActionButton>
                  <Button
                    text="Indicar ciência"
                    color="primary"
                    variant="contained"
                    fullWidth={true}
                    onClick={() =>
                      handleIndicaCiencia(comunicado.idComunicadoWeb)
                    }
                  />
                </ActionButton>
              </>
            ),
          });
        });
      }

      return (
        <Table
          settings={tableDadosComunicados(state.apiDataComunicados)}
          inLoading={state.loading}
        />
      );
    }
  };

  return (
    <>
      <Page title="Meu painel" icon={DashboardIcon}>
        {handleSlider()}
        <Grid container spacing={theme.grid.spacing}>
          {!state.naturezaJuridica || state.naturezaJuridica === 'PJ' ? null : (
            <Grid item md={7} sm={12} style={{ maxWidth: '100%' }}>
              <BodyInfo
                title="Boletos"
                body="Confira aqui seus boletos em aberto. Caso tenha alguma cobrança em atraso, você pode emitir a 2º via do seu boleto."
                noDivider
              />

              <Grid container>
                <Grid item lg={12} md={12} sm={12}>
                  {state.loading === false ? (
                    renderMensalidade()
                  ) : (
                    <Skeleton variant="rect" width={210} height={118} />
                  )}
                </Grid>
              </Grid>

              <NoStylesLink>
                <Link to="/beneficiario/documents">
                  <Button
                    text="Ver meus boletos"
                    startIcon={<PaymentIcon />}
                    fullWidth={false}
                  />
                </Link>
              </NoStylesLink>
            </Grid>
          )}
          <Grid item lg={5} md={12} sm={12}>
            <BodyInfo
              title="Comunicados"
              body="Os comunicados são importantes para se manter atualizado, fique por dentro!"
              noDivider
            />

            {state.successApiComunicados === true
              ? renderComunicados()
              : renderErrorComunicados()}
            <br />

            <NoStylesLink>
              <Link to={`${localStorage.getItem('basename')}/info/comunicados`}>
                <Button
                  text="Ver mais"
                  startIcon={<MessageIcon />}
                  fullWidth={false}
                />
              </Link>
            </NoStylesLink>
          </Grid>
        </Grid>
      </Page>

      {!state.naturezaJuridica || state.naturezaJuridica === 'PJ' ? null : (
        <Page title="Acesso rápido" icon={DashboardIcon}>
          <Grid container spacing={theme.grid.spacing}>
            <Grid item md={12} sm={12}>
              <BodyInfo title="Serviços" />
              <ButtonInfo
                title="Solicitar 2º via carteirinha"
                icon={<PaymentIcon />}
                link="/beneficiario/servicos/carteirinha"
              />
              <ButtonInfo
                title="Solicitar cancelamento"
                icon={<AssignmentLateIcon />}
                link="/beneficiario/servicos/cancelamento-contrato"
              />
              {state.guiaMedico ? (
                <ButtonInfo
                  title="Guia médico"
                  icon={<AssignmentIcon />}
                  link="/beneficiario/info/guias-medico"
                />
              ) : null}
              {state.demonstrativoIR ? (
                <ButtonInfo
                  title="Demonstrativo para I.R"
                  icon={<ReceiptIcon />}
                  renderModal={<DemonstrativoIR />}
                />
              ) : null}
            </Grid>
          </Grid>
        </Page>
      )}
    </>
  );
}
