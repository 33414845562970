import React from 'react';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import Form from '@solussaude/core/CustomForm';
import SolusAPI from '../../../../../services/SolusApi';
import WebContext from '@solussaude/core/WebContext';
import ActionButton from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import { AttachMoneyIcon, WarningIcon } from '@solussaude/core/Icons';
import { formItem } from '../settings';
import { ContainerForm, Container } from './styles';

import {
  validaQuantidadeCampoItem,
  validaQuantidadeItems,
  limpaCamposItem,
} from '../';

let formTaxasValues = [{ codigo: '', item: '' }];

let formMappingTaxasValues;
const getMappingFormTaxas = () => formMappingTaxasValues;
const setMappingFormTaxas = (newValue) => {
  formMappingTaxasValues = newValue;
};

export default function Taxas({ onClick, setMapping, getMapping }) {
  setMapping({
    ...getMapping(),
    getMappingFormTaxas,
    getTaxasFormValues: formTaxasValues,
  });

  const webContext = React.useContext(WebContext);
  const [state, setState] = React.useState({
    codigo: '',
    item: { label: '', value: '' },
  });

  const getTaxasFormValues = () => formTaxasValues;
  const setTaxasFormValues = (newValue, i) => {
    if (newValue.qtde < 0) {
      validaQuantidadeItems(formTaxasValues, i, state, setState);
      return;
    }
    formTaxasValues[i] = newValue;
  };

  const handleCloseModal = () => {
    webContext.showModal({
      open: false,
    });
  };

  const handleErroCodigo = () => {
    webContext.showModal({
      open: true,
      renderBody: (
        <>
          <BodyInfo
            icon={<WarningIcon color="error" />}
            title="Opss..."
            body="Código da taxa não encontrado!"
            noDivider
          />
          <ActionButton>
            <Button
              variant="contained"
              color="secondary"
              text="Fechar"
              onClick={() => handleCloseModal()}
            />
          </ActionButton>
        </>
      ),
    });
  };

  const handleCodigo = async (i) => {
    const { codigo } = getTaxasFormValues()[i];

    if (codigo) {
      const dados = await SolusAPI.get(
        'v1/taxa',
        {
          somenteReembolso: 'S',
          tipobusca: 'codigo',
          parametro: codigo,
        },
        { appName: 'api_webbeneficiario' },
      );

      if (
        dados.status === 200 &&
        !(dados.data?.length > 1 || dados.data?.length === 0)
      ) {
        formTaxasValues[i].item = dados.data[0].descricao;
        formTaxasValues[i].codigo = dados.data[0].codigo;
        setState({ ...state, items: formTaxasValues });
      } else {
        limpaCamposItem(formTaxasValues, i, state, setState);
        handleErroCodigo();
      }
    } else {
      limpaCamposItem(formTaxasValues, i, state, setState);
    }
  };

  const handleAdiciona = () => {
    formTaxasValues.push(getTaxasFormValues());
    setState({ ...state, items: formTaxasValues });
  };

  const onClickRemover = (i) => {
    formTaxasValues.splice(i, 1);
    setState({ ...state, items: formTaxasValues });

    if (validaQuantidadeCampoItem(formTaxasValues.length)) {
      handleAdiciona();
    }
  };

  return (
    <Container>
      <BodyInfo title="Taxas/Diárias" icon={<AttachMoneyIcon />} noDivider />

      {formTaxasValues.map((data, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ContainerForm key={i}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Form
              settings={formItem(
                formTaxasValues[i],
                () => onClick(i),
                () => handleAdiciona(),
                () => onClickRemover(i),
                () => handleCodigo(i),
                'Taxas',
              )}
              setValues={(newValue) => setTaxasFormValues(newValue, i)}
              setMappingForm={setMappingFormTaxas}
            />
          </form>
        </ContainerForm>
      ))}
    </Container>
  );
}
