export const FormatDate = (date, reserveDate, time) => {
  if (reserveDate) {
    const year = date.split('-')[0];
    const month = date.split('-')[1];
    const dayTime = date.split('-')[2];
    const day = dayTime.split('T')[0];
    const dayHour = dayTime.split(':')[0];
    const hour = dayHour.substr(3, 2);
    const minute = dayTime.split(':')[1];
    const secondTimeZone = dayTime.split(':')[2];
    const second = secondTimeZone.substr(3, 2);

    const dateFormated = [day, month, year].join('/');

    if (time) {
      const timeFormated = [hour, minute, second].join(':');
      return [dateFormated, timeFormated].join(' - ');
    }

    return dateFormated;
  }

  if (typeof date === ('number' || 'object' || 'array')) {
    return date;
  }

  if (date && typeof date === 'string') {
    const arrayDate = date.split('-');
    if (arrayDate.length === 3 && Number(arrayDate[0])) {
      return arrayDate.join('/');
    }

    return date;
  }

  return date;
};
