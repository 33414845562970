import React from 'react';
import { DeleteIcon, SearchIcon, AddIcon } from '@solussaude/core/Icons';
import Tooltip from '@solussaude/core/DataDisplay/Tooltip';

const tipoPesquisa = [
  { key: 'descricao', name: 'Descrição' },
  { key: 'codigo', name: 'Código' },
];

export const formPesquisa = (state, changePesquisa) => {
  return {
    form: [
      {
        inputs: [
          [
            {
              // Column
              elem_id: 'tipoBusca',
              elem_val: state.tipoBusca,
              type: 'Select',
              label: 'Tipo de pesquisa',
              required: true,
              variant: 'outlined',
              small: true,
              value: tipoPesquisa,
              onChange: () => changePesquisa(),
            },
          ],
          [
            {
              // Column
              elem_id: 'parametro',
              elem_val: state.parametro,
              type: 'InputText',
              label: '',
              variant: 'outlined',
              small: true,
              flex: 12,
            },
          ],
        ],
      },
    ],
  };
};

const item = (apiData, tipo) => {
  const array = [];
  let id = '';

  if (tipo === 'Procedimentos') {
    id = 'codigo';
  }
  if (tipo === 'MatMed') {
    id = 'idProduto';
  }
  if (tipo === 'Taxas') {
    id = 'idTaxa';
  }
  if (tipo === 'OPME') {
    id = 'idProduto';
  }

  if (apiData) {
    if (apiData.length > 0) {
      apiData.forEach((item) => {
        array.push({
          id: item[id],
          codigo: item.codigo,
          descricao: item.descricao,
          codigoTuss: item.codigoTuss,
          codigoTiss: item.codigoTiss,
          ehTissSigla: item.ehTissSigla,
        });
      });
    }
  }

  return array;
};

export const tableItem = (apiData, tipo) => ({
  table: {
    options: {
      type: 'headerTop',
      small: true,
      clickable: true,
      pagination: true,
    },
    headers: [
      { label: 'Id' },
      { label: 'Código' },
      { label: 'Descrição' },
      { label: 'Laboratório' },
      { label: 'Cód. Referência' },
      { label: 'Nº Anvisa' },
    ],
    data: item(apiData, tipo),
  },
});

export const formItem = (
  apiData,
  onClick,
  onClickSalva,
  onClickRemover,
  handleCodigo,
  tipoItem,
) => ({
  form: [
    {
      inputs: [
        [
          {
            // Column
            elem_id: 'codigo',
            elem_val: apiData.codigo,
            label: 'Código',
            type: 'Input',
            icon: SearchIcon,
            flex: 3,
            required: true,
            variant: 'outlined',
            onClick: (i) => onClick(i),
            onBlur: () => handleCodigo(),
            small: true,
          },
          {
            // Column
            elem_id: 'item',
            elem_val: apiData.item,
            type: 'InputText',
            label: tipoItem,
            flex: 4,
            variant: 'outlined',
            disabled: true,
            small: true,
          },
          {
            // Column
            elem_id: 'qtde',
            elem_val: apiData.qtde,
            type: 'InputText',
            typeInputText: 'number',
            label: 'Qtde',
            flex: 1,
            variant: 'outlined',
            small: true,
          },
          {
            // Column
            elem_id: 'valorReembolso',
            elem_val: apiData.valorReembolso,
            type: 'InputText',
            mask: 'money',
            label: 'Valor',
            variant: 'outlined',
            small: true,
            flex: 2,
          },
          {
            elem_id: 'btnSalva',
            type: 'IconButton',
            children: (
              <Tooltip icon={<AddIcon color="primary" />} title="Adicionar" />
            ),
            onClick: () => onClickSalva(),
            flex: 1,
          },
          {
            elem_id: 'btn',
            type: 'IconButton',
            children: (
              <Tooltip
                icon={<DeleteIcon color="secondary" />}
                title="Remover"
              />
            ),
            onClick: () => onClickRemover(),
            flex: 1,
          },
        ],
        [
          {
            // Column
            elem_id: 'id',
            elem_val: apiData.id,
            type: 'InputText',
            typeInputText: 'hidden',
            label: '',
            flex: 1,
            small: true,
          },
        ],
      ],
    },
  ],
});
