import { processData } from '@solussaude/core/Utils';

export const formDadosBancarios = (
  apiData,
  blurBanco,
  blurFormataDadosBancarios,
) => ({
  form: [
    {
      inputs: [
        [
          {
            // Column
            elem_id: 'tipoConta',
            type: 'RadioButton',
            label: 'Tipo de conta bancária para o depósito',
            value: processData(
              apiData.tipoConta.descricao,
              'valor',
              'descricao',
            ),
            variant: 'outlined',
            small: true,
          },
        ],
        [
          {
            // Column
            elem_id: 'banco',
            type: 'Autocomplete',
            label: 'Banco',
            variant: 'outlined',
            options: processData(
              apiData.banco.descricao,
              'id',
              'descricao',
            ),
            labelOption: (saida) => saida.name || 'Selecione uma opção',
            fullWidth: true,
            small: true,
            required: true,
            flex: 5,
            onBlur: () => blurBanco(),
          },
        ],
        [
          {
            // Column
            elem_id: 'agencia',
            type: 'InputText',
            label: 'Agência',
            required: true,
            variant: 'outlined',
            small: true,
            flex: 5,
            onBlur: () => blurFormataDadosBancarios('AG'),
          },
          {
            // Column
            elem_id: 'contaCorrente',
            type: 'InputText',
            label: 'Conta corrente',
            required: true,
            variant: 'outlined',
            small: true,
            flex: 5,
            onBlur: () => blurFormataDadosBancarios('CC'),
          },
        ],
      ],
    },
  ],
});
