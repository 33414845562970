export const temp = {};

const listaCompetencia = (apiData) => {
  const array = [];
  if (apiData.length > 0) {
    const unique = [...new Set(apiData.map((item) => item.competencia))];

    unique.forEach((res) => {
      array.push({ key: res, name: res });
    });
  }
  return array;
};

export const renderForm = (apiData) => ({
  form: [
    {
      label: 'Nome do form',
      inputs: [
        [
          // Row
          {
            elem_id: 'vencimento',
            type: 'Select',
            label: 'Competência',
            value: listaCompetencia(apiData),
          },
        ],
      ],
    },
  ],
});
