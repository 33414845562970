import styled from 'styled-components';

export const ContainerForm = styled.div`
  width: 100%;
  padding: 5px;
`;

export const Container = styled.div`
  width: 100%;
  padding: 10px 0;
`;
