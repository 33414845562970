import { SendIcon, ArrowBackIosIcon, CheckIcon } from '@solussaude/core/Icons';

import Grid from '@solussaude/core/Grid';
import ActionButtons from '@solussaude/core/Helpers/ActionButtons';
import Form from '@solussaude/core/CustomForm';
import Button from '@solussaude/core/Inputs/Button';
import TableCRUD from '@solussaude/core/DataDisplay/TableCRUD';
import { renderFormSolicita, renderTable, dadosConfirmacao } from './settings';

const formValues = [];
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues[newValue.id] = newValue;
};
const clearFormValues = () => {
  while (formValues.length) {
    formValues.pop();
  }
};

export const ListaBeneficiarios = ({
  apiData,
  onSelectionChange,
  handleNext,
}) => (
  <>
    <TableCRUD settings={renderTable(apiData, onSelectionChange)} />
    <ActionButtons direction="flex-end">
      <Button
        text="Próximo passo"
        variant="contained"
        color="primary"
        endIcon={<SendIcon />}
        fullWidth={false}
        onClick={() => handleNext()}
      />
    </ActionButtons>
  </>
);

export const MotivosBeneficiario = ({
  handleBack,
  handleNext,
  beneficiarios,
  motivos,
}) => {
  const array = [];
  let i = 0;
  clearFormValues();
  beneficiarios.forEach((beneficiario) => {
    array.push(
      <Form
        settings={renderFormSolicita(beneficiario, motivos, i)}
        setValues={setFormValues}
        key={beneficiario.id}
      />,
    );
    i += 1;
  });
  return (
    <>
      <Grid container>
        <Grid item sm={12} md={12}>
          {array}
        </Grid>
        <Grid item sm={12} md={6}>
          <ActionButtons direction="flex-start">
            <Button
              text="Voltar"
              variant="outlined"
              color="secondary"
              startIcon={<ArrowBackIosIcon />}
              fullWidth={false}
              onClick={() => handleBack()}
            />
          </ActionButtons>
        </Grid>
        <Grid item sm={12} md={6}>
          <ActionButtons>
            <Button
              text="Próximo passo"
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              fullWidth={false}
              onClick={() => handleNext()}
            />
          </ActionButtons>
        </Grid>
      </Grid>
    </>
  );
};

export const Confirmacao = ({ handleBack, handleConfirm, motivos }) => (
  <>
    <Grid container>
      <Grid item sm={12} md={12}>
        <TableCRUD settings={dadosConfirmacao(getFormValues(), motivos)} />
      </Grid>
      <Grid item sm={12} md={6}>
        <ActionButtons direction="flex-start">
          <Button
            text="Voltar"
            variant="outlined"
            color="secondary"
            startIcon={<ArrowBackIosIcon />}
            fullWidth={false}
            onClick={() => handleBack()}
          />
        </ActionButtons>
      </Grid>
      <Grid item sm={12} md={6}>
        <ActionButtons>
          <Button
            text="Confirmar solicitação"
            variant="contained"
            color="primary"
            endIcon={<CheckIcon />}
            fullWidth={false}
            onClick={() => handleConfirm(getFormValues())}
          />
        </ActionButtons>
      </Grid>
    </Grid>
  </>
);
