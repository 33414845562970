import { processData } from '@solussaude/core/Utils';

import { CloseIcon, SendIcon } from '@solussaude/core/Icons';

export const temp = {};

export const renderForm = (
  cep,
  tipoLogradouro,
  logradouro,
  numero,
  complemento,
  bairro,
  cidade,
  estado,
  changeEstado,
  handleCloseModal,
  handleClick,
) => ({
  form: [
    {
      inputs: [
        [
          // Row
          {
            // Column
            elem_id: 'cep',
            elem_val: cep || '',
            type: 'InputMask',
            typeMask: 'cep',
            label: 'CEP',
            flex: 2,
            required: true,
          },
          // Row
          {
            // Column
            elem_id: 'logradouro',
            elem_val: logradouro || '',
            type: 'InputText',
            label: 'Logradouro',
            flex: 4,
            required: true,
          },
          {
            // Column
            elem_id: 'numero',
            elem_val: numero || '',
            type: 'InputText',
            label: 'Número',
            flex: 3,
            required: true,
          },
          {
            // Column
            elem_id: 'complemento',
            elem_val: complemento || '',
            type: 'InputText',
            label: 'Complemento',
            flex: 3,
          },
        ],
        [
          {
            // Column
            elem_id: 'estado',
            elem_val: estado.selecionado || '',
            type: 'Select',
            label: 'Estado',
            value: processData(estado.descricao, 'codigo', 'descricao'),
            onChange: (e) => changeEstado(e, estado.descricao, tipoLogradouro),
            flex: 2,
          },
          {
            // Column
            elem_id: 'cidade',
            elem_val: cidade.selecionado || '',
            type: 'Autocomplete',
            label: 'Cidade',
            options: processData(cidade.descricao, 'nome', 'nome'),
            labelOption: (saida) => saida.name || 'Selecione uma opção',
            fullWidth: true,
            flex: 4,
          },
          {
            elem_id: 'tipoLogradouro',
            elem_val: tipoLogradouro.selecionado || '',
            type: 'Autocomplete',
            label: 'Tipo logradouro',
            options: processData(tipoLogradouro.descricao, 'id', 'descricao'),
            labelOption: (saida) => saida.name || 'Selecione uma opção',
            fullWidth: true,
            flex: 3,
          },
          {
            // Column
            elem_id: 'bairro',
            elem_val: bairro || '',
            type: 'InputText',
            label: 'Bairro',
            flex: 3,
            required: true,
          },
        ],
        [
          {
            elem_id: 'btnClose',
            type: 'Button',
            text: 'Fechar',
            fullWidth: true,
            color: 'secondary',
            icon: <CloseIcon />,
            variant: 'outlined',
            onClick: () => handleCloseModal(),
          },
          {
            elem_id: 'btn',
            type: 'Button',
            text: 'Confirmar',
            fullWidth: true,
            endIcon: <SendIcon />,
            color: 'primary',
            variant: 'contained',
            onClick: () => handleClick(),
          },
        ],
      ],
    },
  ],
});
