import React from 'react';

import { AssignmentIcon, WarningIcon } from '@solussaude/core/Icons';
import Grid from '@solussaude/core/Grid';
import Page from '@solussaude/core/Surfaces/Page';
import TableCRUD from '@solussaude/core/DataDisplay/TableCRUD';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import SolusAPI from '../../../services/SolusApi';
import defaultTheme from '../../../styles/themes/default';
import { dadosPlanosProdutos } from './settings';

export default function PlanosProdutos() {
  const theme = defaultTheme;

  const [state, setState] = React.useState({
    loading: true,
    apiData: [],
    successApi: true,
  });

  React.useEffect(() => {
    const search = async () => {
      const dadosP = await SolusAPI.post('api/beneficiarios/planosProdutos', {
        situacao: 'A',
        web: 'S',
        ordem: 'C',
      });

      setState({
        loading: false,
        apiData: dadosP.data,
        successApi: dadosP.success,
      });
    };

    search();
  }, []);

  const renderBodyTablePlanosProdutos = () => {
    if (state.loading === false) {
      return (
        <TableCRUD
          settings={dadosPlanosProdutos(state.apiData)}
          inLoading={state.loading}
        />
      );
    }
    return null;
  };

  const renderDefaultErrorPlanosProdutos = (
    <>
      <BodyInfo
        icon={<WarningIcon color="error" />}
        title="Opss..."
        body="Não foi possivel carregar seus planos e produtos, tente novamente mais tarde."
        noDivider
      />
    </>
  );

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page title="Relação de planos e produtos" icon={AssignmentIcon}>
            <Grid item sm={12} md={12}>
              {state.successApi
                ? renderBodyTablePlanosProdutos()
                : renderDefaultErrorPlanosProdutos}
            </Grid>
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
