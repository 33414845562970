/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import WebContext from '@solussaude/core/WebContext';
import { CheckIcon, CloseIcon, WarningIcon } from '@solussaude/core/Icons';
import Grid from '@solussaude/core/Grid';
import Button from '@solussaude/core/Inputs/Button';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import { isPromise, isURL } from '@solussaude/core/Utils';
import SolusAPI from '../../../services/SolusApi';

import defaultTheme from '../../../styles/themes/default';
import { Container, ActionButtons } from './styles';

export default function DeclaracaoPermanencia() {
  const context = React.useContext(WebContext);
  const theme = defaultTheme;
  const [state, setState] = React.useState({
    inLoading: true,
    success: false,
  });

  const handleCloseModal = () => {
    context.showModal({
      open: false,
      renderBody: context.modal.renderBody,
    });
  };

  React.useEffect(() => {
    const getFromAPI = async () => {
      const userAuth = await isPromise(context.module.beneficiario.userAuth);

      const dadosFromAPI = await SolusAPI.post(
        'api/beneficiarios/declaracaoPermanencia/pdf',
        {
          api: 'S',
          listar: 'S',
          id_usuario: userAuth.id,
        },
      );

      const urlPHP = dadosFromAPI.data;

      const success = dadosFromAPI.success && isURL(urlPHP);

      if (success) window.open(urlPHP);

      setState((old) => ({
        ...old,
        inLoading: false,
        success,
      }));
    };
    getFromAPI();
  }, []);

  if (state.inLoading) {
    return <CircularIndeterminateModal />;
  }

  const renderError = () => (
    <BodyInfo
      icon={<WarningIcon color="error" />}
      title="Opss..."
      body="Não foi possivel emitir a declaração de permanência tente novamente mais tarde!"
    />
  );

  const renderSuccess = () => (
    <BodyInfo
      icon={<CheckIcon color="primary" />}
      title="Declaração de permanência"
      body="Seu documento foi gerado com sucesso!"
    />
  );

  return (
    <Container>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          {state.success ? renderSuccess() : renderError()}
        </Grid>
        <Grid item sm={12} md={6}>
          <ActionButtons direction="flex-start">
            <Button
              text="Fechar"
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
              fullWidth={false}
              onClick={() => handleCloseModal()}
            />
          </ActionButtons>
        </Grid>
        <Grid item sm={12} md={6} />
      </Grid>
    </Container>
  );
}
