import React from 'react';
import { AttachFileIcon, AddIcon } from '@solussaude/core/Icons';
import Tooltip from '@solussaude/core/DataDisplay/Tooltip';

export const formBotaoAnexo = (onClickSalva) => ({
  form: [
    {
      inputs: [
        [
          // Row
          {
            elem_id: 'btnSalva',
            type: 'IconButton',
            children: (
              <Tooltip icon={<AddIcon color="primary" />} title="Adicionar" />
            ),
            onClick: () => onClickSalva(),
            flex: 1,
          },
        ],
      ],
    },
  ],
});

export const formTipoAnexo = (handleCloseModal, insereAnexo) => ({
  form: [
    {
      inputs: [
        [
          // Row
          {
            // Column
            elem_id: 'tipoAnexo',
            type: 'InputText',
            label: 'Tipo do anexo',
            variant: 'outlined',
            placeholder: 'Descreva o tipo de anexo',
            required: true,
            small: true,
            flex: 10,
          },
        ],
        [
          {
            elem_id: 'btnClose',
            type: 'Button',
            text: 'Fechar',
            fullWidth: true,
            color: 'primary',
            variant: 'outlined',
            onClick: () => handleCloseModal(),
          },
          {
            elem_id: 'btn',
            type: 'Button',
            text: 'Confirmar',
            fullWidth: true,
            color: 'primary',
            variant: 'contained',
            onClick: () => insereAnexo(),
          },
        ],
      ],
    },
  ],
});

export const formListaAnexo = (state, tipoAnexo, idx, ref) => ({
  form: [
    {
      inputs: [
        {
          elem_id: 'anexo',
          elem_val: state.anexo,
          type: 'Button',
          flex: 1,
          icon: <AttachFileIcon />,
          color: 'primary',
          variant: 'contained',
          text: tipoAnexo.tipoAnexo,
          fullWidth: true,
          typeButton: 'file',
          ref,
        },
      ],
    },
  ],
});
