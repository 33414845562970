const tipoReembolso = [
  { key: 0, name: 'Instrumentação Cirúrgica' },
  { key: 1, name: 'Deslocamento' },
  { key: 2, name: 'Outros' },
];

export const formTipoReembolso = () => ({
  form: [
    {
      inputs: [
        [
          {
            // Column
            elem_id: 'tipo',
            type: 'Select', 
            label: 'Tipo reembolso',
            required: true,
            value: tipoReembolso,
          },
        ],
      ],
    },
  ],
});

export const formTermos = (check, dis, handleTermos, handleCloseModal) => ({
  form: [
    {
      inputs: [
        [
          {
            elem_id: 'termos',
            type: 'Checkbox',
            label: 'Declaro que li e concordo com os "Termos e Condições"',
            onChange: (c) => check(c),
            required: true,
          },
        ],
        [
          {
            elem_id: 'btnClose',
            type: 'Button',
            text: 'Fechar',       
            fullWidth: true,
            color: 'primary',
            variant: 'outlined',
            onClick: () => handleCloseModal(),
          },
          {
            elem_id: 'btn',
            type: 'Button',
            text: 'Avançar',
            disabled: dis,
            fullWidth: true,
            color: 'primary',
            variant: 'contained',
            onClick: () => handleTermos(),
          },
        ],
      ],
    },
  ],
});
