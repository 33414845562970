export const temp = {};

const processDataPlanosProdutos = (apiData) => {
  const array = [];

  if (apiData.length > 0) {
    apiData.forEach((planosP) => {
      array.push({
        codigo: planosP.codigo,
        descricao: planosP.nome,
        abrangencia: planosP.abrangencia,
        registro: planosP.registro_ans,
        cobertura: planosP.cobertura,
      });
    });
  }
  return array;
};

export const dadosPlanosProdutos = (apiData) => {
  if (apiData.length > 0) {
    return {
      table: {
        options: {
          editableTable: false,
          pagination: true,
          search: true,
          pageSize: 10,
        },
        columns: [
          {
            title: 'Código',
            field: 'codigo',
          },
          {
            title: 'Descrição',
            field: 'descricao',
          },
          {
            title: 'Abrangência',
            field: 'abrangencia',
          },
          {
            title: 'Registro',
            field: 'registro',
          },
          {
            title: 'Cobertura',
            field: 'cobertura',
          },
        ],
        data: processDataPlanosProdutos(apiData),
      },
    };
  }
  return null;
};
