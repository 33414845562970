/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-brace-presence */

import React from 'react';
import Form from '@solussaude/core/CustomForm';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import TableModal from '@solussaude/core/Helpers/TableModal';
import ActionButton from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import WebContext from '@solussaude/core/WebContext';
import {
  SearchIcon,
  LocalHospitalIcon,
  CloseIcon,
} from '@solussaude/core/Icons';
import SolusAPI from '../../../../services/SolusApi';
import { formDadosMedico, formPrestador, tablePrestadores } from './settings';
import { Container } from './styles';

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

let formPrestadorValues;
const getPrestadorFormValues = () => formPrestadorValues;
const setPrestadorFormValues = (newValue) => {
  formPrestadorValues = newValue;
};

let mappingForm;
const getMappingDadosMedico = () => mappingForm;
const setMappingDadosMedico = (pGetMapping) => {
  mappingForm = pGetMapping;
};

export default function DadosMedico({ setMapping, getMapping }) {
  setMapping({
    ...getMapping(),
    getMappingDadosMedico,
    getFormDadosMedico: getFormValues,
  });

  const webContext = React.useContext(WebContext);

  const [state, setState] = React.useState({
    loading: true,
    estado: { selecionado: '', descricao: [] },
  });

  React.useEffect(() => {
    const init = async () => {
      const dadosEstado = await SolusAPI.get(
        'v1/dominio/estado',
        {},
        { appName: 'api_comercial' },
      );

      if (dadosEstado.status === 200) {
        setState({
          loading: false,
          estado: { selecionado: '', descricao: dadosEstado.data },
        });
      }
    };
    init();
  }, []);

  const ListagemPrestador = () => {
    const [listagem, setListagem] = React.useState({
      prestador: { selecionado: '', descricao: [] },
      nomeMedico: '',
      ufCRM: '',
      crm: '',
      qtdRegistro: 10,
      qtdRequisicao: 0,
      tipoBusca: 'nome',
      parametro: '',
      render: (
        <>
          <TableModal tableSettings={tablePrestadores([])} />
        </>
      ),
      loading: true,
    });

    React.useEffect(() => {
      const init = async () => {
        const dadosPrestadores = await SolusAPI.get(
          'v1/prestador',
          {
            tipobusca: listagem.tipoBusca,
            parametro: listagem.parametro,
            quantidaderegistro: listagem.qtdRegistro,
          },
          { appName: 'api_prestador' },
        );
        setListagem({
          ...listagem,
          prestador: { selecionado: '', descricao: dadosPrestadores.data },
          loading: false,
          render: (
            <>
              <TableModal
                tableSettings={tablePrestadores(dadosPrestadores.data)}
                refGetMapping={getMapping}
                idElemChange={'nomeMedico'}
                element="nome"
                handleClick={(row) => handleClickPrestador(row)}
              />
            </>
          ),
        });
      };
      init();
    }, []);

    React.useEffect(
      () =>
        setListagem({
          ...listagem,
          loading: false,
          qtdRegistro: 10,
          qtdRequisicao: 0,
        }),
      [listagem.tipoBusca],
    );

    const handleClickPrestador = (row) => {
      const formMap = getMapping().getMappingDadosMedico()();

      formMap.crm.setValues(row.crm);
      formMap.nomeMedico.setValues(row.nome);
      formMap.ufCRM.setValues(row.estadoConselho);

      webContext.showModal({
        open: false,
      });
    };

    const changePesquisa = () => {
      const { tipoBusca, parametro } = getPrestadorFormValues();

      setListagem({ ...listagem, tipoBusca, parametro });
    };

    const handlePrestador = async () => {
      const { tipoBusca, parametro } = getPrestadorFormValues();

      let dadosPrestadores = {};

      if (tipoBusca.includes('nome')) {
        dadosPrestadores = await SolusAPI.get(
          'v1/prestador',
          {
            nomeContendo: parametro,
          },
          { appName: 'api_prestador' },
        );
      } else {
        dadosPrestadores = await SolusAPI.get(
          'v1/prestador',
          {
            tipobusca: tipoBusca,
            parametro,
          },
          { appName: 'api_prestador' },
        );
      }

      setListagem({
        ...listagem,
        tipoBusca,
        qtdRequisicao: listagem.qtdRequisicao + 1,
        qtdRegistro: listagem.qtdRegistro + 10,
        prestador: { selecionado: '', descricao: dadosPrestadores.data },
        render: (
          <>
            <TableModal
              tableSettings={tablePrestadores(dadosPrestadores.data)}
              refGetMapping={getMapping}
              idElemChange={'nomeMedico'}
              element="nome"
              handleClick={(row) => handleClickPrestador(row)}
            />
          </>
        ),
      });
    };

    if (listagem.loading) return <CircularIndeterminateModal />;

    return (
      <>
        <Container>
          <BodyInfo icon={<SearchIcon />} title="Pesquise pelo prestador" />
          <br />
          <Form
            settings={formPrestador(listagem, changePesquisa)}
            setValues={setPrestadorFormValues}
          />
          <br />
          <ActionButton direction="space-between">
            <Button
              variant="outlined"
              color="secondary"
              text="Fechar"
              startIcon={<CloseIcon />}
              onClick={() => handleClose()}
            />
            <Button
              variant="contained"
              color="primary"
              text={
                listagem.qtdRequisicao === 0 ? 'Procurar' : 'Mais registros'
              }
              startIcon={<SearchIcon />}
              onClick={() => handlePrestador()}
            />
          </ActionButton>
          <br />
          {listagem.render}
        </Container>
      </>
    );
  };

  const onClick = () => {
    webContext.showModal({
      open: true,
      renderBody: <ListagemPrestador />,
    });
  };

  const handleClose = () => {
    webContext.showModal({
      open: false,
    });
  };

  return (
    <>
      <BodyInfo
        title="Dados do médico/profissional"
        icon={<LocalHospitalIcon />}
        noDivider
      />
      <Form
        settings={formDadosMedico(state, onClick)}
        setValues={setFormValues}
        setMappingForm={setMappingDadosMedico}
      />
    </>
  );
}
