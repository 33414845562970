/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  InsertDriveFileIcon,
  QueryBuilderIcon,
  AttachMoneyIcon,
  InfoIcon,
  SettingsIcon,
  WarningIcon,
  FilterListIcon,
} from '@solussaude/core/Icons';

import { dateFormat, formatMoney } from '@solussaude/core/Utils';
import Button from '@solussaude/core/Inputs/Button';
import WebContext from '@solussaude/core/WebContext';
import MensConfirmacao from '@solussaude/core/Helpers/MensConfirmacao';

import { BodyStatus, ContainerButton } from './styles';
import Detail from './Detail';

const handleCloseModal = (context) => {
  context.showModal({
    open: false,
  });
};

export const errorDefaultPage = (webContext) => (
  <>
    <MensConfirmacao
      title="Opss"
      IconTitle={<WarningIcon color="primary" />}
      description={
        <p>
          Não foi possível carregar os dados do beneficiário, tente novamente
          mais tarde!
        </p>
      }
      handleClick={() => handleCloseModal(webContext)}
    />
  </>
);

const renderStatus = (pago, situChpre) => {
  let color = '';

  if (pago) color = '#0cbd00';
  else if (situChpre === 'P') color = '#0c32bb';
  else color = '#dcc81b';

  let situacao = '';
  if (situChpre === 'P') {
    situacao = 'Processando';
  } else if (pago) {
    situacao = 'Pago';
  } else {
    situacao = 'Em aberto';
  }

  return (
    <BodyStatus color={color}>
      <b>
        <span>{situacao}</span>
      </b>
    </BodyStatus>
  );
};

const ButtonRow = ({ pago, referencia, dadosPaga }) => {
  const modal = React.useContext(WebContext);

  const processResponse = () => {
    modal.showModal({
      open: true,
      renderBody: (
        <Detail
          dadosPaga={dadosPaga}
          modal={modal}
          pago={pago}
          referencia={referencia}
        />
      ),
    });
  };

  return (
    <Button
      color="primary"
      variant="contained"
      text="Visualizar Boleto"
      onClick={() => processResponse()}
    >
      Ver mais
    </Button>
  );
};

const processData = (apiData) => {
  const array = [];

  if (apiData.length > 0) {
    apiData.forEach((dadosPaga) => {
      array.push({
        documento: dadosPaga.numero_documento,
        vencimento: dateFormat(dadosPaga.vencimento),
        valor: `R$ ${formatMoney(dadosPaga.valor)}`,
        pago: dadosPaga.situacao === 'S',
        situacao: renderStatus(
          dadosPaga.situacao === 'S',
          dadosPaga.situacao_chpre,
        ),
        detalhes: (
          <ContainerButton>
            <ButtonRow
              dadosPaga={dadosPaga}
              pago={dadosPaga.situacao === 'S'}
              referencia="mensalidade"
            />
          </ContainerButton>
        ),
      });
    });
  }

  return array;
};

export const temp = {};
export const renderTable = (apiData) => ({
  table: {
    options: {
      convertTo: true,
      iconTitle: <InsertDriveFileIcon color="primary" />,
      titleTable: 'Lista de documentos',
      description:
        'Há um espaço de tempo entre o pagamento efetuado na rede bancária e o registro dessa informação em nosso sistema. Pode acontecer de mensalidades que já foram quitadas ainda constarem na relação. Neste caso desconsidere se a informação constar como boleto em aberto.',
      editableTable: false,
    },
    columns: [
      {
        title: 'Documento',
        field: 'documento',
        icon: <InsertDriveFileIcon color="primary" />,
      },
      {
        title: 'Vencimento',
        field: 'vencimento',
        icon: <QueryBuilderIcon color="primary" />,
      },
      // {
      //   title: 'Referência',
      //   field: 'referencia',
      //   icon: <SearchIcon color="primary" />
      // },
      {
        title: 'Valor',
        field: 'valor',
        icon: <AttachMoneyIcon color="primary" />,
      },
      {
        title: 'Situação',
        field: 'situacao',
        icon: <InfoIcon color="primary" />,
      },
      {
        title: 'Boleto',
        field: 'detalhes',
        icon: <SettingsIcon color="primary" />,
      },
    ],
    data: processData(apiData || []),
  },
});

export const renderDrawer = {
  drawer: {
    icon: FilterListIcon,
    style: {
      maxWidth: '100vw',
      width: '450px',
      padding: '25px',
      boxSizing: 'border-box',
    },
  },
};

export const renderFilter = ({ onClickListar }) => ({
  header: {
    description: 'Utilize os filtros para localizar documentos antigos',
  },
  form: [
    {
      label: 'boletos',
      inputs: [
        [
          {
            // Column
            elem_id: 'situacao',
            type: 'Select',
            label: 'Situação',
            required: false,
            value: [
              { key: 'T', name: 'Todos as situações' },
              { key: 'A', name: 'Em aberto' },
            ],
            htmlFor: 'Situação',
          },
        ],
        [
          {
            elem_id: 'ano',
            type: 'Select',
            label: 'Ano',
            value: [
              { key: 0, name: 'Todo período' },
              { key: 2021, name: '2021' },
              { key: 2020, name: '2020' },
              { key: 2019, name: '2019' },
              { key: 2018, name: '2018' },
              { key: 2017, name: '2017' },
              { key: 2016, name: '2016' },
            ],
            required: false,
          },
        ],
        [
          {
            elem_id: 'btnSubmit',
            type: 'Button',
            text: 'Localizar documentos',
            fullWidth: true,
            color: 'primary',
            variant: 'contained',
            onClick: () => onClickListar(),
          },
        ],
      ],
    },
  ],
});
