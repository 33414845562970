import styled from 'styled-components';

export const ContainerWarning = styled.div`
  width: 450px;
`;

export const Terms = styled.div`
  background: #f7f7f7;
`;

export const TitleTerms = styled.div`
  padding-top: 15px;
  color: ${props => props.theme.palette.primary.main} !important;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
`;

export const BodyTerms = styled.div`
  font-size: 13px;
  padding: 15px;
`;
