/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */

import React from 'react';

import {
  SendIcon,
  ArrowBackIosIcon,
  CheckIcon,
  CheckCircleIcon,
  WarningIcon,
} from '@solussaude/core/Icons';

import Grid from '@solussaude/core/Grid';
import Typography from '@solussaude/core/Typography';
import Button from '@solussaude/core/Inputs/Button';
import Form from '@solussaude/core/CustomForm';
import FormEndereco from '@solussaude/core/Utils/FormEndereco';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import Checkbox from '@solussaude/core/Inputs/Checkbox';
import MensConfirmacao from '@solussaude/core/Helpers/MensConfirmacao';
import Table from '@solussaude/core/DataDisplay/TableSimple';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import WebContext from '@solussaude/core/WebContext';
import { isPromise } from '@solussaude/core/Utils';
import SolusAPI from '../../../services/SolusApi';
import defaultTheme from '../../../styles/themes/default';

import {
  cancelamento,
  renderDados,
  renderCancelamento,
  dadosConfirmacao,
} from './settings';
import {
  ActionButtons,
  ContentBody,
  BodyDescription,
  IconAlign,
  Container,
  ContentLoading,
} from './styles';

function genKey() {
  return Math.random().toString(16).slice(-4);
}

let mapping;
const getMapping = () => mapping;
const setMapping = (newMapping) => {
  mapping = newMapping;
};

let mappingForm;
const getMappingForm = () => mappingForm;
const setMappingForm = (pGetMapping) => {
  mappingForm = pGetMapping;
};

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

let formEndereco;
const getFormEndereco = () => formEndereco;
const setFormEndereco = (newValue) => {
  formEndereco = newValue;
};

let checkValues;
const getCheckValues = () => checkValues;
const setCheckValues = (newValue) => {
  checkValues = newValue;
};

const handleCloseModal = (context, redirect = '') => {
  context.showModal({
    open: false,
  });
  if (redirect !== '') window.location.href = redirect;
};

const theme = defaultTheme;

const RenderStepError = ({ params, webContext, props }) => (
  <MensConfirmacao
    title="Opss"
    IconTitle={<WarningIcon color="error" />}
    description={
      <p>{typeof params === 'object' ? params.retMessage : params}</p>
    }
    handleClick={() => handleCloseModal(webContext, '/beneficiario/dashboard')}
  />
);
const RenderStepAlert = ({ title, description, titleIcon }) => {
  const webContext = React.useContext(WebContext);

  return (
    <>
      <Grid item sm={12} md={12}>
        <Typography variant="h6">
          <IconAlign>
            {titleIcon}
            <span>{title}</span>
          </IconAlign>
        </Typography>

        <BodyDescription>
          <p>{description}</p>
        </BodyDescription>
      </Grid>
      <Grid item sm={12} md={12}>
        <ActionButtons>
          <Button
            text="OK"
            variant="contained"
            color="primary"
            fullWidth={false}
            size="large"
            onClick={() => handleCloseModal(webContext)}
          />
        </ActionButtons>
      </Grid>
    </>
  );
};

export const RenderStep1 = ({ handleNext, props }) => {
  const context = React.useContext(WebContext);
  const [step1, setStep1] = React.useState({
    loading: true,
    apiData: [],
  });

  React.useEffect(() => {
    const init = async () => {
      const dados = await SolusAPI.get(
        'api/beneficiarios/pre-valida-cancelamento',
      );

      setStep1({
        loading: false,
        apiData: dados.data,
        successApi: dados.success,
      });
    };
    init();
  }, []);

  if (step1.apiData.tipo === 'E') {
    context.showModal({
      open: true,
      renderBody: (
        <RenderStepError
          params={step1.apiData}
          webContext={context}
          props={props}
        />
      ),
      disableEscapeKeyDown: true,
      disableBackdropClick: true,
    });
  } else if (step1.apiData.tipo === 'A') {
    context.showModal({
      open: true,
      renderBody: (
        <RenderStepAlert
          title="Alerta"
          description={step1.apiData.retMessage}
          titleIcon={<WarningIcon color="error" />}
        />
      ),
    });
  } else {
    context.showModal({
      open: false,
    });
  }

  const handleCheck = () => {
    let vContinue = true;

    Object.values(getCheckValues()).forEach((data) => {
      if (!data) vContinue = false;
    });

    if (vContinue) handleNext();
    else {
      context.showModal({
        open: true,
        renderBody: (
          <RenderStepAlert
            title="Alerta"
            description="Para prosseguir é necessário aceitar todos os termos de cancelamento."
            titleIcon={<WarningIcon color="error" />}
          />
        ),
      });
    }
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12} md={12}>
          <Container>
            {cancelamento.stepContent.map((content, i) => (
              <React.Fragment key={genKey()}>
                <Container>
                  {content.title ? (
                    <Typography variant="h6">{content.title}</Typography>
                  ) : (
                    <></>
                  )}
                </Container>
                <Container>
                  <div variant="body1">{content.text}</div>
                </Container>
                <Checkbox
                  data={{
                    label: content.checkbox,
                    elem_id: i,
                  }}
                  refGetValues={getCheckValues}
                  refSetValues={setCheckValues}
                />
              </React.Fragment>
            ))}
          </Container>
        </Grid>
        <Grid item sm={12} md={12}>
          <ActionButtons>
            <Button
              text="Próximo passo"
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              fullWidth={false}
              onClick={() => handleCheck()}
            />
          </ActionButtons>
        </Grid>
      </Grid>
    </>
  );
};

export const RenderStep2 = ({ handleNext, handleBack }) => {
  const [values, setValues] = React.useState({
    loading: true,
    telefone: '',
    email: '',
  });

  setMapping({
    ...getMapping(),
    getMappingForm,
    getFormValues,
  });

  React.useEffect(() => {
    const init = async () => {
      const dadosT = await SolusAPI.post('api/beneficiarios/lista-telefones', {
        tipo: 'P',
      });
      const dadosEm = await SolusAPI.post('api/beneficiarios/lista-email', {
        tipo: 'P',
      });

      setValues({
        ...values,
        loading: false,
        telefone: dadosT.data?.length > 0 ? dadosT.data[0].telefone : '',
        email: dadosEm.data?.length > 0 ? dadosEm.data[0].email : '',
      });
    };
    init();
  }, []);

  const context = React.useContext(WebContext);

  const handleCheck = async () => {
    const { email } = getFormValues();
    const userAuth = await isPromise(context.module.beneficiario.userAuth);

    let continua = true;

    Object.values(getMapping().getMappingForm()()).forEach((key) => {
      if (key.values === '' || key.values === '(  )     -    ') {
        key.setError({ is: true, helperText: 'Preenchimento obrigatório' });
        continua = false;
      } else {
        key.setError({ is: false, helperText: '' });
      }
    });

    if (continua) {
      setValues((oldValues) => ({
        ...oldValues,
        loading: true,
      }));

      const dadosE = await SolusAPI.post('api/beneficiarios/atualiza-email', {
        email,
        id: userAuth.id,
        aceite: 'I',
      });

      setValues((oldValues) => ({
        ...oldValues,
        loading: false,
      }));

      if (dadosE.data && dadosE.data.retMessage === null) {
        handleNext({ contato: getFormValues(), endereco: getFormEndereco() });
      } else {
        context.showModal({
          open: true,
          renderBody: (
            <RenderStepAlert
              title="Ops..."
              description="Ocorreu um erro, tente novamente mais tarde."
              titleIcon={<WarningIcon color="error" />}
            />
          ),
        });
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12} md={12}>
          {values.loading === false ? (
            <>
              <Form
                settings={renderDados(values)}
                setValues={setFormValues}
                setMappingForm={setMappingForm}
              />
              <FormEndereco
                theme={theme}
                setValues={setFormEndereco}
                principal
              />
            </>
          ) : (
            <ContentLoading>
              <CircularIndeterminateModal />
            </ContentLoading>
          )}
        </Grid>
        <Grid item sm={12} md={6}>
          <ActionButtons direction="flex-start">
            <Button
              text="Voltar"
              variant="outlined"
              color="secondary"
              startIcon={<ArrowBackIosIcon />}
              fullWidth={false}
              onClick={() => handleBack()}
            />
          </ActionButtons>
        </Grid>
        <Grid item sm={12} md={6}>
          <ActionButtons>
            <Button
              text="Próximo passo"
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              fullWidth={false}
              onClick={() => handleCheck()}
            />
          </ActionButtons>
        </Grid>
      </Grid>
    </>
  );
};

export const RenderStep3 = ({ handleNext, handleBack, params }) => {
  const [values, setValues] = React.useState({
    tipo: { selecionado: 0, descricao: [] },
    motivo: { selecionado: 0, descricao: [] },
    operadora: { selecionado: 0, descricao: [] },
    dependentes: { selecionado: [], descricao: [] },
  });

  const context = React.useContext(WebContext);

  React.useEffect(() => {
    const init = async () => {
      const dadosTi = await SolusAPI.get(
        'api/beneficiarios/lista-tipo-cancelamento',
      );
      const dadosM = await SolusAPI.get(
        'api/beneficiarios/lista-motivo-cancelamento',
      );

      setValues({
        ...values,
        loading: false,
        motivo: {
          selecionado: dadosM.data?.length > 0 ? dadosM.data[0].id_motivo : 0,
          descricao: dadosM.data,
        },
        tipo: {
          selecionado: dadosTi.data?.length > 0 ? dadosTi.data[0].tipo_flag : 0,
          descricao: dadosTi.data,
        },
      });
    };
    init();
  }, []);

  const changeTipo = async (apiData) => {
    const { tipo } = getFormValues();

    const operadora = getFormValues().operadora
      ? getFormValues().operadora.key
      : 0;

    if (tipo !== 'C') {
      const dados = await SolusAPI.get('api/beneficiarios/lista-familia');

      setValues((oldValues) => ({
        ...values,
        operadora: {
          selecionado: operadora,
          descricao: oldValues.operadora.descricao,
        },
        tipo: { selecionado: tipo, descricao: apiData.descricao },
        dependentes: { ...values.dependentes, descricao: dados.data },
      }));
    } else {
      setValues((oldValues) => ({
        ...values,
        operadora: {
          selecionado: operadora,
          descricao: oldValues.operadora.descricao,
        },
        tipo: { selecionado: tipo, descricao: apiData.descricao },
        dependentes: { ...values.dependentes, descricao: [] },
      }));
    }
  };

  const changeMotivo = async (e, motivo) => {
    const aux = motivo.descricao.filter((res) => res.id_motivo === e.value);

    const { telefone, email } = getFormValues();

    if (aux[0].motivo_troca_operadora === 'S') {
      const dadosO = await SolusAPI.get('api/beneficiarios/lista-operadoras');
      setValues({
        ...values,
        telefone,
        email,
        motivo: {
          selecionado: e.value,
          descricao: motivo.descricao,
        },
        operadora: { selecionado: 0, descricao: dadosO.data },
      });
    } else {
      setValues({
        ...values,
        telefone,
        email,
        motivo: {
          selecionado: e.value,
          descricao: motivo.descricao,
        },
        operadora: { selecionado: 0, descricao: [] },
      });
    }
  };

  const changeDependente = async () => {
    const { dependentes } = getFormValues();

    const arrayIdsDependentes = [];

    dependentes.forEach((dep) => {
      // percorre o array com os selecionados
      const findDependente = values.dependentes.descricao.find(
        (deps) => deps.nome === dep || deps.id === dep,
      );

      if (findDependente && arrayIdsDependentes.includes(findDependente.id))
        arrayIdsDependentes.splice(
          arrayIdsDependentes.indexOf(findDependente.id),
          1,
        );
      else arrayIdsDependentes.push(findDependente.id);
    });

    setValues({
      ...values,
      dependentes: {
        ...values.dependentes,
        selecionado: arrayIdsDependentes,
      },
    });
  };

  const handleCheck = () => {
    const { dependentes, tipo } = getFormValues();

    if (tipo !== 'C' && dependentes.length === 0) {
      return context.showModal({
        open: true,
        renderBody: (
          <RenderStepAlert
            title="Alerta"
            description="É necessário escolher ao menos um dependente!"
            titleIcon={<WarningIcon color="error" />}
          />
        ),
      });
    }

    handleNext({
      cancelamento: getFormValues(),
      contato: params[params.active].contato,
      endereco: params[params.active].endereco,
    });

    return null;
  };

  const renderError = () => (
    <BodyInfo
      icon={<WarningIcon color="error" />}
      title="Opss..."
      body="Não foi possivel, tente novamente mais tarde!"
    />
  );

  const formCancelamento = () => {
    if (values.loading === false) {
      if (values.motivo.descricao.length !== 0) {
        return (
          <>
            <Form
              settings={renderCancelamento(
                changeTipo,
                changeMotivo,
                changeDependente,
                values,
              )}
              setValues={setFormValues}
            />
          </>
        );
      }
      return renderError();
    }
    return (
      <ContentLoading>
        {' '}
        <CircularIndeterminateModal />{' '}
      </ContentLoading>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12} md={12}>
          {formCancelamento()}
        </Grid>
        <Grid item sm={12} md={6}>
          <ActionButtons direction="flex-start">
            <Button
              text="Voltar"
              variant="outlined"
              color="secondary"
              startIcon={<ArrowBackIosIcon />}
              fullWidth={false}
              onClick={() => handleBack()}
            />
          </ActionButtons>
        </Grid>
        <Grid item sm={12} md={6}>
          <ActionButtons>
            <Button
              text="Próximo passo"
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              fullWidth={false}
              onClick={() => handleCheck()}
            />
          </ActionButtons>
        </Grid>
      </Grid>
    </>
  );
};

export const RenderStep4 = ({ handleBack, params, props }) => {
  const webContext = React.useContext(WebContext);

  const [step4, setStep4] = React.useState({
    loading: true,
    plano: [],
    beneficiario: [],
    motivo: params[params.active],
    numeroContrato: '',
  });

  React.useEffect(() => {
    const init = async () => {
      const userAuth = await isPromise(webContext.module.beneficiario.userAuth);

      const dados = await SolusAPI.post('api/beneficiarios/planos', {
        id: userAuth.id_plano,
      });

      const contratoFromApi = await SolusAPI.get(
        `api/beneficiarios/dados-contrato/${userAuth.id_contrato}`,
      );

      setStep4({
        ...step4,
        loading: false,
        plano: dados.data?.length > 0 ? dados.data[0] : [],
        beneficiario: userAuth,
        numeroContrato: contratoFromApi.data[0].codigo,
      });
    };
    init();
  }, []);

  const handleClick = (context) => {
    setStep4({ ...step4, loading: true });
    context.showModal({
      open: true,
      renderBody: (
        <RenderStep5 params={step4} setStep={setStep4} props={props} />
      ),
      disableEscapeKeyDown: true,
      disableBackdropClick: true,
    });
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12} md={12}>
          <Typography variant="h6">Confirmação dos dados</Typography>
          <br />
          <Table settings={dadosConfirmacao(step4)} />
        </Grid>
        <Grid item sm={12} md={6}>
          <ActionButtons direction="flex-start">
            <Button
              text="Voltar"
              variant="outlined"
              color="secondary"
              startIcon={<ArrowBackIosIcon />}
              fullWidth={false}
              onClick={() => handleBack({ ...params[params.active] })}
            />
          </ActionButtons>
        </Grid>
        <Grid item sm={12} md={6}>
          <ActionButtons>
            <Button
              text="Confirmar cancelamento"
              variant="contained"
              color="primary"
              endIcon={<CheckIcon />}
              fullWidth={false}
              onClick={() => handleClick(webContext)}
            />
          </ActionButtons>
        </Grid>
      </Grid>
    </>
  );
};

export const RenderStep5 = ({ params, setStep, props }) => {
  const { beneficiario, motivo } = params;
  const webContext = React.useContext(WebContext);
  const [state, setState] = React.useState({
    loading: true,
    status: 0,
  });

  const { telefone, email } = motivo.contato;
  const {
    cep,
    tipoLogradouro,
    logradouro,
    numero,
    complemento,
    bairro,
    estado,
    cidade,
  } = motivo.endereco;

  const endereco = `CEP: ${cep} Tipo Logradouro: ${tipoLogradouro.name}  Logradouro: ${logradouro} Número: ${numero} Complemento: ${complemento} Bairro: ${bairro} Estado: ${estado} Cidade: ${cidade.name}`;

  React.useEffect(() => {
    const init = async () => {
      const dados = await SolusAPI.post(
        'api/beneficiarios/cancelamento-contrato',
        {
          id: beneficiario.id,
          cpf: beneficiario.cpf,
          novoTitular:
            motivo.cancelamento.tipo === 'E'
              ? motivo.cancelamento.dependentes[0]
              : 0,
          usuarios:
            motivo.cancelamento.tipo !== 'C'
              ? motivo.cancelamento.dependentes.toString()
              : 0,
          telefone,
          email,
          tipo: motivo.cancelamento.tipo,
          motivo: motivo.cancelamento.motivo,
          operadora: motivo.cancelamento.operadora
            ? motivo.cancelamento.operadora.key
            : 0,
          endereco,
          motivoDependentes: motivo.cancelamento.motivo,
        },
      );
      setState({
        loading: false,
        apiData: dados.data,
        status: dados.status,
      });
      setStep((old) => ({ ...old, loading: false }));
    };

    init();
  }, []);

  if (state.loading)
    return (
      <ContentLoading>
        {' '}
        <CircularIndeterminateModal />{' '}
      </ContentLoading>
    );

  if (state.status === 200) {
    return (
      <>
        <ContentBody>
          <Grid container>
            <Grid item sm={12} md={12}>
              <Typography variant="h6">
                <IconAlign>
                  <CheckCircleIcon color="primary" />
                  <span>Solicitação de cancelamento concluída!</span>
                </IconAlign>
              </Typography>

              <BodyDescription>
                <p>
                  Seu cancelamento foi processado e autorizado, o comprovante
                  desse cancelamento foi enviado para o e-mail
                  <b>{motivo.contato.email}</b>
                </p>
              </BodyDescription>
            </Grid>
            <Grid item sm={12} md={12}>
              <ActionButtons>
                <Button
                  text="Concluir"
                  variant="contained"
                  color="primary"
                  fullWidth={false}
                  onClick={() => handleCloseModal(webContext, '/beneficiario/')}
                />
              </ActionButtons>
            </Grid>
          </Grid>
        </ContentBody>
      </>
    );
  }

  return (
    <MensConfirmacao
      title="Opss"
      IconTitle={<WarningIcon color="error" />}
      description={<p>Ocorreu um erro, tente novamente mais tarde!</p>}
      handleClick={() => handleCloseModal(webContext)}
    />
  );
};
