import React from 'react';
import Form from '@solussaude/core/CustomForm';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import { AssignmentIndIcon } from '@solussaude/core/Icons';
import { formAutorizadoPagamento } from './settings';

let formValuesAutorizadoPagamento;
const getMappingAutorizadoPagamento = () => formValuesAutorizadoPagamento;
const setMappingAutorizadoPagamento = (newValue) => {
  formValuesAutorizadoPagamento = newValue;
};

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

export default function AutorizadoPagamento({ setMapping, getMapping }) {
  setMapping({
    ...getMapping(),
    getFormAutorizadoPagamento: getFormValues,
    getMappingAutorizadoPagamento,
  });

  const [state, setState] = React.useState({
    nome: '',
    tipoPessoa: 'PF',
    cpf: '',
    cnpj: '',
  });

  const handleTipoPessoa = (e) => {
    const { cpf, cnpj, titular } = getFormValues();
    setState({ ...state, tipoPessoa: e.value, cpf, cnpj, nome: titular });
  };

  return (
    <>
      <BodyInfo
        title="Autorizado pagamento"
        icon={<AssignmentIndIcon />}
        noDivider
      />
      <Form
        settings={formAutorizadoPagamento(state, handleTipoPessoa)}
        setValues={setFormValues}
        setMappingForm={setMappingAutorizadoPagamento}
      />
    </>
  );
}
