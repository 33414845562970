/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import {
  PrintIcon,
  ReceiptIcon,
  PictureAsPdfIcon,
  FileCopyIcon,
} from '@solussaude/core/Icons';

import { dateFormat, isURL, isPromise } from '@solussaude/core/Utils';
import Grid from '@solussaude/core/Grid';
import Typography from '@solussaude/core/Typography';
import Button from '@solussaude/core/Inputs/Button';
import WebContext from '@solussaude/core/WebContext';
import ButtonInfo from '@solussaude/core/Inputs/ButtonInfo';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import Icon from '@solussaude/core/Helpers/Icon';
import Snackbars from '@solussaude/core/Feedback/Snackbars';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import SolusAPI from '../../../services/SolusApi';

import defaultTheme from '../../../styles/themes/default';
import { Container, TitleCont, ActionButtons } from '../styles';

const handleReferencia = ({ referencia }) => (
  <>
    {referencia ? (
      <Grid item sm={12} md={3}>
        <BodyInfo title="Referência" body="Mensalidade" />
      </Grid>
    ) : (
      <></>
    )}
  </>
);

const handlePago = ({ pago, onHandleClick, state }) => {
  const vPago = pago;

  return (
    <>
      {vPago ? (
        <>
          <Grid item sm={12} md={6}>
            <BodyInfo title="Comprovante de pagamento" />
            <ButtonInfo
              title="Comprovante de pagamento"
              icon={<PrintIcon />}
              click={() =>
                onHandleClick(
                  'api/beneficiarios/comprovante/pdf',
                  0,
                  'comprovante',
                )
              }
            />
          </Grid>
        </>
      ) : (
        <>
          {state.mostraBoleto && state.mostraBoletoVencido ? (
            <Grid item sm={12} md={6}>
              <BodyInfo title="Boleto/Cartão" />
              <ButtonInfo
                title="Boleto/Cartão"
                icon={<PrintIcon />}
                click={() =>
                  onHandleClick('api/beneficiarios/boleto/pdf', 0, 'boleto')
                }
              />
            </Grid>
          ) : null}
        </>
      )}
    </>
  );
};

const handleCloseModal = (context) => {
  context.showModal({
    open: false,
  });
};
const Detail = (props) => {
  const [state, setState] = React.useState({
    inLoading: false,
    mostraBoleto: true,
    mostraBoletoVencido: true,
  });
  const { dadosPaga } = props;
  const theme = defaultTheme;

  const modal = React.useContext(WebContext);

  React.useEffect(() => {
    const init = async () => {
      const mostraBoleto = await SolusAPI.get('api/settings/boleto');
      const mostraBoletoVencido = await SolusAPI.get(
        'api/settings/boleto-vencido',
      );
      let liberado = true;

      if (dadosPaga.vencida === 'S') {
        if (mostraBoletoVencido === 'N') {
          liberado = false;
        }
      }

      setState({
        ...state,
        inLoading: false,
        mostraBoleto: mostraBoleto.data === 'S',
        mostraBoletoVencido: liberado,
      });
    };
    init();
  }, []);

  const handleReturn = (dadosFromAPI, modelo) => {
    const settings = {
      boleto: {
        messageSuccess: 'Boleto gerado com sucesso!',
        messageError:
          'Não foi possível imprimir a 2ª via do seu boleto no momento, tente novamente mais tarde!',
      },
      fatura: {
        messageSuccess: 'Fatura gerada com sucesso!',
        messageError:
          'Não foi possível gerar a fatura no momento, tente novamente mais tarde!',
      },
      comprovante: {
        messageSuccess: 'Comprovante gerado com sucesso!',
        messageError:
          'Não foi possível gerar o seu comprovante no momento, tente novamente mais tarde!',
      },
    };

    const dataReturn = {};

    if (dadosFromAPI.success || isURL(dadosFromAPI.data)) {
      dataReturn.message = settings[modelo].messageSuccess;
      dataReturn.variant = 'success';
      if (isURL(dadosFromAPI.data)) window.open(dadosFromAPI.data);
      else {
        const pdfWindow = window.open('');

        pdfWindow.document.write(
          `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${encodeURI(
            dadosFromAPI.data.mensagem,
          )}'></iframe>`,
        );
      }
    } else {
      dataReturn.message = settings[modelo].messageError;
      dataReturn.variant = 'error';
    }

    return dataReturn;
  };

  const onHandleClick = async (action, tipo, modelo) => {
    setState({
      ...state,
      inLoading: true,
    });
    const userAuth = await isPromise(modal.module.beneficiario.userAuth);
    const dadosFromAPI = await SolusAPI.post(action, {
      api: 'S',
      id_usuario: userAuth.id,
      id_contrato: userAuth.id_contrato,
      id_titular: userAuth.id_titular,
      logando: 'PF',
      id_pagamento: dadosPaga.id_pagamento,
      returnName: 'S',
      atualiza_boleto: 'S',
      tipo,
    });

    setState((old) => ({
      ...old,
      ...handleReturn(dadosFromAPI, modelo),
      inLoading: false,
    }));
  };

  if (state.inLoading) {
    return <CircularIndeterminateModal />;
  }

  return (
    <Container>
      <TitleCont>
        <Icon icon={ReceiptIcon} color="#fff">
          <Typography variant="h5">Detalhes da Mensalidade</Typography>
        </Icon>
      </TitleCont>
      {state.message ? (
        <Snackbars
          key={new Date()}
          message={state.message}
          variant={state.variant}
          show
        />
      ) : (
        <></>
      )}
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={6}>
          {/* <BodyInfo
              title="Local de cobrança"
              body={`${dadosPaga.local_pagamento}.`}
            /> */}
        </Grid>
        <Grid item sm={12} md={3}>
          <BodyInfo
            title="Competência"
            body={dateFormat(dadosPaga.data_documento)}
          />
        </Grid>
        {handleReferencia(props)}
      </Grid>
      <Grid container spacing={theme.grid.spacing}>
        {handlePago({ pago: dadosPaga.situacao === 'S', onHandleClick, state })}
        <Grid item sm={12} md={6}>
          <BodyInfo title="Detalhamento da Fatura" />

          <ButtonInfo
            title="Fatura PDF"
            icon={<PictureAsPdfIcon />}
            click={() =>
              onHandleClick('api/beneficiarios/fatura/pdf', 0, 'fatura')
            }
          />

          <ButtonInfo
            title="Fatura CSV"
            icon={<FileCopyIcon />}
            click={() =>
              onHandleClick('api/beneficiarios/fatura/csv', 0, 'fatura')
            }
          />
        </Grid>
        {dadosPaga.link_nfse ? (
          <Grid item sm={12} md={6}>
            <BodyInfo title="Nota fiscal" />
            <div style={{ opacity: dadosPaga.link_nfse ? 1 : 0.6 }}>
              <ButtonInfo
                title="Nota fiscal"
                icon={<FileCopyIcon />}
                click={() => {
                  if (dadosPaga.link_nfse) window.open(dadosPaga.link_nfse);
                }}
              />
            </div>
          </Grid>
        ) : null}
      </Grid>
      <ActionButtons direction="flex-start">
        <Button
          text="Fechar"
          variant="contained"
          color="secondary"
          fullWidth={false}
          onClick={() => handleCloseModal(modal)}
        />
      </ActionButtons>
    </Container>
  );
};

export default Detail;
