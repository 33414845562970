import React from 'react';
import { FormatAlignLeftIcon } from '@solussaude/core/Icons';

export const temp = {};

const processData = (apiData) => {
  const array = [];

  if (apiData.length > 0) {
    apiData.forEach((limite) => {
      array.push({
        limite: limite.nome,
        total: limite.total,
        consumido: limite.consumido,
        restante: limite.restante,
      });
    });
  }
  return array;
};

export const dadosLimites = (apiData) => ({
  header: (
    <p>
      Abaixo estão as descrições e os respectivos limites com seu devido valor
      total e consumo.
    </p>
  ),
  table: {
    options: {
      type: 'headerTop',
      small: true,
    },

    headers: [
      {
        label: 'Limite',
        icon: <FormatAlignLeftIcon color="primary" />,
      },
      {
        label: 'Total',
      },
      {
        label: 'Consumido',
      },
      {
        label: 'Restante',
      },
    ],
    data: processData(apiData || []),
  },
});
