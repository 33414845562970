import React from 'react';
import Grid from '@solussaude/core/Grid';
import Form from '@solussaude/core/CustomForm';
import defaultTheme from '../../../../styles/themes/default';
import DadosMedico from '../DadosMedico';
import Anexos from '../Anexos';
import TipoItem from '../TipoItem';
import { formOutros } from './settings';

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

let formValuesFormOutros;
const getMappingFormOutros = () => formValuesFormOutros;
const setMappingFormOutros = (newValue) => {
  formValuesFormOutros = newValue;
};

export default function FormularioOutros({
  setMapping,
  getMapping,
  tipoSolicitacao,
}) {
  const theme = defaultTheme;

  setMapping({
    ...getMapping(),
    getFormOutros: getFormValues,
    getMappingFormOutros
  });

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Grid item sm={12} md={12}>
            <Form settings={formOutros()} setValues={setFormValues} setMappingForm={setMappingFormOutros}/>
            <DadosMedico setMapping={setMapping} getMapping={getMapping} />
            <br />
            <TipoItem setMapping={setMapping} getMapping={getMapping} />
            <br />
            <Anexos
              setMapping={setMapping}
              getMapping={getMapping}
              tipoSolicitacao={tipoSolicitacao}
            />
            <br />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
