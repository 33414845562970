/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import WebContext from '@solussaude/core/WebContext';
import { CheckIcon, CloseIcon, WarningIcon } from '@solussaude/core/Icons';
import Grid from '@solussaude/core/Grid';
import Button from '@solussaude/core/Inputs/Button';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import { isPromise, isURL } from '@solussaude/core/Utils';
import SolusAPI from '../../../services/SolusApi';

import defaultTheme from '../../../styles/themes/default';
import { Container, ActionButtons } from './styles';

export default function CartaPortabilidade() {
  const context = React.useContext(WebContext);
  const theme = defaultTheme;
  const [state, setState] = React.useState({
    inLoading: true,
    success: false,
  });

  const handleCloseModal = () => {
    context.showModal({
      open: false,
      renderBody: context.modal.renderBody,
    });
  };

  React.useEffect(() => {
    const getFromAPI = async () => {
      const userAuth = await isPromise(context.module.beneficiario.userAuth);

      const dadosFromAPI = await SolusAPI.get(
        'api/beneficiarios/cartaPortabilidade/pdf',
        {
          userID: userAuth.id,
        },
      );
      let urlPHP = dadosFromAPI.data;

      if (!isURL(urlPHP)) {
        urlPHP = process.env.REACT_APP_HOST + urlPHP.replace('/', '');
      }

      const success = dadosFromAPI.success && isURL(urlPHP);

      if (success) window.open(urlPHP);
      setState((old) => ({
        ...old,
        inLoading: false,
        success,
      }));
    };
    getFromAPI();
  }, []);

  if (state.inLoading) {
    return <CircularIndeterminateModal />;
  }

  const renderError = () => (
    <>
      <BodyInfo
        icon={<WarningIcon color="error" />}
        title="Opss..."
        body="Não foi possivel emitir a carta de portabilidade tente novamente mais tarde!"
      />
      <ActionButtons direction="flex-start">
        <Button
          text="Fechar"
          variant="outlined"
          color="secondary"
          startIcon={<CloseIcon />}
          fullWidth={false}
          onClick={() => handleCloseModal()}
        />
      </ActionButtons>
    </>
  );

  const renderSuccess = () => (
    <>
      <BodyInfo
        icon={<CheckIcon color="primary" />}
        title="Carta de portabilidade"
        body="Seu documento foi gerado com sucesso!"
      />
      <ActionButtons direction="flex-end">
        <Button
          text="Concluir"
          variant="contained"
          color="primary"
          endIcon={<CheckIcon />}
          fullWidth={false}
          onClick={() => handleCloseModal()}
        />
      </ActionButtons>
    </>
  );

  return (
    <Container>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          {state.success ? renderSuccess() : renderError()}
        </Grid>
      </Grid>
    </Container>
  );
}
