/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import Grid from '@solussaude/core/Grid';
import Page from '@solussaude/core/Surfaces/Page';
import Form from '@solussaude/core/CustomForm';
import LengendaANS from '@solussaude/core/Helpers/LegendaQualificacoesANS';
import ItemPrestador from '@solussaude/core/Helpers/ItemPrestador';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import WebContext from '@solussaude/core/WebContext';
import { isPromise } from '@solussaude/core/Utils';
import { WarningIcon, AssignmentIcon } from '@solussaude/core/Icons';
import SolusAPI from '../../services/SolusApi';
import { Container, ContainerInfo } from './styles';

import { renderForm, prestadores, legendas } from './settings';

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

export default function GuiaMedico() {
  const webContext = React.useContext(WebContext);
  const [state, setState] = React.useState({
    loading: true,
    render: <></>,
    nomePrestador: { selected: 0, descricao: [] },
    tipoPrestador: { selected: 0, descricao: [] },
    estado: { selected: 0, descricao: [] },
    cidade: { selected: 0, descricao: [] },
    especialidade: { selected: 0, descricao: [] },
    apiLegenda: [],
  });

  React.useEffect(() => {
    const init = async () => {
      const est = await SolusAPI.get('api/beneficiarios/localidades-uf');
      const cid = await SolusAPI.post('api/beneficiarios/localidades-cidades', {
        estado: est.data[0].uf,
      });
      const tipoP = await SolusAPI.get('api/beneficiarios/tipos-prestador');
      const espec = await SolusAPI.post('api/beneficiarios/especialidades', {
        tipoPrestador: '',
      });

      const dadosL = {
        data: [
          {
            img: `${process.env.PUBLIC_URL}/img/documentacao/Certificação ISO 9001.png`,
            label: 'Certificação ISO 9001',
          },
          {
            img: `${process.env.PUBLIC_URL}/img/documentacao/Certificação de Entidades Gestoras de outros Programas de Qualidade.png`,
            label:
              'Certificação de Entidades Gestoras de outros Programas de Qualidade',
          },
          {
            img: `${process.env.PUBLIC_URL}/img/documentacao/Comunicação de eventos adversos.png`,
            label: 'Comunicação de eventos adversos',
          },
          {
            img: `${process.env.PUBLIC_URL}/img/documentacao/Doutorado Pós-Doutorado.png`,
            label: 'Doutorado Pós-Doutorado',
          },
          {
            img: `${process.env.PUBLIC_URL}/img/documentacao/Mestrado.png`,
            label: 'Mestrado',
          },
          {
            img: `${process.env.PUBLIC_URL}/img/documentacao/Programa de Acreditação.png`,
            label: 'Programa de Acreditação',
          },
          {
            img: `${process.env.PUBLIC_URL}/img/documentacao/Pós-graduação Lato Senso.png`,
            label: 'Pós-graduação Lato Senso',
          },
          {
            img: `${process.env.PUBLIC_URL}/img/documentacao/Qualidade Monitorada.png`,
            label: 'Qualidade Monitorada',
          },
          {
            img: `${process.env.PUBLIC_URL}/img/documentacao/Residência.png`,
            label: 'Residência',
          },
          {
            img: `${process.env.PUBLIC_URL}/img/documentacao/Título de Especialista.png`,
            label: 'Título de Especialista',
          },
        ],
      };

      setState({
        ...state,
        loading: false,
        render: (
          <ContainerInfo>
            <BodyInfo
              icon={<WarningIcon />}
              title="Pesquise um prestador"
              body="Utilize os filtros ao lado para encontrar os prestadores disponíveis."
            />
          </ContainerInfo>
        ),
        cidade: { selecionado: 0, descricao: cid.data },
        estado: {
          selecionado: 0,
          descricao: est.data,
        },
        tipoPrestador: {
          selecionado: 0,
          descricao: tipoP.data,
        },
        especialidade: {
          selecionado: 0,
          descricao: espec.data,
        },
        apiLegenda: dadosL.data,
      });
    };
    init();
  }, []);

  const onHandleClick = async () => {
    const { cidade, uf, tipoPrestador, especialidade } = getFormValues();
    const userAuth = await isPromise(webContext.module.beneficiario.userAuth);

    const dados = await SolusAPI.post('api/beneficiarios/guia-medico', {
      usuario: userAuth.id,
      cidade,
      uf,
      tipoPrestador,
      especialidade,
    });

    if (dados.data.code === 2000) {
      setState({
        ...state,
        loading: false,
        estado: {
          selecionado: uf,
          descricao: state.estado.descricao,
        },
        cidade: {
          selecionado: cidade,
          descricao: state.cidade.descricao,
        },
        tipoPrestador: {
          selecionado: tipoPrestador,
          descricao: state.tipoPrestador.descricao,
        },
        especialidade: {
          selecionado: especialidade,
          descricao: state.especialidade.descricao,
        },
        render: <ItemPrestador dados={prestadores(dados.data.prestadores)} />,
      });
    }
  };

  const changeTipo = async (e, tipoPrestador, cidade, estado) => {
    let especialidades = [];

    if (tipoPrestador === '') {
      setState({
        ...state,
        tipoPrestador: {
          selecionado: 0,
          descricao: tipoPrestador.descricao,
        },
        estado: {
          selecionado: getFormValues().uf,
          descricao: estado.descricao,
        },
        cidade: {
          selecionado: 0,
          descricao: [],
        },
        especialidade: {
          selecionado: getFormValues().especialidade,
          descricao: especialidades.descricao,
        },
      });
    }

    especialidades = await SolusAPI.post('api/beneficiarios/especialidades', {
      tipoPrestador: e.value,
    });
    setState({
      ...state,
      tipoPrestador: {
        selecionado: getFormValues().tipoPrestador,
        descricao: tipoPrestador.descricao,
      },
      estado: { selecionado: getFormValues().uf, descricao: estado.descricao },
      cidade: {
        selecionado: getFormValues().cidade,
        descricao: cidade.descricao,
      },
      especialidade: {
        selecionado: 0,
        descricao: especialidades.data,
      },
    });
  };

  const changeEstado = async (e, estado, tipoPrestador, especialidade) => {
    let cidades = [];

    if (estado === '') {
      setState({
        ...state,
        tipoPrestador: {
          selecionado: getFormValues().tipoPrestador,
          descricao: tipoPrestador.descricao,
        },
        estado: { selecionado: e.value, descricao: estado.descricao },
        cidade: {
          selecionado: 0,
          descricao: [],
        },
        especialidade: {
          selecionado: getFormValues().especialidade,
          descricao: especialidade.descricao,
        },
      });
    }

    cidades = await SolusAPI.post('api/beneficiarios/localidades-cidades', {
      estado: e.value,
    });
    setState({
      ...state,
      tipoPrestador: {
        selecionado: getFormValues().tipoPrestador,
        descricao: tipoPrestador.descricao,
      },
      estado: { selecionado: e.value, descricao: estado.descricao },
      cidade: { selecionado: 0, descricao: cidades.data },
      especialidade: {
        selecionado: getFormValues().especialidade,
        descricao: especialidade.descricao,
      },
    });
  };

  const GuiaForm = () => {
    const settings = renderForm(
      state.nomePrestador,
      state.tipoPrestador,
      state.estado,
      state.cidade,
      state.especialidade,
      changeEstado,
      changeTipo,
      onHandleClick,
    );

    if (state.loading) {
      return <CircularIndeterminateModal />;
    }

    return (
      <>
        <Page title="Guia médico" icon={AssignmentIcon}>
          <Form settings={settings} setValues={setFormValues} />
        </Page>
      </>
    );
  };

  return (
    <>
      <Container>
        <Grid container>
          <Grid item md={4}>
            {state.loading === false ? (
              <>
                <GuiaForm />
                <LengendaANS settings={legendas(state.apiLegenda)} />
              </>
            ) : (
              <CircularIndeterminateModal />
            )}
          </Grid>

          <Grid item md={8} style={{ paddingLeft: 25 }}>
            {state.loading === false ? (
              <>{state.render}</>
            ) : (
              <CircularIndeterminateModal />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
