/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import { AssignmentLateIcon } from '@solussaude/core/Icons';

import Stepper from '@solussaude/core/Navigation/Steppers';
import Step from '@solussaude/core/Navigation/Step';
import StepLabel from '@solussaude/core/Navigation/StepLabel';

import Grid from '@solussaude/core/Grid';
import CircularIndeterminate from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import Page from '@solussaude/core/Surfaces/Page';

import {
  RenderStep1,
  RenderStep2,
  RenderStep3,
  RenderStep4,
  RenderStep5,
} from './steps';
import { ContentLoading } from './styles';
import defaultTheme from '../../../styles/themes/default';
import { cancelamento } from './settings';

function genKey() {
  return Math.random().toString(16).slice(-4);
}

function renderStepHeader(activeStep) {
  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel>
        {cancelamento.steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
}

function getStepContent(state, handleNext, handleBack, renderLoading, props) {
  const renderArray = [];

  switch (state.active) {
    case 0:
      renderArray.push(
        <React.Fragment key={genKey()}>
          {renderStepHeader(state.active)}
          <RenderStep1
            params={state}
            handleNext={handleNext}
            handleBack={handleBack}
            props={props}
          />
        </React.Fragment>,
      );
      break;
    case 1:
      renderArray.push(
        <React.Fragment key={genKey()}>
          {renderStepHeader(state.active)}
          <RenderStep2 handleNext={handleNext} handleBack={handleBack} />
        </React.Fragment>,
      );
      break;
    case 2:
      renderArray.push(
        <React.Fragment key={genKey()}>
          {renderStepHeader(state.active)}
          <RenderStep3
            params={state}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </React.Fragment>,
      );
      break;
    case 3:
      renderArray.push(
        <React.Fragment key={genKey()}>
          {renderStepHeader(state.active)}
          <RenderStep4
            params={state}
            handleNext={handleNext}
            handleBack={handleBack}
            props={props}
          />
        </React.Fragment>,
      );
      break;
    case 4:
      renderArray.push(
        <React.Fragment key={genKey()}>
          <RenderStep5
            params={state}
            renderLoading={renderLoading}
            props={props}
          />
        </React.Fragment>,
      );
      break;
    default:
      renderArray.push('Unknown state.active');
  }

  return renderArray;
}

export default function Cancelation(props) {
  const theme = defaultTheme;

  const [step, setStep] = React.useState({
    active: 0,
  });
  const [state, setState] = React.useState({
    loading: true,
  });

  React.useEffect(() => {
    setTimeout(() => {
      setState({
        loading: false,
      });
    }, 1500);
  }, []);

  const handleNext = (params) => {
    setStep((old) => ({
      ...old,
      active: old.active + 1,
      [old.active + 1]: params || old[old.active + 1],
    }));
  };

  const handleBack = (params) => {
    setStep((old) => ({
      ...old,
      active: old.active - 1,
      [old.active - 1]: params || old[old.active - 1],
    }));
  };

  const renderLoading = () => (
    <>
      <ContentLoading>
        <CircularIndeterminate />
      </ContentLoading>
    </>
  );

  const renderCancelation = () =>
    getStepContent(step, handleNext, handleBack, renderLoading, props);

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page icon={AssignmentLateIcon} title="Cancelamento do contrato">
            <Grid item sm={12} md={12}>
              {state.loading ? renderLoading() : renderCancelation()}
            </Grid>
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
