/* eslint-disable import/no-extraneous-dependencies */
import createMuiTheme from '@solussaude/core/styles';

const api = {
  palette: {
    type: localStorage.getItem('tema') || 'light',
    primary: {
      main:
        (localStorage.getItem('web_color_primary') !== 'null'
          ? localStorage.getItem('web_color_primary')
          : '#168f59') || '#168f59',
      contrastText:
        (localStorage.getItem('web_fontC_primary') !== 'null'
          ? localStorage.getItem('web_fontC_primary')
          : '#fff') || '#fff',
    },
    secondary: {
      main:
        (localStorage.getItem('web_color_secondary') !== 'null'
          ? localStorage.getItem('web_color_secondary')
          : '#333333') || '#333333',
      contrastText:
        (localStorage.getItem('web_fontC_secondary') !== 'null'
          ? localStorage.getItem('web_fontC_secondary')
          : '#333333') || '#fff',
    },
    background: {
      body: localStorage.getItem('web_background_color') || '#f5f8fa',
      linearGradient: '',
      linearColorGradient: localStorage.getItem('web_fontC_gradient') || '#fff',
      headerTitle: '',
      buttonAccess: '',
      colorIcons: '',
      colorCards: '',
    },
  },
  typography: {
    fontFamily:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
    h6: {
      // fontSize: 200,
    },
  },
  grid: {
    spacing: 3,
  },
  shape: {
    borderRadius: '2px',
  },
  shadows: [
    'none',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
    '0px 5px 5px 0px rgba(0,0,0,.26)',
  ],
  // logo: 'https://www.solus.inf.br/public/image/logo.png'
  // logo: 'https://www.clinipam.com.br/Assets/img/logo.png',
  // logo: 'http://sampweb.samp.com.br/solusweb/comum/assets/usuario/images/logo.png',
  logo:
    'https://www.unimed.coop.br/image/layout_set_logo?img_id=2178066&t=1572523448834',
};

// linear-gradient(90deg, #42275A 10.5%, #B4044A 123.83%);
api.palette.background.linearGradient =
  localStorage.getItem('web_background_gradient') ||
  'linear-gradient(90deg,#06653b 10.5%,#168f59 123.83%);';
// localStorage.getItem('web_background_gradient') || `linear-gradient(to right,${api.palette.primary.main} ,${api.palette.secondary.main});`;

api.palette.background = {
  ...api.palette.background,
  headerTitle:
    localStorage.getItem('web_background_header_title') ||
    api.palette.primary.main,
  buttonAccess:
    localStorage.getItem('web_background_button_access') || '#a3238e',
  colorIcons: localStorage.getItem('web_background_icons') || '#a3238e',
  colorCards: localStorage.getItem('web_background_cards') || '#f47920',
};

const theme = createMuiTheme(api);

export default theme;
