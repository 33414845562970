import { SearchIcon } from '@solussaude/core/Icons';

export const formInstrumentacaoCirurgica = (state, onClick) => ({
  form: [
    {
      inputs: [
        [
          {
            // Column
            elem_id: 'nomeHospital',
            elem_val: state.nomeHospital,
            label: 'Nome do hospital/clínica',
            type: 'Input',
            autoFocus: true,
            icon: SearchIcon,
            flex: 5,
            required: true,
            variant: 'outlined',
            small: true,
            // disabled: true,
            onClick: () => onClick(),
          },
        ],
      ],
    },
  ],
});

export const formHospital = (state) => ({
  form: [
    {
      inputs: [
        [
          {
            // Column
            elem_id: 'parametro',
            elem_val: state.parametro,
            type: 'InputText',
            label: '',
            variant: 'outlined',
            small: true,
          },
        ],
      ],
    },
  ],
});

const hospital = (apiData) => {
  const array = [];

  if (apiData) {
    if (apiData.length > 0) {
      apiData.forEach((prestador) => {
        array.push({
          codigo: prestador.codigo,
          nome: prestador.nome,
          nomeFantasia: prestador.nomeFantasia,
          cpf: prestador.cpf,
          estadoConselho: prestador.estadoConselho,
          cidade: prestador.cidade,
        });
      });
    }
  }

  return array;
};

export const tableHospital = (apiData) => ({
  table: {
    options: {
      type: 'headerTop',
      small: true,
      clickable: true,
      pagination: true,
    },
    headers: [
      { label: 'Código' },
      { label: 'Nome' },
      { label: 'Nome fantasia' },
      { label: 'CPF/CNPJ' },
      { label: 'Estado' },
    ],
    data: hospital(apiData),
  },
});
