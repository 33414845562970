import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const Padding = styled.div`
  padding-${props => props.paddingDirection}: ${props => props.paddingValue ? props.paddingValue + 'px' : '10px'};
`;

export const ContainerButton = styled.div`
  width: 100%;
  padding-${props => props.paddingDirection}: 12px;  

  @media(max-width: 600px){
    padding: 12px 0px;
  }
`;

export const FlexDirection = styled.div`
  flex:1;
  display:flex;
  flex-direction: row;
  justify-content: space-between;

  @media(max-width: 600px){
    flex-direction: column;

    .MuiButtonBase-root{
      margin-top: 10px;
    }
  }
`;
