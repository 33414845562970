import React from 'react';

import Icon from '@solussaude/core/Icons';

import { Pages, PagesProps } from './pages';

const naturezaJuridica = (value, permission) => {
  return (
    Object(value).private === true &&
    Object(value).menu === true &&
    (!Object(value).permission ||
      (Object(value).permission &&
        Object(value).permission.includes(permission)))
  );
};

const menu = (value) => {
  const item = Object(value);

  return {
    label: item.title,
    link: item.route,
    icon: <Icon color="secondary">{item.icon}</Icon>,
  };
};

const menus = Object.values(Pages)
  .filter((value) => naturezaJuridica(value, 'PF'))
  .map((value) => [menu(value)]);

const menusPJ = Object.values(Pages)
  .filter((value) => naturezaJuridica(value, 'PJ'))
  .map((value) => [menu(value)]);

export { menus, menusPJ };
