import React from 'react';

import Boleto from '@solussaude/core/Boleto';
import defaultTheme from '../../styles/themes/default';

const BoletoDigital = (props) => {
  const theme = defaultTheme;

  return <Boleto theme={theme} props={props} />;
};

export default BoletoDigital;
