import React from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import { LockIcon, PermIdentityIcon } from '@solussaude/core/Icons';
import CircularIndeterminate from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import Button from '@solussaude/core/Inputs/Button';
import SolusAPI from '../../services/SolusApi';

import { ContainerOption, ContainerCaptcha } from './styles';

const customComp = (props) => (
  <ContainerOption key={0}>
    <Button
      text="Cadastrar senha"
      fullWidth={false}
      size="small"
      color="secondary"
      onClick={() => {
        props.history.push(
          `${localStorage.getItem('basename')}/cadastrar-senha`,
        );
      }}
    />
  </ContainerOption>
);

const Captcha = ({ setValues }) => {
  const [state, setState] = React.useState({
    inLoading: true,
  });

  React.useEffect(() => {
    const run = async () => {
      const dadosFromAPI = await SolusAPI.get('api/recupera-dados-config');
      setState({
        inLoading: false,
        token: dadosFromAPI.data.captcha_sitekey,
      });
    };
    run();
  }, []);

  const onChange = (value) => {
    setValues({
      captcha: value,
    });
  };

  if (state.inLoading)
    return (
      <ContainerCaptcha>
        <CircularIndeterminate />
      </ContainerCaptcha>
    );

  if (state.token)
    return (
      <ContainerCaptcha>
        <ReCAPTCHA
          sitekey={state.token}
          onChange={(value) => onChange(value)}
        />
      </ContainerCaptcha>
    );

  return <></>;
};

export const renderForm = ({
  onClickEntrar,
  onClickEsqueceu,
  props,
  setValues,
  getValues,
}) => ({
  form: [
    {
      label: 'Endereco',
      inputs: [
        [
          {
            elem_id: 'user',
            type: 'InputMask',
            typeMask: 'cpf',
            maskOnRequest: false,
            label: 'CPF (somente números)',
            required: true,
            icon: PermIdentityIcon,
            onClick: () => onClickEntrar(),
          },
        ],
        [
          {
            elem_id: 'pwd',
            type: 'InputText',
            label: 'Senha',
            required: true,
            icon: LockIcon,
            typeInputText: 'password',
            onClick: () => onClickEntrar(),
          },
        ],

        [],
        [<Captcha setValues={setValues} getValues={getValues} />],
        [
          {
            elem_id: 'btnSubmit',
            type: 'Button',
            text: 'Entrar',
            fullWidth: true,
            color: 'primary',
            variant: 'contained',
            onClick: () => onClickEntrar(),
          },
        ],
        [customComp(props)],
        [
          {
            type: 'Button',
            text: 'Esqueceu sua senha?',
            fullWidth: true,
            variant: 'text',
            onClick: () => onClickEsqueceu(),
          },
        ],
      ],
    },
  ],
});
