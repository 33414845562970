import { dateFormat } from '@solussaude/core/Utils';

export const temp = {};

const processDataProtocolos = (apiData) => {
  const array = [];
  if (apiData.length > 0) {
    apiData.forEach((protocolo) => {
      array.push({
        nProtocolo: protocolo.protocolo,
        assunto: protocolo.titulo,
        data: dateFormat(protocolo.data_abertura),
        situacao: protocolo.situacao,
        origem: protocolo.origem,
      });
    });
  }

  return array;
};

export const dadosMeusProtocolos = (apiData) => ({
  table: {
    options: {
      convertTo: true,
      editableTable: false,
      pagination: true,
      search: true,
      pageSize: 10,
    },
    columns: [
      {
        title: 'Nº Protocolo',
        field: 'nProtocolo',
      },
      {
        title: 'Assunto',
        field: 'assunto',
      },
      {
        title: 'Data Criação',
        field: 'data',
      },
      {
        title: 'Situação',
        field: 'situacao',
      },
      {
        title: 'Origem',
        field: 'origem',
      },
    ],
    data: processDataProtocolos(apiData || []),
  },
});
