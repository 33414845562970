/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import {
  SearchIcon,
  SendIcon,
  WarningIcon,
  AssignmentIcon,
} from '@solussaude/core/Icons';
import TableModal from '@solussaude/core/Helpers/TableModal';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import ActionButton from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import ItemPrestador from '@solussaude/core/Helpers/ItemPrestador';
import { dateFormat, processData } from '@solussaude/core/Utils';
import SolusAPI from '../../../services/SolusApi';

export const temp = {};

const processDataProcedimento = (apiData) => {
  const array = [];

  if (apiData.length > 0) {
    apiData.forEach((procedimento) => {
      array.push({
        codigo: procedimento.codigo,
        descricao: procedimento.nome,
      });
    });
  }
  return array;
};

const processPedidos = (apiData) => {
  const array = [];
  if (apiData.length > 0) {
    apiData.forEach((pedido) => {
      array.push({
        guia: pedido.id_guia,
        codigo: pedido.codigo_beneficiario,
        nome: pedido.nome_beneficiario,
        validade: dateFormat(`${pedido.validade}`),
        solicitante: pedido.nome_solicitante,
        dataSolicitacao: dateFormat(`${pedido.emissao}`),
      });
    });
  }

  return array;
};

const tableCID = (apiData) => ({
  table: {
    options: {
      type: 'headerTop',
      small: true,
      clickable: true,
      pagination: true,
    },

    headers: [
      {
        label: 'Guia',
      },
      {
        label: 'Código',
      },
      {
        label: 'Nome',
      },
      {
        label: 'Validade',
      },
      {
        label: 'Solicitante',
      },
      {
        label: 'Data da solicitação',
      },
    ],
    data: processPedidos(apiData),
  },
});

const handleCloseModal = (webContext) => {
  webContext.showModal({
    open: false,
  });
};

const handleOpenModal = async (webContext, refGetMapping, inputClicked, id) => {
  const dados = await SolusAPI.post('api/beneficiarios/lista-pedidos', {
    id,
  });

  const handleClick = (row) => {
    const formMap = refGetMapping().getMappingForm()();
    formMap[inputClicked].setValues(row.guia);

    webContext.showModal({
      open: false,
      renderBody: webContext.modal.renderBody,
    });
  };

  if (dados.data.length === 0) {
    webContext.showModal({
      open: true,
      renderBody: (
        <>
          <BodyInfo
            icon={<WarningIcon color="error" />}
            title="Opss..."
            body="Nenhum pedido foi encontrado"
          />
          <ActionButton>
            <Button
              variant="contained"
              color="secondary"
              text="Fechar"
              onClick={() => handleCloseModal(webContext)}
            />
          </ActionButton>
        </>
      ),
    });
  } else {
    webContext.showModal({
      open: true,
      renderBody: (
        <>
          <TableModal
            title="Lista de pedidos"
            description="Abaixo estão os pedidos realizados"
            tableSettings={tableCID(dados.data)}
            refGetMapping={refGetMapping}
            idElemChange={inputClicked}
            element="guia"
            handleClick={(row) => handleClick(row)}
          />
        </>
      ),
      styleContainer: 'padding: 25px',
    });
  }
};

export const consultaPedido = (
  webContext,
  refGetMapping,
  estados,
  changeEstado,
  cidades,
  guia,
  id,
  handleClick,
) => ({
  form: [
    {
      inputs: [
        [
          {
            // Column
            elem_id: 'pedido',
            elem_val: guia,
            type: 'Input',
            label: 'Pedido',
            icon: SearchIcon,
            flex: 4,
            onClick: () =>
              handleOpenModal(webContext, refGetMapping, 'pedido', id),
          },
          {
            // Column
            elem_id: 'estado',
            elem_val: estados.selecionado,
            type: 'Select',
            label: 'Estado',
            value: processData(estados.lista, 'codigo', 'descricao'),
            onChange: (e) => changeEstado(e, estados.lista, refGetMapping),
            flex: 3,
          },
          {
            // Column
            elem_id: 'cidade',
            type: 'Autocomplete',
            options: processData(cidades, 'nome', 'nome'),
            label: 'Cidade',
            labelOption: (saida) => saida.name || 'Selecione uma opção',
            required: true,
            fullWidth: true,
            flex: 3,
          },
        ],
        [
          {
            flex: 8,
          },
          {
            elem_id: 'verifica',
            type: 'Button',
            text: 'Verificar',
            onClick: () => handleClick(),
            fullWidth: true,
            color: 'primary',
            variant: 'contained',
            endIcon: <SendIcon />,
            flex: 2,
          },
        ],
      ],
    },
  ],
});

const Detail = ({
  cidade,
  estado,
  row,
  idBeneficiario,
  idGuia,
  webContext,
}) => {
  const [state, setState] = React.useState({
    loading: true,
    apiData: [],
  });

  React.useEffect(() => {
    const init = async () => {
      const dados = await SolusAPI.post(
        'api/beneficiarios/lista-prestadores-procedimento',
        {
          cidade: cidade.key,
          estado,
          idBeneficiario,
          idGuia,
          codigoProcedimento: row.codigo,
        },
      );

      setState({ loading: false, apiData: dados.data.prestadores });
    };
    init();
  }, []);

  const handleClick = (endereco) =>
    window.open(`http://maps.google.com.br/maps?q=${endereco}`, '_blank');

  const prestadores = (apiData) => {
    const array = [];

    apiData.forEach((prestador) => {
      let enderecoP = '';

      prestador.enderecosprestador.forEach((endereco) => {
        if (endereco.principal === 'S') {
          enderecoP = `${endereco.endereco}, ${endereco.numero} - ${endereco.complemento}+${endereco.cidade}`;
        }
      });

      if (enderecoP === '') {
        const primeiroEndereco = prestador.enderecosprestador[0];
        enderecoP = `${primeiroEndereco.endereco}, ${primeiroEndereco.numero} - ${primeiroEndereco.complemento} + ${primeiroEndereco.cidade}`;
      }
      array.push({
        nome: prestador.nome,
        conselho: prestador.conselho,
        docConselho: prestador.docConselho,
        ufConselho: prestador.ufConselho,
        rede: prestador.redescredenciadas,
        tipo: prestador.grupo_dominio,
        especialidades: prestador.especialidades,
        endereco: prestador.enderecosprestador,
        telefone: prestador.enderecosprestador,
        onClick: () => handleClick(enderecoP),
      });
    });

    return array;
  };

  return <ItemPrestador dados={prestadores(state.apiData)} />;
};

export const procedimentos = (
  apiData,
  cidade,
  estado,
  idBeneficiario,
  guia,
  webContext,
) => ({
  table: {
    options: {
      iconTitle: <AssignmentIcon color="primary" />,
      titleTable: 'Procedimentos',
      editableTable: false,
      detailPanel: (rowData) => (
        <Detail
          cidade={cidade}
          estado={estado}
          row={rowData}
          idBeneficiario={idBeneficiario}
          idGuia={guia}
          webContext={webContext}
        />
      ),
    },
    columns: [
      {
        title: 'Código',
        field: 'codigo',
      },
      {
        title: 'Descrição',
        field: 'descricao',
      },
    ],
    data: processDataProcedimento(apiData),
  },
});
