import React from 'react';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import Form from '@solussaude/core/CustomForm';
import SolusAPI from '../../../../../services/SolusApi';
import WebContext from '@solussaude/core/WebContext';
import ActionButton from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import { DescriptionIcon, WarningIcon } from '@solussaude/core/Icons';
import { formItem } from '../settings';
import { ContainerForm, Container } from './styles';

// eslint-disable-next-line import/no-cycle
import {
  validaQuantidadeCampoItem,
  validaQuantidadeItems,
  limpaCamposItem,
} from '../index';

let formMatMedValues = [{ codigo: '', item: '' }];

let formMappingMatMedValues;
const getMappingFormMatMed = () => formMappingMatMedValues;
const setMappingFormMatMed = (newValue) => {
  formMappingMatMedValues = newValue;
};

export default function MatMed({ onClick, setMapping, getMapping }) {
  setMapping({
    ...getMapping(),
    getMappingFormMatMed,
    getMatMedFormValues: formMatMedValues,
  });

  const webContext = React.useContext(WebContext);
  const [state, setState] = React.useState({
    codigo: '',
    item: { label: '', value: '' },
  });

  const getMatMedFormValues = () => formMatMedValues;
  const setMatMedFormValues = (newValue, i) => {
    if (newValue.qtde < 0) {
      validaQuantidadeItems(formMatMedValues, i, state, setState);
      return;
    }
    formMatMedValues[i] = newValue;
  };

  const handleCloseModal = () => {
    webContext.showModal({
      open: false,
    });
  };

  const handleErroCodigo = () => {
    webContext.showModal({
      open: true,
      renderBody: (
        <>
          <BodyInfo
            icon={<WarningIcon color="error" />}
            title="Opss..."
            body="Código do material ou medicamento não encontrado!"
            noDivider
          />
          <ActionButton>
            <Button
              variant="contained"
              color="secondary"
              text="Fechar"
              onClick={() => handleCloseModal()}
            />
          </ActionButton>
        </>
      ),
    });
  };

  const handleCodigo = async (i) => {
    const { codigo } = getMatMedFormValues()[i];

    if (codigo) {
      const dados = await SolusAPI.get(
        'v1/produto',
        {
          tipoSigla: 'M',
          tipoBusca: 'codigo',
          parametro: codigo,
        },
        { appName: 'api_webbeneficiario' },
      );

      if (
        dados.status === 200 &&
        !(dados.data?.length > 1 || dados.data?.length === 0)
      ) {
        formMatMedValues[i].item = dados.data[0].descricao;
        formMatMedValues[i].codigo = dados.data[0].codigo;
        setState({ ...state, items: formMatMedValues });
      } else {
        limpaCamposItem(formMatMedValues, i, state, setState);
        handleErroCodigo();
      }
    } else {
      limpaCamposItem(formMatMedValues, i, state, setState);
    }
  };

  const handleAdiciona = () => {
    formMatMedValues.push(getMatMedFormValues());
    setState({ ...state, items: formMatMedValues });
  };

  const onClickRemover = (i) => {
    formMatMedValues.splice(i, 1);
    setState({ ...state, items: formMatMedValues });

    if (validaQuantidadeCampoItem(formMatMedValues.length)) {
      handleAdiciona();
    }
  };

  return (
    <Container>
      <BodyInfo
        title="Materiais/Medicamentos"
        icon={<DescriptionIcon />}
        noDivider
      />

      {formMatMedValues.map((data, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ContainerForm key={i}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Form
              settings={formItem(
                formMatMedValues[i],
                () => onClick(i),
                () => handleAdiciona(),
                () => onClickRemover(i),
                () => handleCodigo(i),
                'MatMed',
              )}
              setValues={(newValue) => setMatMedFormValues(newValue, i)}
              setMappingForm={setMappingFormMatMed}
            />
          </form>
        </ContainerForm>
      ))}
    </Container>
  );
}
