/* eslint-disable import/no-extraneous-dependencies */
import styled from 'styled-components';

export const ContainerPDF = styled.div`
  width: 900px;
  height: 80vh;
  padding: 15px 30px;
  min-width: 100%;
  max-width: 100%;

  @media(max-width: 600px) {
    flex-direction: column;
    height: 80vh;
    padding: 1px 5px;
  }
`;
