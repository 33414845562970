import React from 'react';

import {
  CallMadeIcon,
  ListIcon,
  WarningIcon,
  CloseIcon,
  DeleteIcon,
} from '@solussaude/core/Icons';
import Grid from '@solussaude/core/Grid';
import Page from '@solussaude/core/Surfaces/Page';
import TableCRUD from '@solussaude/core/DataDisplay/TableCRUD';
import Button from '@solussaude/core/Inputs/Button';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import WebContext from '@solussaude/core/WebContext';
import SolusAPI from '../../../services/SolusApi';
import defaultTheme from '../../../styles/themes/default';
import { SolicitaChamado } from './views';
import { renderTable } from './settings';
import { ActionButtons } from './styles';

export default function SupportChannels() {
  const theme = defaultTheme;
  const webContext = React.useContext(WebContext);
  const [state, setState] = React.useState({
    loading: true,
    apiData: [],
    successApi: true,
    usaCRM: false,
  });

  React.useEffect(() => {
    const init = async () => {
      const dados = await SolusAPI.post('api/beneficiarios/atendimentos', {
        tipo: 'atendimento',
      });
      const usaCRM = await SolusAPI.get('api/solus/v1/parametros/UTILIZA_CRM');

      setState({
        loading: false,
        apiData: dados.data,
        successApi: dados.success,
        usaCRM: usaCRM.data.valor === 'S',
      });
    };
    init();
  }, []);

  const handleClick = () => {
    webContext.showModal({
      open: true,
      renderBody: <SolicitaChamado />,
    });
  };

  const handleCloseModal = () => {
    webContext.showModal({
      open: false,
    });
  };

  const handleDelete = () => {
    webContext.showModal({
      open: true,
      renderBody: (
        <>
          <BodyInfo
            icon={<WarningIcon color="error" />}
            title="Deseja realmente cancelar o chamado?"
            body="Seu chamado será cancelado e não poderá ser aberto novamente"
          />
          <ActionButtons direction="space-between">
            <Button
              text="Excluir chamado"
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              fullWidth={false}
              onClick={() => handleCloseModal()}
            />
            <Button
              text="Fechar"
              variant="contained"
              color="secondary"
              fullWidth={false}
              startIcon={<CloseIcon />}
              onClick={() => handleCloseModal()}
            />
          </ActionButtons>
        </>
      ),
    });
  };

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page title="Canais de atendimento" icon={ListIcon}>
            <Grid item sm={12} md={12}>
              <TableCRUD settings={renderTable(state.apiData, handleDelete)} />
              <ActionButtons direction="flex-end">
                <Button
                  text="Solicitar chamado"
                  variant="contained"
                  color="primary"
                  endIcon={<CallMadeIcon />}
                  fullWidth={false}
                  disabled={state.usaCRM}
                  onClick={() => handleClick()}
                />
              </ActionButtons>
            </Grid>
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
