import React from 'react';

import {
  PersonIcon,
  CreditCardIcon,
  RecentActorsIcon,
  GavelIcon,
  CommentIcon,
  AssignmentLateIcon,
} from '@solussaude/core/Icons';

import { processData } from '@solussaude/core/Utils';

export const solicitacao = {
  steps: ['Família do contrato', 'Motivo da solicitação', 'Confirmação'],
};

const processDataTable = (apiData) => {
  const array = [];

  if (apiData.length > 0) {
    apiData.forEach((beneficiario) => {
      array.push({
        beneficiario: beneficiario.nome,
        carteirinha: beneficiario.codigo,
        tipo: beneficiario.tipo,
        id: beneficiario.id,
      });
    });
  }

  return array;
};

const processDataConfirm = (beneficiarios, motivos) => {
  const array = [];
  beneficiarios.forEach((usuario) => {
    const motivo = motivos.find((element) => element.id === usuario.motivo);
    array.push({
      beneficiario: usuario.nomeBeneficiario,
      motivo: motivo !== undefined ? motivo.descricao : '',
      bo: usuario.bo,
      observacao: usuario.observacao,
    });
  });
  return array;
};

export const renderTable = (apiData, onSelectionChange) => ({
  table: {
    options: {
      titleTable: 'Selecione pelo menos um beneficiário para continuar',
      editableTable: false,
      selection: true,
      onSelectionChange,
    },
    columns: [
      {
        title: 'Beneficiario',
        field: 'beneficiario',
        icon: <PersonIcon color="primary" />,
      },
      {
        title: 'Carteirinha',
        field: 'carteirinha',
        icon: <CreditCardIcon color="primary" />,
      },
      {
        title: 'Tipo',
        field: 'tipo',
        icon: <RecentActorsIcon color="primary" />,
      },
    ],
    data: processDataTable(apiData),
  },
});

export const renderFormSolicita = (beneficiario, motivo, i) => ({
  header: {
    description:
      i === 0 ? (
        <p>
          Preencha os dados para solicitar uma nova carteirinha, é importante
          que o<b> endereço de correspondência </b>
          esteja atualizado!
        </p>
      ) : (
        ''
      ),
  },
  form: [
    {
      label: 'Solicitação de carteirinha',
      inputs: [
        [
          // Row
          {
            // Column
            elem_id: 'nomeBeneficiario',
            elem_val: beneficiario.beneficiario,
            disabled: true,
            icon: PersonIcon,
            type: 'InputText',
            label: 'Beneficiário',
            flex: 3,
          },
          {
            // Column
            elem_id: 'motivo',
            type: 'Select',
            value: processData(motivo, 'id', 'descricao'),
            label: 'Motivo',
            flex: 3,
          },
          {
            // Column
            elem_id: 'bo',
            type: 'InputText',
            icon: GavelIcon,
            label: 'B.O',
            flex: 3,
          },
          {
            // Column
            elem_id: 'observacao',
            type: 'InputText',
            icon: CommentIcon,
            label: 'Observação',
            flex: 3,
          },
          {
            // Column
            elem_id: 'id',
            elem_val: beneficiario.id,
            flex: 1,
            type: 'InputText',
            typeInputText: 'hidden',
            label: 'Código do beneficiário',
          },
        ],
      ],
    },
  ],
});

export const dadosConfirmacao = (apiData, motivos) => ({
  table: {
    options: {
      titleTable: 'Confirmar solicitação',
      editableTable: false,
      selecion: false,
    },
    columns: [
      {
        title: 'Beneficiario',
        field: 'beneficiario',
        icon: <PersonIcon color="primary" />,
      },
      {
        title: 'Motivo',
        field: 'motivo',
        icon: <AssignmentLateIcon color="primary" />,
      },
      {
        title: 'B.O',
        field: 'bo',
        icon: <GavelIcon color="primary" />,
      },
      {
        title: 'Observação',
        field: 'observacao',
        icon: <CommentIcon color="primary" />,
      },
    ],
    data: processDataConfirm(apiData, motivos),
  },
});
