import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const Titulo = styled.h1`
  width: 100%;
  height: 50px;
  font-size: 140px;
  font-weight: 600;
  text-align: center;
`;

export const Descricao = styled.p`
  width: 100%;
  height: 50px;
  font-size: 40px;
  line-height: 1.1;
  font-weight: 500;
  text-align: center;
`;

export const ContainerLogo = styled.div``;

export const Logo = styled.img`
  max-width: 100%;
  height: 100%;
`;
