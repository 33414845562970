/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import BodyFormModal from '@solussaude/core/Helpers/BodyFormModal';
import { isPromise } from '@solussaude/core/Utils';
import WebContext from '@solussaude/core/WebContext';

import { renderForm } from './settings';

export default function UtilizacaoServicos() {
  const { module } = React.useContext(WebContext);

  const [state, setState] = React.useState({
    inLoading: true,
    action: {
      verifyURL: 'api/beneficiarios/utilizacao/verify',
      verifyData: {},
      urlPDF: 'api/beneficiarios/utilizacao/pdf',
      dataPDF: {},
    },
  });

  React.useEffect(() => {
    const getFromAPI = async () => {
      const userAuth = await isPromise(module.beneficiario.userAuth);

      setState((old) => ({
        ...old,
        inLoading: true,
        action: {
          ...old.action,
          verifyData: {
            api: 'S',
            userId: userAuth.id,
            idTitular: userAuth.id_titular,
            imprimir: 'S',
          },
          dataPDF: {
            api: 'S',
            listar: 'S',
            userId: userAuth.id,
          },
        },
      }));
    };
    getFromAPI();
  }, []);

  return (
    <>
      <BodyFormModal
        title="Componente de utilização de serviços"
        description="Escolha o período que deseja emitir o extrato."
        formSettings={renderForm}
        descriptionSuccess={<p>Seu extrato foi impresso com sucesso!</p>}
        action={state.action}
      />
    </>
  );
}
