/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';
import Form from '@solussaude/core/CustomForm';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import { AccountBalanceIcon } from '@solussaude/core/Icons';
import SolusAPI from '../../../../../services/SolusApi';
import { formDadosBancarios } from './settings';

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

let formValuesDadosBancarios;
const getMappingDadosBancarios = () => formValuesDadosBancarios;
const setMappingDadosBancarios = (newValue) => {
  formValuesDadosBancarios = newValue;
};

export default function DadosBancarios({ setMapping, getMapping }) {
  const [state, setState] = React.useState({
    loading: true,
    banco: { selecionado: '', descricao: [] },
  });

  const [bancos, setBancos] = useState([]);

  React.useEffect(() => {
    const init = async () => {
      const dadosBanco = await SolusAPI.get(
        'v1/bancos',
        {
          flagWebSigla: 'S',
        },
        { appName: 'api_webbeneficiario' },
      );
      const dadosTipoConta = await SolusAPI.get(
        'v1/dominio/tipocontabancaria',
        {},
        { appName: 'api_beneficiario' },
      );
      if (dadosBanco.status === 200 && dadosTipoConta.status === 200) {
        setBancos(dadosBanco.data);

        setState({
          ...state,
          banco: {
            selecionado: dadosBanco.data[0].idBanco,
            descricao: dadosBanco.data,
          },
          tipoConta: {
            selecionado: dadosTipoConta.data[0].valor,
            descricao: dadosTipoConta.data,
          },
          loading: false,
        });
      } else {
        setState({
          ...state,
          loading: false,
        });
      }
    };
    init();
  }, []);

  setMapping({
    ...getMapping(),
    getFormDadosBancarios: getFormValues,
    getMappingDadosBancarios,
  });

  let qtdeDigitosAgencia;
  let qtdeDigitosConta;
  let qtdeDigitosDvAgencia;
  let qtdeDigitosDvConta;

  const blurBanco = () => {
    const dadosBanco = getMappingDadosBancarios()().banco.values;

    bancos.forEach((banco) => {
      if (banco.id === dadosBanco.key) {
        qtdeDigitosAgencia = banco.quantidadeDigitosAgencia;
        qtdeDigitosConta = banco.quantidadeDigitosConta;
        qtdeDigitosDvAgencia = banco.quantidadeDigitosDvAgencia;
        qtdeDigitosDvConta = banco.quantidadeDigitosDvConta;
      }
    });
  };

  const blurFormataDadosBancarios = (codigo) => {
    let field;
    let dadosField;
    let qtdeDigitoVerificador;
    let qtdeDigitos;

    if (codigo === 'CC') {
      dadosField = getMappingDadosBancarios()().contaCorrente.values;
      field = getMappingDadosBancarios()().contaCorrente;
      qtdeDigitoVerificador = qtdeDigitosDvConta;
      qtdeDigitos = qtdeDigitosConta;
    } else {
      dadosField = getMappingDadosBancarios()().agencia.values;
      field = getMappingDadosBancarios()().agencia;
      qtdeDigitoVerificador = qtdeDigitosDvAgencia;
      qtdeDigitos = qtdeDigitosAgencia;
    }

    const contemTraco = dadosField.includes('-') ? 1 : 0;

    if (dadosField && qtdeDigitos !== 0) {
      // Alguns bancos estão com a quantidade em 0, assim não é feita a formatação.
      if (
        dadosField.length - contemTraco >
        qtdeDigitos + qtdeDigitoVerificador
      ) {
        field.setError({
          is: true,
          helperText: 'Preenchimento inválido',
        });
        field.setValues(dadosField.replace('-', ''));
      } else if (dadosField.length <= qtdeDigitos + qtdeDigitoVerificador) {
        let zeros = '';
        let formatted = '';
        let digitoVerificador = '';

        for (
          let i = 0;
          i <
          qtdeDigitos +
            qtdeDigitoVerificador -
            (dadosField.length - contemTraco);
          i += 1
        ) {
          zeros += '0';
        }

        if (qtdeDigitoVerificador !== 0) {
          digitoVerificador = dadosField.slice(-qtdeDigitoVerificador);

          formatted = dadosField.includes('-')
            ? zeros +
              dadosField.substr(0, dadosField.length - qtdeDigitoVerificador) +
              digitoVerificador
            : `${
                zeros +
                dadosField.substr(0, dadosField.length - qtdeDigitoVerificador)
              }-${digitoVerificador}`;

          field.setValues(formatted);
        } else {
          formatted = zeros + dadosField;
          field.setValues(formatted);
        }
      }
    }
  };

  if (state.loading) return <CircularIndeterminateModal />;

  return (
    <>
      <BodyInfo
        title="Dados bancários"
        icon={<AccountBalanceIcon />}
        noDivider
      />
      <Form
        settings={formDadosBancarios(
          state,
          blurBanco,
          blurFormataDadosBancarios,
        )}
        setValues={setFormValues}
        setMappingForm={setMappingDadosBancarios}
      />
    </>
  );
}
