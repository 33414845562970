import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'contentCenter',
})``;

export const ContainerInfo = styled.div`
  width: 100%;
  background: #fff;
  padding: 25px;
`;
