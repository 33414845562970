import { processData } from '@solussaude/core/Utils';

export const formRecebimento = (formaPagamento, changePagamento) => ({
  form: [
    {
      inputs: [
        [
          // Row
          {
            // Column
            elem_id: 'siglaFormaPagamento',
            elem_val: formaPagamento.selecionado,
            type: 'Select',
            label: 'Escolha a forma de pagamento',
            required: true,
            value: processData(formaPagamento.descricao, 'codigo', 'descricao'),
            onChange: (e) => changePagamento(e),
            variant: 'outlined',
            small: true,
            flex: 5,
          },
        ],
      ],
    },
  ],
});

const possuiNotaFiscal = [
  {
    key: 'S',
    name: 'Sim',
  },
  {
    key: 'N',
    name: 'Não',
  },
];

export const formValor = (state, changeNota) => ({
  form: [
    {
      inputs: [
        [
          // Row
          {
            // Column
            elem_id: 'possuiNotaFiscal',
            elem_val: state.possuiNotaFiscal,
            type: 'RadioButton',
            label: 'Possui nota fiscal?',
            value: possuiNotaFiscal,
            required: true,
            variant: 'outlined',
            onChange: () => changeNota(),
            small: true,
            flex: 5,
          },
        ],
        [
          {
            // Column
            elem_id: 'valorSolicitado',
            elem_val: state.valorSolicitado,
            type: 'InputText',
            mask: 'money',
            label: 'Valor solicitado',
            required: true,
            variant: 'outlined',
            small: true,
            flex: 5,
          },
          {
            // Column
            elem_id: 'nroNotaFiscal',
            elem_val: state.nroNotaFiscal,
            type: 'InputText',
            typeInputText: 'number',
            label: 'Nº da nota fiscal',
            required: state.possuiNotaFiscal === 'S',
            variant: 'outlined',
            small: true,
            flex: 5,
          },
        ],
      ],
    },
  ],
});
