import React from 'react';
import { dateFormat } from '@solussaude/core/Utils';

const widthReal = window.innerWidth;

const processDataBeneficiario = (beneficiario, contrato) => {
  const array = [];
  if (beneficiario) {
    if (widthReal <= 500) {
      array.push(
        [
          {
            label: 'Nome',
            value: beneficiario.nome,
          },
        ],
        [
          {
            label: 'Categoria',
            value: beneficiario.tipo,
          },
        ],
        [
          {
            label: 'Sexo',
            value: beneficiario.sexo === 'F' ? 'Feminino' : 'Masculino',
          },
        ],
        [
          {
            label: 'Data de nascimento',
            value: dateFormat(beneficiario.nascimento),
          },
        ],
        [
          {
            label: 'Código do beneficiário',
            value: beneficiario.codigo,
          },
        ],
        [
          {
            label: 'Matrícula',
            value: beneficiario.matricula,
          },
        ],
        [
          {
            label: 'CNS - Cartão Nacional de Saúde',
            value: beneficiario.cartao_sus,
          },
        ],
        [
          {
            label: 'CPF',
            value: beneficiario.cpf,
          },
        ],
        [
          {
            label: 'RG',
            value: beneficiario.rg,
          },
        ],
        [
          {
            label: 'Orgão Emissor',
            value: beneficiario.rg_orgao_exped,
          },
        ],
        [
          {
            label: 'Contrato',
            value: contrato,
          },
        ],
        [
          {
            label: 'Data de inclusão',
            value: dateFormat(beneficiario.data_inclusao),
          },
        ],
        [
          {
            label: 'Estado Civil',
            value: beneficiario.estado_civil_extenso,
          },
        ],
        [
          {
            label: '',
            value: '',
          },
        ],
        [
          {
            label: '',
            value: '',
          },
        ],
      );
    } else {
      array.push(
        [
          {
            label: 'Nome',
            value: beneficiario.nome,
          },
          {
            label: 'Categoria',
            value: beneficiario.tipo,
          },
        ],
        [
          {
            label: 'Sexo',
            value: beneficiario.sexo === 'F' ? 'Feminino' : 'Masculino',
          },
          {
            label: 'Data de nascimento',
            value: dateFormat(beneficiario.nascimento),
          },
        ],
        [
          {
            label: 'Código do beneficiário',
            value: beneficiario.codigo,
          },
          {
            label: 'Matrícula',
            value: beneficiario.matricula,
          },
        ],
        [
          {
            label: 'CNS - Cartão Nacional de Saúde',
            value: beneficiario.cartao_sus,
          },
          {
            label: 'CPF',
            value: beneficiario.cpf,
          },
        ],
        [
          {
            label: 'RG',
            value: beneficiario.rg,
          },
          {
            label: 'Orgão Emissor',
            value: beneficiario.rg_orgao_exped,
          },
        ],
        [
          {
            label: 'Contrato',
            value: contrato,
          },
          {
            label: 'Data de inclusão',
            value: dateFormat(beneficiario.data_inclusao),
          },
        ],
        [
          {
            label: 'Estado Civil',
            value: beneficiario.estado_civil_extenso,
          },
        ],
        [
          {
            label: '',
            value: '',
          },
          {
            label: '',
            value: '',
          },
        ],
      );
    }
  }
  return array;
};

const processDataPlano = (plano) => {
  const array = [];
  if (plano) {
    if (widthReal <= 500) {
      array.push(
        [
          {
            label: 'Nome do plano',
            value: plano.nome,
          },
        ],
        [
          {
            label: 'Código do plano',
            value: plano.codigo,
          },
        ],
        [
          {
            label: 'Registro do plano na A.N.S',
            value: plano.registro_ans,
          },
        ],
        [
          {
            label: 'Vigência inicial do plano',
            value: dateFormat(plano.vigencia_inicial),
          },
        ],
        [
          {
            label: 'Regulamentação',
            value: plano.regulamentacao_plano,
          },
        ],
        [
          {
            label: 'Contratação',
            value: plano.natureza,
          },
        ],
        [
          {
            label: 'Segmentação',
            value: plano.cobertura,
          },
        ],
        [
          {
            label: 'Abrangência',
            value: plano.abrangencia,
          },
        ],
        [
          {
            label: 'Acomodação',
            value: plano.acomodacoes,
          },
        ],
        [
          {
            label: 'Período de implantação',
            value: plano.lei,
          },
        ],
        [
          {
            label: '',
            value: '',
          },
        ],
        [
          {
            label: '',
            value: '',
          },
        ],
      );
    } else {
      array.push(
        [
          {
            label: 'Nome do plano',
            value: plano.nome,
          },
          {
            label: 'Código do plano',
            value: plano.codigo,
          },
        ],
        [
          {
            label: 'Registro do plano na A.N.S',
            value: plano.registro_ans,
          },
          {
            label: 'Vigência inicial do plano',
            value: dateFormat(plano.vigencia_inicial),
          },
        ],
        [
          {
            label: 'Regulamentação',
            value: plano.regulamentacao_plano,
          },
          {
            label: 'Contratação',
            value: plano.natureza,
          },
        ],
        [
          {
            label: 'Segmentação',
            value: plano.cobertura,
          },
          {
            label: 'Abrangência',
            value: plano.abrangencia,
          },
        ],
        [
          {
            label: 'Acomodação',
            value: plano.acomodacoes,
          },
          {
            label: 'Período de implantação',
            value: plano.lei,
          },
        ],
        [
          {
            label: '',
            value: '',
          },
          {
            label: '',
            value: '',
          },
        ],
      );
    }
  }
  return array;
};

const processDataEndereco = (endereco) => {
  const array = [];
  if (endereco) {
    let enderecoPrincial = {};
    // eslint-disable-next-line consistent-return
    endereco.forEach((data) => {
      if (data.principal === 'S') {
        enderecoPrincial = data;
        return false;
      }
    });
    if (widthReal <= 500) {
      array.push(
        [
          {
            label: 'Endereço',
            value: `${enderecoPrincial.tipo_logradouro} ${enderecoPrincial.logradouro}`,
          },
        ],
        [
          {
            label: 'Número',
            value: enderecoPrincial.numero,
          },
        ],
        [
          {
            label: 'Complemento',
            value: enderecoPrincial.complemento,
          },
        ],
        [
          {
            label: 'Bairro',
            value: enderecoPrincial.bairro,
          },
        ],
        [
          {
            label: 'Cidade',
            value: enderecoPrincial.cidade,
          },
        ],
        [
          {
            label: 'Estado',
            value: enderecoPrincial.estado,
          },
        ],
        [
          {
            label: 'CEP',
            value: enderecoPrincial.cep,
          },
        ],
        [
          {
            label: '',
            value: '',
          },
        ],
        [
          {
            label: '',
            value: '',
          },
        ],
      );
    } else {
      array.push(
        [
          {
            label: 'Endereço',
            value: `${enderecoPrincial.tipo_logradouro} ${enderecoPrincial.logradouro}`,
          },
          {
            label: 'Número',
            value: enderecoPrincial.numero,
          },
        ],
        [
          {
            label: 'Complemento',
            value: enderecoPrincial.complemento,
          },
          {
            label: 'Bairro',
            value: enderecoPrincial.bairro,
          },
        ],
        [
          {
            label: 'Cidade',
            value: enderecoPrincial.cidade,
          },
          {
            label: 'Estado',
            value: enderecoPrincial.estado,
          },
        ],
        [
          {
            label: 'CEP',
            value: enderecoPrincial.cep,
          },
        ],
        [
          {
            label: '',
            value: '',
          },
          {
            label: '',
            value: '',
          },
        ],
      );
    }
  }
  return array;
};

const processDataContato = (telefone, email) => {
  const array = [];
  if (telefone || email) {
    email.forEach((data) => {
      if (widthReal <= 500) {
        array.push(
          [
            {
              label: 'Email',
              value: data.email,
            },
          ],
          [
            {
              label: 'Principal',
              value: data.tipo_flag === 'P' ? 'Sim' : 'Não',
            },
          ],
        );
      } else {
        array.push([
          {
            label: 'Email',
            value: data.email,
          },
          {
            label: 'Principal',
            value: data.tipo_flag === 'P' ? 'Sim' : 'Não',
          },
        ]);
      }
    });

    telefone.forEach((data) => {
      if (widthReal <= 500) {
        array.push(
          [
            {
              label: 'Telefone',
              value: data.telefone,
            },
          ],
          [
            {
              label: 'tipo',
              value: data.tipo_extenso,
            },
          ],
        );
      } else {
        array.push([
          {
            label: 'Telefone',
            value: data.telefone,
          },
          {
            label: 'tipo',
            value: data.tipo_extenso,
          },
        ]);
      }
    });

    if (widthReal <= 500) {
      array.push(
        [
          {
            label: '',
            value: '',
          },
        ],
        [
          {
            label: '',
            value: '',
          },
        ],
      );
    } else {
      array.push([
        {
          label: '',
          value: '',
        },
        {
          label: '',
          value: '',
        },
      ]);
    }
  }
  return array;
};

const processDataOperadora = (apiSiteOpe, apiFantOpe, apiParamSac) => {
  const array = [];
  if (widthReal <= 500) {
    array.push(
      [
        {
          label: 'Nome fantasia Operadora',
          value: apiFantOpe,
        },
      ],
      [
        {
          label: 'Site Operadora',
          value: apiSiteOpe,
        },
      ],
      [
        {
          label: 'Site ANS',
          value: 'www.ans.gov.br',
        },
      ],
      [
        {
          label: 'Disque ANS',
          value: '0800 7019 656',
        },
      ],
      [
        {
          label: 'Canais de atendimento ANS',
          value: (
            <a
              href="http://www.ans.gov.br/canais-de-atendimento"
              target="blank"
            >
              clique aqui
            </a>
          ),
        },
      ],
      [
        {
          label: 'Núcleos de Fiscalização',
          value: (
            <a
              href="http://www.ans.gov.br/aans/nossos-enderecos"
              target="blank"
            >
              clique aqui
            </a>
          ),
        },
      ],
      [
        {
          label: 'S.A.C',
          value: apiParamSac,
        },
      ],
    );
  } else {
    array.push(
      [
        {
          label: 'Nome fantasia Operadora',
          value: apiFantOpe,
        },
        {
          label: 'Site Operadora',
          value: apiSiteOpe,
        },
      ],
      [
        {
          label: 'Site ANS',
          value: 'www.ans.gov.br',
        },
        {
          label: 'Disque ANS',
          value: '0800 7019 656',
        },
      ],
      [
        {
          label: 'Canais de atendimento ANS',
          value: (
            <a
              href="http://www.ans.gov.br/canais-de-atendimento"
              target="blank"
            >
              clique aqui
            </a>
          ),
        },
        {
          label: 'Núcleos de Fiscalização',
          value: (
            <a
              href="http://www.ans.gov.br/aans/nossos-enderecos"
              target="blank"
            >
              clique aqui
            </a>
          ),
        },
      ],
      [
        {
          label: 'S.A.C',
          value: apiParamSac,
        },
      ],
    );
  }

  return array;
};

const processDataContrato = (apiParams) => {
  const array = [];
  return array.concat(
    processDataBeneficiario(apiParams.apiDataUsuario, apiParams.contrato),
    processDataPlano(apiParams.apiDataPlano),
    processDataEndereco(apiParams.apiDataEndereco),
    processDataContato(apiParams.apiDataTelefone, apiParams.apiDataEmail),
    processDataOperadora(
      apiParams.apiSiteOpe,
      apiParams.apiFantOpe,
      apiParams.apiParamSac,
    ),
  );
};

const processDataDependentes = (apiDependentes) => {
  const array = [];
  if (apiDependentes.length > 0) {
    apiDependentes.forEach((dependente) => {
      array.push({
        matricula: dependente.matricula,
        categoria: dependente.parentesco_extenso,
        nome: dependente.nome,
        nascimento: dateFormat(dependente.nascimento),
      });
    });
  }

  return array;
};

// eslint-disable-next-line import/prefer-default-export
export const dadosCadastro = (apiParams) => ({
  table: {
    options: {
      type: 'headerAside',
      small: true,
      bold: true,
    },
    data: processDataContrato(apiParams),
  },
});

export const dadosDependentes = (apiDependentes) => ({
  table: {
    options: {
      editableTable: false,
    },
    columns: [
      {
        title: 'Matrícula',
        field: 'matricula',
      },
      {
        title: 'Categoria',
        field: 'categoria',
      },
      {
        title: 'Nome',
        field: 'nome',
      },
      {
        title: 'Nascimento',
        field: 'nascimento',
      },
    ],
    data: processDataDependentes(apiDependentes),
  },
});
