/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import Form from '@solussaude/core/CustomForm';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import ActionButtons from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import WebContext from '@solussaude/core/WebContext';
import {
  CloseIcon,
  WarningIcon,
  TodayIcon,
  CheckCircleIcon,
  CheckIcon,
} from '@solussaude/core/Icons';

import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import SolusAPI from '../../../services/SolusApi';
import { renderForm } from './settings';
import { ContainerWarning } from './styles';

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

let mapping;
const getMapping = () => mapping;
const setMapping = (newMapping) => {
  mapping = newMapping;
};

let mappingForm;
const getMappingForm = () => mappingForm;
const setMappingForm = (pGetMapping) => {
  mappingForm = pGetMapping;
};

export default function CancelamentoBoletoDigital() {
  const webContext = React.useContext(WebContext);

  const [state, setState] = React.useState({
    loading: true,
    cep: '',
    tipoLogradouro: { selecionado: 0, descricao: [] },
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    estados: { selecionado: 0, descricao: [] },
    cidades: { selecionado: 0, descricao: [] },
    dadosEndereco: [],
  });

  React.useEffect(() => {
    const init = async () => {
      const dadosEnd = await SolusAPI.get('api/beneficiarios/lista-endereco');
      const dadosE = await SolusAPI.get('api/settings/estados');
      const dadosC = await SolusAPI.post('api/settings/cidades', {
        estado:
          dadosEnd.data?.length > 0
            ? dadosEnd.data[0].estado
            : dadosE.data[0].codigo,
      });
      const dadosL = await SolusAPI.get('api/settings/lista-logradouro');
      setState({
        ...state,
        loading: false,
        dadosEndereco: dadosEnd.data?.length > 0 ? dadosEnd.data[0] : [],
        cep: dadosEnd.data?.length > 0 ? dadosEnd.data[0].cep : '',
        tipoLogradouro: {
          selecionado:
            dadosEnd.data?.length > 0
              ? dadosEnd.data[0].id_tipo_logradouro
              : '',
          descricao: dadosL.data,
        },
        logradouro:
          dadosEnd.data?.length > 0 ? dadosEnd.data[0].logradouro : '',
        complemento:
          dadosEnd.data?.length > 0 ? dadosEnd.data[0].complemento : '',

        numero: dadosEnd.data?.length > 0 ? dadosEnd.data[0].numero : '',

        bairro: dadosEnd.data?.length > 0 ? dadosEnd.data[0].bairro : '',
        estados: {
          selecionado: dadosEnd.data?.length > 0 ? dadosEnd.data[0].estado : 0,
          descricao: dadosE.data,
        },
        cidades: {
          selecionado: dadosEnd.data?.length > 0 ? dadosEnd.data[0].cidade : 0,
          descricao: dadosC.data,
        },
      });
    };
    init();
  }, []);

  setMapping({
    ...getMapping(),
    getMappingForm,
    getFormValues,
  });

  const handleCloseModal = (reloadPage) => {
    webContext.showModal({
      open: false,
    });

    reloadPage && window.location.reload();
  };

  const changeEstado = async (e, estados, tipoLog) => {
    const { cep, tipoLogradouro, logradouro, numero, complemento, bairro } =
      getFormValues();

    const cidades = await SolusAPI.post('api/settings/cidades', {
      estado: e.value,
    });

    setState({
      ...state,
      cep,
      tipoLogradouro: {
        selecionado: tipoLogradouro,
        descricao: tipoLog.descricao,
      },
      logradouro,
      numero,
      complemento,
      bairro,
      estados: { selecionado: e.value, descricao: estados },
      cidades: { selecionado: 0, descricao: cidades.data },
    });
  };

  const handleClick = async () => {
    const {
      cep,
      tipoLogradouro,
      logradouro,
      numero,
      complemento,
      bairro,
      estado,
      cidade,
    } = getFormValues();
    let success = true;
    Object.values(getMapping().getMappingForm()()).forEach((item) => {
      if (item.values === '' && item.self.elem_id !== 'complemento') {
        item.setError({
          is: true,
          helperText: 'Preenchimento obrigatório',
        });
        success = false;
      }
    });
    if (success) {
      const dados = await SolusAPI.post(
        'api/beneficiarios/cancelar-boleto-digital',
        {
          cep,
          tipoLogradouro: tipoLogradouro.key,
          logradouro,
          numero,
          complemento,
          bairro,
          estado,
          cidade,
          idEndereco: state.dadosEndereco.id_endereco
            ? state.dadosEndereco.id_endereco
            : '',
          tipoEndereco: state.dadosEndereco.tipo_endereco
            ? state.dadosEndereco.tipo_endereco
            : '',
          principal: state.dadosEndereco.principal
            ? state.dadosEndereco.principal
            : '',
        },
      );

      if (dados.success === true) {
        webContext.showModal({
          open: true,
          renderBody: (
            <>
              <ContainerWarning>
                <BodyInfo
                  icon={<CheckCircleIcon color="primary" />}
                  title="Cancelamento realizado com sucesso!"
                  body="Sua próxima fatura será entregue no endereço fornecido."
                  noDivider
                />
                <ActionButtons direction="flex-end">
                  <Button
                    text="Concluir"
                    variant="contained"
                    color="secondary"
                    endIcon={<CheckIcon />}
                    fullWidth={false}
                    onClick={() => handleCloseModal(true)}
                  />
                </ActionButtons>
              </ContainerWarning>
            </>
          ),
        });
      } else {
        webContext.showModal({
          open: true,
          renderBody: (
            <>
              <ContainerWarning>
                <BodyInfo
                  icon={<WarningIcon color="error" />}
                  title="Opss..."
                  body={
                    dados.data.message
                      ? dados.data.message
                      : 'Ocorreu um erro no cancelamento do boleto digital, tente novamente mais tarde.'
                  }
                  noDivider
                />
                <ActionButtons direction="flex-end">
                  <Button
                    text="Fechar"
                    variant="contained"
                    color="secondary"
                    startIcon={<CloseIcon />}
                    fullWidth={false}
                    onClick={() => handleCloseModal(webContext)}
                  />
                </ActionButtons>
              </ContainerWarning>
            </>
          ),
        });
      }
    }
  };

  return (
    <>
      <BodyInfo
        icon={<TodayIcon />}
        title="Cancelar boleto digital"
        body="Preencha os dados de endereço abaixo para realizar o cancelamento do boleto digital"
      />
      {state.loading === false ? (
        <Form
          settings={renderForm(
            state.cep,
            state.tipoLogradouro,
            state.logradouro,
            state.numero,
            state.complemento,
            state.bairro,
            state.cidades,
            state.estados,
            changeEstado,
            handleCloseModal,
            handleClick,
          )}
          setValues={setFormValues}
          setMappingForm={setMappingForm}
        />
      ) : (
        <CircularIndeterminateModal />
      )}
    </>
  );
}
