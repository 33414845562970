import React from 'react';

import CircularIndeterminate from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import SolusAPI from '../../services/SolusApi';
import { Container, Title } from './styles';

export default function PaymentConfirm() {
  const [state, setState] = React.useState({
    inLoading: true,
    message: '',
  });

  const getParams = (url) => {
    const params = {};
    const parser = document.createElement('a');
    parser.href = url;
    const query = parser.search.substring(1);
    const vars = query.split('&');
    for (let i of vars) {
      const pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  };

  React.useEffect(() => {
    const getFromApi = async () => {
      const { produtoID } = getParams(window.location.href);

      const dados = await SolusAPI.post(
        'api/documentos/verificaPagamentoBaixa',
        {
          produtoID,
        },
      );

      let message;
      if (dados.success) {
        if (dados.data.erroBaixa) {
          message =
            'Estamos validando a transação, em breve sua mensalidade será baixada';
        } else if (
          dados.data.summary.paymentSuccess &&
          dados.data.summary.paymentAuthorized
        ) {
          message =
            'Mensalidade baixada com sucesso, obrigado por utilizar o nosso pagador online!';
        } else {
          message =
            'Pagamento não foi autorizado pela operadora do cartão de débito.';
        }
      } else {
        message =
          'Não foi possível validar o pagamento, tente novamente mais tarde!';
      }
      setState({
        inLoading: false,
        message,
      });
    };
    getFromApi();
  }, []);

  if (state.inLoading) {
    return (
      <Container>
        <CircularIndeterminate />
      </Container>
    );
  }

  return (
    <Container>
      <Title>{state.message}</Title>
    </Container>
  );
}
