/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import { useHistory } from '@solussaude/core/Router';

import {
  BuildIcon,
  PaymentIcon,
  AssignmentLateIcon,
  CallMadeIcon,
  DateRangeIcon,
  HighlightOffIcon,
  ReceiptIcon,
} from '@solussaude/core/Icons';
import Grid from '@solussaude/core/Grid';
import Page from '@solussaude/core/Surfaces/Page';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import ButtonInfo from '@solussaude/core/Inputs/ButtonInfo';
import WebContext from '@solussaude/core/WebContext';
import { isPromise } from '@solussaude/core/Utils';
import SolusAPI from '../../services/SolusApi';
import VencimentoMensalidade from './VencimentoMensalidade';
import CancelamentoBoletoDigital from './CancelamentoBoletoDigital';
import AdesaoBoletoDigital from './AdesaoBoletoDigital';
import SolicitacaoProtocoloReembolso from './SolicitacaoProtocoloReembolso';

export default function Servicos(props) {
  const webContext = React.useContext(WebContext);
  const [state, setState] = React.useState({
    loading: true,
    apiData: [],
    relacaoAtendimento: true,
    segundaViaCarteirinha: true,
    usaCRM: false,
  });

  const history = useHistory();
  const { module } = webContext;

  React.useEffect(() => {
    const init = async () => {
      const userAuth = await isPromise(module.beneficiario.userAuth);
      const relacaoAtendimento = await SolusAPI.get(
        'api/settings/atendimentos',
      );
      const segundaViaCarteirinha = await SolusAPI.get(
        'api/settings/segunda-via-carteira',
      );
      const usaCRM = await SolusAPI.get('api/solus/v1/parametros/UTILIZA_CRM');

      setState({
        loading: false,
        apiData: userAuth,
        relacaoAtendimento: relacaoAtendimento.data === 'S',
        segundaViaCarteirinha: segundaViaCarteirinha.data === 'S',
        usaCRM: usaCRM.data.valor === 'S',
      });
    };
    init();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item sm={12} md={12}>
          <Page icon={BuildIcon} title="Serviços">
            <BodyInfo
              title="Solicitações"
              body="Você pode solicitar alguns serviços pelo portal, segue as opções abaixo."
            />
            {!state.apiData.natureza_juridica ||
            state.apiData.natureza_juridica === 'PJ' ? null : (
              <>
                {state.segundaViaCarteirinha ? (
                  <ButtonInfo
                    title="Solicitar 2º via carteirinha"
                    icon={<PaymentIcon />}
                    link={`${localStorage.getItem(
                      'basename',
                    )}/servicos/carteirinha`}
                  />
                ) : null}
                <ButtonInfo
                  title="Solicitar cancelamento"
                  icon={<AssignmentLateIcon />}
                  link={`${localStorage.getItem(
                    'basename',
                  )}/servicos/cancelamento-contrato`}
                />
                {state.loading === false &&
                state.apiData.boleto_digital === 'N' ? (
                  <ButtonInfo
                    title="Adesão boleto digital"
                    icon={<ReceiptIcon />}
                    renderModal={<AdesaoBoletoDigital />}
                  />
                ) : (
                  <ButtonInfo
                    title="Cancelamento boleto digital"
                    icon={<HighlightOffIcon />}
                    renderModal={<CancelamentoBoletoDigital />}
                  />
                )}
              </>
            )}
            {/* {validar regra de negócio} */}
            {(state.apiData.natureza_juridica === 'PF' ||
              state.apiData.natureza_juridica === 'PFF') && (
              <ButtonInfo
                title="Alteração da data de vencimento da mensalidade"
                icon={<DateRangeIcon />}
                renderModal={<VencimentoMensalidade />}
              />
            )}

            {state.usaCRM && (
              <ButtonInfo
                title="Solicitação de reembolso"
                icon={<AssignmentLateIcon />}
                renderModal={
                  <SolicitacaoProtocoloReembolso history={history} />
                }
              />
            )}

            {!state.apiData.natureza_juridica ||
            state.apiData.natureza_juridica === 'PJ' ? null : (
              <>
                {state.relacaoAtendimento ? (
                  <>
                    <BodyInfo
                      title="Contato"
                      body="Precisa de ajuda? Entre em contato com a gente."
                    />
                    <ButtonInfo
                      title="Canais de atendimento"
                      icon={<CallMadeIcon />}
                      link={`${localStorage.getItem(
                        'basename',
                      )}/servicos/canais-atendimento`}
                    />
                  </>
                ) : null}
              </>
            )}
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
