import styled from 'styled-components';

const defaultDirection = 'flex-end';

export const ContentBody = styled.div`
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media(max-width: 600){
    width: unset;
  }
`;

export const ContentLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionButtons = styled.div`
  padding-top: 10px;
  justify-content: ${props =>
    props.direction !== undefined ? props.direction : defaultDirection};
  display: flex;
  flex: 1;

  .MuiStepper-horizontal {
    padding-bottom: 0px;
  }
`;

export const BodyDescription = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  color: #686868;

  p {
    font-size: 18px;
  }

  b {
    color: #000;
  }
`;

export const IconAlign = styled.div`
  display: flex;
  align-items: center;

  span {
    padding-left: 5px;
  }
`;
export const Container = styled.div`
  padding-bottom: 15px;
  p,
  span {
    /* .MuiTypography-body1 { */
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    /* } */
  }
`;

export const ContentCancelamento = styled.div`
  @keyframes widthToMax {
    0% {
      width: 300px;
    }
    100% {
      width: 500px;
    }
  }

  animation: 0.2s ease-out 0s 1 widthToMax;
  margin: 0px 15px;
  width: 500px;
`;
