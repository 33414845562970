/* eslint-disable react-hooks/exhaustive-deps */

import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import {
  EventIcon,
  InfoIcon,
  AssignmentIcon,
  MonetizationOnIcon,
  ShowChartIcon,
  AccountCircleIcon,
} from '@solussaude/core/Icons';
import { InfoContainer } from './styles';
import { dateFormat, retornaStringData } from '@solussaude/core/Utils';
import Table from '@solussaude/core/DataDisplay/TableSimple';

//Planos
export const tableDadosPlano = (apiData) => ({
  table: {
    options: {
      type: 'headerAside',
      small: true,
    },
    data: processDataPlano(apiData || []),
  },
});

const processDataPlano = (apiData) => {
  const array = [];

  if (apiData.length > 0) {
    apiData.forEach((plano) => {
      array.push(
        [
          {
            label: 'Nome',
            value: plano.nome,
          },
        ],
        [
          {
            label: 'Código do plano',
            value: plano.codigo,
          },
        ],
        [
          {
            label: 'Vigência inicial do plano',
            value: dateFormat(plano.vigencia_inicial),
          },
        ],
        [
          {
            label: 'Regulamentação do Plano',
            value: plano.regulamentacao_plano,
          },
        ],
        [
          {
            label: 'Registro do plano na A.N.S',
            value: plano.registro_ans,
          },
        ],
        [
          {
            label: 'Contratação',
            value: plano.natureza,
          },
        ],
      );
    });
  }

  return array;
};

//Reajuste
export const tableReajusteTabelaPreco = (apiData, naturezaContrato) => ({
  table: {
    options: {
      editableTable: false,
      description: (
        <>
          {naturezaContrato === 'PF' ? (
            <p>
              Os valores da tabela de preços são reajustados, no mínimo, uma vez
              ao ano. O índice/percentual aplicado é estabelecido pela ANS e,
              portanto, a Operadora pode aplicar, no máximo, o valor autorizado.
              A data efetiva da aplicação do reajuste pode variar, pois a
              agência reguladora emite o comunicado oficial após o mês de Maio,
              mês base para o cálculo.
            </p>
          ) : (
            <p>
              Os valores da tabela de preços são reajustados, no mínimo, uma vez
              ao ano. O índice/percentual é aplicado com base na legislação
              vigente.
            </p>
          )}

          <br />
          <BodyInfo
            icon={<InfoIcon color="primary" />}
            title="Informações da tabela"
            noDivider
            body={
              <InfoContainer>
                <Table
                  error="Não há dados do plano para mostrar!"
                  settings={tableInfoReajuste(apiData)}
                />
              </InfoContainer>
            }
          />
        </>
      ),
      pagination: true,
      pageSize: 5,
    },
    columns: [
      {
        title: 'Vigência',
        field: 'vigencia',
        icon: <EventIcon color="primary" />,
      },
      {
        title: 'Percentual',
        field: 'percentual',
        icon: <ShowChartIcon color="primary" />,
      },
      {
        title: 'Motivo',
        field: 'motivo',
        icon: <AssignmentIcon color="primary" />,
      },
    ],
    data: processDataReajusteTabelaPreco(apiData || []),
  },
});

const processDataReajusteTabelaPreco = (apiData) => {
  const array = [];

  if (apiData) {
    if (apiData.historicoReajuste && apiData.historicoReajuste.length > 0) {
      apiData.historicoReajuste.forEach((reajuste) => {
        array.push({
          vigencia: retornaStringData(reajuste.dataVigencia),
          percentual: reajuste.percentual + '%',
          motivo: reajuste.motivoReajuste,
        });
      });
    }
  }

  return array;
};

export const tableInfoReajuste = (apiData) => ({
  table: {
    options: {
      type: 'headerAside',
      small: true,
    },
    data: processInfoReajuste(apiData || []),
  },
});

const processInfoReajuste = (apiData) => {
  const array = [];

  if (apiData) {
    array.push(
      [
        {
          label: 'Nome da tabela',
          value: apiData.nomeTabela,
        },
      ],
      [
        {
          label: 'Tipo de tabela de preço',
          value: apiData.tipoTabela,
        },
      ],
      [
        {
          label: 'Mês de reajuste',
          value: apiData.mesReajusteExtenso,
        },
      ],
      [
        {
          label: 'Ano de comercialização',
          value: apiData.anoComercializacao,
        },
      ],
    );
  }

  return array;
};

//Faixa etária
export const tableReajusteFaixaEtaria = (apiData) => ({
  table: {
    options: {
      editableTable: false,
      description: (
        <>
          <br />
          <BodyInfo
            icon={<AccountCircleIcon color="primary" />}
            title="Reajuste por faixa etária"
            noDivider
            body={
              <InfoContainer>
                <p>
                  O reajuste por faixa etária acontece quando o beneficiário
                  troca de valores dentro da mesma tabela.
                </p>
              </InfoContainer>
            }
          />
        </>
      ),
    },
    columns: [
      {
        title: 'Vencimento',
        field: 'vencimento',
        icon: <EventIcon color="primary" />,
      },
      {
        title: 'Percentual',
        field: 'percentual',
        icon: <ShowChartIcon color="primary" />,
      },
    ],
    data: processReajusteFaixaEtaria(apiData || []),
  },
});

const processReajusteFaixaEtaria = (apiData) => {
  const array = [];

  if (apiData) {
    if (apiData.length > 0) {
      apiData?.forEach((dadosPlano) => {
        array.push({
          vencimento: retornaStringData(dadosPlano.data),
          percentual: dadosPlano.percentual + '%',
        });
      });
    }
  }

  return array;
};

//Histórico de Planos
export const tableHistoricoPlanos = (apiData) => ({
  table: {
    options: {
      editableTable: false,
      description: (
        <p>
          Abaixo está o histórico de planos que você possui na operadora! Você
          pode consultar dados simplicados dos planos e tabelas de preços.
          Qualquer dúvida, entre em contato conosco para esclarecimento.
        </p>
      ),
    },
    columns: [
      {
        title: 'Data da Troca',
        field: 'dataTroca',
        icon: <EventIcon color="primary" />,
      },
      {
        title: 'Plano Novo',
        field: 'planoNovo',
        icon: <InfoIcon color="primary" />,
      },
      {
        title: 'Tabela Nova',
        field: 'tabelaNova',
        icon: <MonetizationOnIcon color="primary" />,
      },
      {
        title: 'Plano Antigo',
        field: 'planoAntigo',
        icon: <InfoIcon color="primary" />,
      },
      {
        title: 'Tabela Antiga',
        field: 'tabelaAntiga',
        icon: <MonetizationOnIcon color="primary" />,
      },
      {
        title: 'Motivo',
        field: 'motivoTroca',
        icon: <AssignmentIcon color="primary" />,
      },
    ],
    data: processHistoricoPlano(apiData || []),
  },
});

const processHistoricoPlano = (apiData) => {
  const array = [];

  if (apiData) {
    if (apiData.length > 0) {
      apiData.forEach((historicoPlano) => {
        array.push({
          dataTroca: retornaStringData(historicoPlano.novaVigencia),
          planoNovo: historicoPlano.dadosPlanoAtual.nome,
          tabelaNova: historicoPlano.dadosTabelaAtual.nomeTabela,
          planoAntigo: historicoPlano.dadosPlanoAnterior?.nome,
          tabelaAntiga: historicoPlano.dadosTabelaAnterior?.nomeTabela,
          motivoTroca: historicoPlano.tipoMudanca,
        });
      });
    }
  }

  return array;
};
