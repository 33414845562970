import React from 'react';

import createMuiTheme from '@solussaude/core/styles';
import ThemeProvider from '@solussaude/core/Templates/ThemeProvider';
import Props from '@solussaude/core/Utils/Props';
import SolusAPI from './services/SolusApi';
import GlobalStyled from './styles/global';
import Routes from './Routes';

export default function App() {
  const [state, setState] = React.useState({
    inLoading: true,

    theme: undefined,
  });

  React.useEffect(() => {
    const getFromAPI = async () => {
      const dadosFromAPI = await SolusAPI.get('api/settings/webcolors');

      const theme = dadosFromAPI.data ? createMuiTheme(dadosFromAPI.data) : '';

      setState({
        inLoading: false,
        theme,
      });
    };

    getFromAPI();
  }, []);

  if (state.inLoading) return <></>;

  if (!state.theme) return <p>Nenhuma configuração encontrada</p>;

  return (
    <ThemeProvider theme={state.theme}>
      <GlobalStyled theme={state.theme} />

      <Props>
        <Routes />
      </Props>
    </ThemeProvider>
  );
}
