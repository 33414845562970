import styled from 'styled-components';

export const Container = styled.div`
  width: 500px;

  overflow: hidden;
  @media(max-width: 600px){
    width: 100%;
  }
`;

export const ActionButtons = styled.div`
  padding-top: 10px;
  justify-content: ${props => (props.direction ? props.direction : 'flex-end')};
  display: flex;
  flex: 1;

  .MuiStepper-horizontal {
    padding-bottom: 0px;
  }
`;
