export const temp = {};
export const renderForm = {
  form: [
    {
      label: 'Nome do form',
      inputs: [
        [
          // Row
          {
            // Column
            elem_id: 'dataInicio',
            type: 'InputText',
            shrink: true,
            typeInputText: 'date',
            label: 'Período entre',
            required: true,
          },
          {
            // Column
            elem_id: 'dataFim',
            type: 'InputText',
            typeInputText: 'date',
            shrink: true,
            required: true,
          },
        ],
      ],
    },
  ],
};
