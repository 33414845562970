/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import {
  DescriptionIcon,
  EventIcon,
  InfoIcon,
  AssignmentIcon,
  MonetizationOnIcon,
  ShowChartIcon,
} from '@solussaude/core/Icons';
import { dateFormat, retornaStringData } from '@solussaude/core/Utils';
import Table from '@solussaude/core/DataDisplay/TableSimple';
import SolusAPI from '../../../services/SolusApi';

const processDataPlano = (apiData) => {
  const array = [];

  if (apiData.length > 0) {
    apiData.forEach((plano) => {
      array.push(
        [
          {
            label: 'Nome',
            value: plano.nome,
          },
        ],
        [
          {
            label: 'Nº registro plano',
            value: plano.codigo,
          },
        ],
        [
          {
            label: 'Vigência inicial do plano',
            value: dateFormat(plano.vigencia_inicial),
          },
        ],
        [
          {
            label: 'Regulamentação do Plano',
            value: plano.regulamentacao_plano,
          },
        ],
        [
          {
            label: 'Registro da operadora A.N.S',
            value: plano.registro_ans,
          },
        ],
        [
          {
            label: 'Contratação',
            value: plano.natureza,
          },
        ],
      );
    });
  }

  return array;
};

export const dadosPlano = (apiData) => ({
  table: {
    options: {
      type: 'headerAside',
      small: true,
    },
    data: processDataPlano(apiData || []),
  },
});

const processDataCarencias = (apiData) => {
  const array = [];

  if (apiData.length > 0) {
    apiData.forEach((carencia) => {
      array.push({
        descricao: carencia.descricao,
        fimCarencia: carencia.vencimento ? dateFormat(carencia.vencimento) : '',
        status: carencia.status,
        id: carencia.id,
      });
    });
  }

  return array;
};

const procedimentosCarencia = (apiData) => {
  const array = [];
  if (apiData) {
    if (apiData.length > 0) {
      apiData.forEach((procedimento) => {
        array.push({
          codigo: procedimento.codigo,
          procedimento: procedimento.descricao,
        });
      });
    }
  }

  return array;
};

const tableProcedimentos = (apiData) => ({
  table: {
    options: {
      type: 'headerTop',
      small: true,
      convetTo: true,
    },
    headers: [
      {
        label: 'Codigo',
        icon: <InfoIcon color="primary" />,
      },
      {
        label: 'Procedimento',
        icon: <AssignmentIcon color="primary" />,
      },
    ],
    data: procedimentosCarencia(apiData || []),
  },
});

const Detail = ({ id }) => {
  const [state, setState] = React.useState({
    loading: true,
    apiData: [],
    successApi: true,
  });

  React.useEffect(() => {
    const init = async () => {
      const dados = await SolusAPI.post(
        'api/beneficiarios/procedimentos-carencia',
        {
          id,
        },
      );

      setState({
        ...state,
        loading: false,
        apiData: dados.data,
        successApi: dados.success,
      });
    };

    init();
  }, []);

  return (
    <>
      <BodyInfo
        icon={<AssignmentIcon color="primary" />}
        title="Lista de carências"
        body="Abaixo estão os procedimentos da carência."
        noDivider
      />
      <Table
        settings={tableProcedimentos(state.apiData)}
        inLoading={state.loading}
      />
    </>
  );
};

export const dadosCarencia = (apiData) => ({
  table: {
    options: {
      editableTable: false,
      description: (
        <p>
          Abaixo estão as descrições e os respectivos prazos de carência com seu
          devido status para realizar as operações!
        </p>
      ),
      detailPanel: (rowData) => <Detail id={rowData.id} />,
    },
    columns: [
      {
        title: 'Descrição',
        field: 'descricao',
        icon: <DescriptionIcon color="primary" />,
      },
      {
        title: 'Fim da carência',
        field: 'fimCarencia',
        icon: <EventIcon color="primary" />,
      },
      {
        title: 'Status',
        field: 'status',
        icon: <InfoIcon color="primary" />,
      },
    ],
    data: processDataCarencias(apiData || []),
  },
});

const processDataReajuste = (apiData) => {
  const array = [];

  if (apiData) {
    if (apiData.length > 0) {
      apiData.forEach((reajuste) => {
        array.push({
          vencimento: retornaStringData(reajuste.vencimento),
          valorMensalidade: reajuste.valorMensalidade,
          percentualReajuste: reajuste.percentualReajuste,
          motivoReajuste: reajuste.motivoReajuste,
        });
      });
    }
  }

  return array;
};

export const dadosReajuste = (apiData) => ({
  table: {
    options: {
      editableTable: false,
      description: <p>Abaixo estão as informações sobre os reajustes!</p>,
      pagination: true,
      search: true,
      pageSize: 5,
    },
    columns: [
      {
        title: 'Vencimento',
        field: 'vencimento',
        icon: <EventIcon color="primary" />,
      },
      {
        title: 'Mensalidade',
        field: 'ValorMensalidade',
        icon: <MonetizationOnIcon color="primary" />,
      },
      {
        title: 'Percentual',
        field: 'percentualReajuste',
        icon: <ShowChartIcon color="primary" />,
      },
      {
        title: 'Motivo',
        field: 'motivoReajuste',
        icon: <AssignmentIcon color="primary" />,
      },
    ],
    data: processDataReajuste(apiData || []),
  },
});
