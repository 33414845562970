/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import {
  LocalAtmIcon,
  PaymentIcon,
  FormatAlignLeftIcon,
  ReceiptIcon,
  WarningIcon,
  FilterListIcon,
} from '@solussaude/core/Icons';

import Grid from '@solussaude/core/Grid';
import Divider from '@solussaude/core/Divider';
import Page from '@solussaude/core/Surfaces/Page';
import TableCRUD from '@solussaude/core/DataDisplay/TableCRUD';
import Button from '@solussaude/core/Inputs/Button';
import Payments from '@solussaude/core/Payments';

import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import Drawer from '@solussaude/core/Navigation/Drawer';
import Form from '@solussaude/core/CustomForm';
import ButtonInfo from '@solussaude/core/Inputs/ButtonInfo';
import WebContext from '@solussaude/core/WebContext';
import { isPromise } from '@solussaude/core/Utils';
import SolusAPI from '../../services/SolusApi';

import defaultTheme from '../../styles/themes/default';
import DemonstrativoIR from '../Relatorio/DemonstrativoIR';
import DemonstrativoQuitacao from '../Relatorio/DemonstrativoQuitacao';

import { renderTable, renderDrawer, renderFilter } from './settings';
import { ActionButtons, SpaceItems, ContainerDetail, Fab } from './styles';

export default function Documents() {
  const theme = defaultTheme;
  const context = React.useContext(WebContext);
  const { showModal, module } = context;
  const [userData, setUsuario] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const [mensalidades, setMensalidades] = useState({
    successApi: true,
    apiData: [],
  });
  const [filtros, setFiltros] = useState({
    situacao: '',
    ano: 0,
  });

  const [state, setState] = React.useState({
    loading: true,
    render: null,
    reload: false,
    demonstrativoIR: false,
    demonstrativoQuitacao: false,
  });

  useEffect(() => {
    async function initial() {
      const userAuth = await isPromise(module.beneficiario.userAuth);
      setUsuario(userAuth);
    }
    initial();
  }, []);

  const calculaDatasFilter = () => {
    const dataInicial =
      filtros.ano && filtros.ano > 0 ? `01/01/${filtros.ano}` : '01/01/1900';

    const dataFinal =
      filtros.ano && filtros.ano > 0 ? `01/12/${filtros.ano}` : '01/01/2100';

    return {
      dataInicial,
      dataFinal,
    };
  };

  useEffect(() => {
    async function initial() {
      const userAuth = await isPromise(module.beneficiario.userAuth);

      const { dataInicial, dataFinal } = calculaDatasFilter();
      const dados = await SolusAPI.post('api/beneficiarios/contasReceber', {
        regInicial: 0,
        regFinal: 15,
        dataInicial,
        dataFinal,
        contrato: userAuth.id_contrato,
        status: filtros.situacao || 'A',
      });

      let newData = [];

      if (dados.data !== '') {
        // eslint-disable-next-line no-unused-vars
        newData = dados.data.filter((element, index, array) => index <= 14);
      }

      setMensalidades({ successApi: dados.success, apiData: newData });
      setOpenModal(false);
    }
    initial();
  }, [filtros]);

  const processPayment = () => {
    showModal({
      open: true,
      renderBody: <Payments />,
    });
  };

  let valuesFilter;
  const setValuesForm = (newValue) => {
    valuesFilter = newValue;
  };

  const renderBodyTable = () => {
    if (state.loading) {
      return (
        <center>
          <CircularIndeterminateModal />
        </center>
      );
    }

    return (
      <ContainerDetail>
        <TableCRUD
          settings={renderTable(mensalidades.apiData)}
          inloading={state.loading}
        />

        {userData.pagamento_cartao === 'S' && (
          <ActionButtons>
            <Button
              text="Pagar boletos"
              variant="contained"
              color="primary"
              startIcon={<PaymentIcon />}
              fullWidth={false}
              onClick={() => processPayment()}
            />
          </ActionButtons>
        )}
      </ContainerDetail>
    );
  };

  const renderDefaultError = (
    <>
      <BodyInfo
        icon={<WarningIcon color="error" />}
        title="Opss..."
        body="Não foi possivel carregar seus boletos, tente novamente mais tarde."
        noDivider
      />
    </>
  );

  const handleClose = () => {
    setOpenModal(false);
  };

  const onClickListar = () => {
    const { situacao, ano } = valuesFilter;
    setFiltros({ situacao, ano });
    handleClose();
  };

  React.useEffect(() => {
    const getFromAPI = async () => {
      if (!state.loading) {
        setState((old) => ({ ...old, loading: true }));
      }

      const { data } = await SolusAPI.get('api/recupera-dados-config');

      setState((old) => ({
        ...old,
        loading: false,
        demonstrativoIR: data !== undefined && data.demonstrativo_irpf === 'S',
        demonstrativoQuitacao:
          data !== undefined && data.demonstrativo_quitacao === 'S',
      }));
    };

    getFromAPI();
  }, []);

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page icon={LocalAtmIcon} title="Boletos">
            <Grid item sm={12} md={12}>
              {/* { userData.natureza_juridica === "PJ" ? null : ( */}
              <>
                <SpaceItems>
                  <BodyInfo
                    title="Imposto de renda"
                    body="As opções para demonstrativos já estão disponíveis."
                  />

                  {state.demonstrativoIR ? (
                    <ButtonInfo
                      title="Demonstrativo para I.R"
                      icon={<ReceiptIcon />}
                      renderModal={<DemonstrativoIR />}
                    />
                  ) : null}
                  {state.demonstrativoQuitacao ? (
                    <ButtonInfo
                      title="Demonstrativo de quitação débitos"
                      icon={<FormatAlignLeftIcon />}
                      renderModal={<DemonstrativoQuitacao />}
                    />
                  ) : null}
                </SpaceItems>
                <Divider />
              </>
              {mensalidades.successApi ? renderBodyTable() : renderDefaultError}
            </Grid>
          </Page>
        </Grid>
      </Grid>
      {state.loading ? null : (
        <>
          <Fab
            onClick={() => {
              setOpenModal(true);
            }}
            color="primary"
          >
            <FilterListIcon />
          </Fab>
          <Drawer
            open={openModal}
            settings={renderDrawer}
            onClose={handleClose}
          >
            <BodyInfo title="Busca avançada" icon={<FilterListIcon />} />
            <Form
              settings={renderFilter({ onClickListar })}
              setValues={setValuesForm}
            />
          </Drawer>
        </>
      )}
    </>
  );
}
