/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import React from 'react';

import Grid from '@solussaude/core/Grid';
import Typography from '@solussaude/core/Typography';
import WebContext from '@solussaude/core/WebContext';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import Form from '@solussaude/core/CustomForm';
import Button from '@solussaude/core/Inputs/Button';
import MensConfirmacao from '@solussaude/core/Helpers/MensConfirmacao';
import { isPromise } from '@solussaude/core/Utils';
import {
  SendIcon,
  CloseIcon,
  CheckIcon,
  WarningIcon,
} from '@solussaude/core/Icons';
import SolusAPI from '../../../services/SolusApi';
import { renderFormSolicita, renderFormTipoChamado } from './settings';
import { ContentSolicitacao, ActionButtons } from './styles';

const handleCloseModal = (context) => {
  context.showModal({
    open: false,
  });
};

export const SolicitaChamado = () => {
  const webContext = React.useContext(WebContext);
  const [state, setState] = React.useState({
    loading: true,
    renderRetorno: false,
    verificaChamado: true,
    renderSolicitaChamado: false,
    renderErro: false,
    tipo: 0,
    nroProtocolo: '',
    titulo: '',
    assunto: '',
    classificacao: { selecionado: 0, descricaco: [] },
    subclassificacao: { selecionado: 0, descricaco: [] },
    pessoaContato: '',
    telefoneContato: '',
    email: '',
    mensagem: '',
  });

  let formValues;
  const getFormValues = () => formValues;
  const setFormValues = (newValue) => {
    formValues = newValue;
  };

  let mapping;
  const getMapping = () => mapping;
  const setMapping = (newMapping) => {
    mapping = newMapping;
  };

  let mappingForm;
  const getMappingForm = () => mappingForm;
  const setMappingForm = (pGetMapping) => {
    mappingForm = pGetMapping;
  };

  React.useEffect(() => {
    const init = () => {
      setState({
        ...state,
        loading: false,
        renderRetorno: false,
        verificaChamado: true,
        renderSolicitaChamado: false,
      });
    };
    init();
  }, []);

  setMapping({
    ...getMapping(),
    getMappingForm,
    getFormValues,
  });

  const processaSolicitacao = async () => {
    const {
      protocolo,
      assunto,
      titulo,
      classificacao,
      subclassificacao,
      pessoaContato,
      telefoneContato,
      email,
    } = getFormValues();

    let success = true;
    Object.values(getMapping().getMappingForm()()).map((item) => {
      if (item.values === '') {
        item.setError({
          is: true,
          helperText: 'Preenchimento obrigatório',
        });
        success = false;
      }
    });
    if (success) {
      const temp = `Pessoa para contato: ${pessoaContato} Email: ${email} Telefone: ${telefoneContato}  Mensagem: ${assunto} `;
      const userAuth = await isPromise(webContext.module.beneficiario.userAuth);

      const dados = await SolusAPI.post('api/beneficiarios/insere-protocolo', {
        titulo,
        assunto: temp,
        protocolo,
        id: userAuth.id,
        origem: state.tipo === 1 ? 'V' : 'G',
        data: new Date().toLocaleDateString(),
        classificacao,
        subclassificacao,
        pessoaContato,
        telefoneContato,
        email,
        tipo: 'U',
      });

      setState({
        ...state,
        loading: true,
      });

      setTimeout(() => {
        if (Object.keys(dados.data).length === 0) {
          setState({ ...state, loading: false, renderRetorno: true });
        } else {
          setState({
            ...state,
            loading: false,
            renderSolicitaChamado: false,
            renderErro: true,
          });
        }
      }, 1500);
    }
  };

  const renderErro = () => (
    <>
      <MensConfirmacao
        title="Erro"
        IconTitle={<WarningIcon color="error" />}
        description={<p>Ops... tente novamente mais tarde</p>}
        handleClick={() => handleCloseModal(webContext)}
      />
    </>
  );
  const processaTipo = async () => {
    const dadosC = await SolusAPI.post(
      'api/settings/classificacao-atendimento',
      {
        tipo: state.tipo === 0 ? 'G' : 'V',
      },
    );
    const dadosS = await SolusAPI.post(
      'api/settings/subclassificacao-atendimento',
      {
        tipo: state.tipo === 0 ? 'G' : 'V',
        classificacao:
          dadosC.data?.length > 0 ? dadosC.data[0].id_classificacao : '',
      },
    );

    if (dadosC.data?.length === 0) {
      setState({
        ...state,
        loading: false,
        verificaChamado: false,
        renderErro: true,
        renderSolicitaChamado: false,
      });
    } else {
      setState({
        ...state,
        classificacao: { selecionado: 0, descricao: dadosC.data },
        subclassificacao: { selecionado: 0, descricao: dadosS.data },
        loading: false,
        verificaChamado: false,
        renderSolicitaChamado: true,
      });
    }
  };

  const changeClassificacao = async (e) => {
    const dadosC = await SolusAPI.post(
      'api/settings/subclassificacao-atendimento',
      {
        tipo: state.tipo,
        classificacao: e.value,
      },
    );

    const {
      protocolo,
      assunto,
      titulo,
      pessoaContato,
      telefoneContato,
      email,
    } = getFormValues();

    setState({
      ...state,
      nroProtocolo: protocolo,
      titulo,
      assunto,
      classificacao: { ...state.classificacao, selecionado: e.value },
      subclassificacao: { ...state.subclassificacao, descricao: dadosC.data },
      pessoaContato,
      telefoneContato,
      email,
      mensagem: assunto,
    });
  };

  const renderLoading = () => <CircularIndeterminateModal />;

  const renderSolicitaChamado = () => (
    <>
      <ContentSolicitacao>
        <Typography variant="h6">Solicitação de chamado</Typography>
        <Form
          settings={renderFormSolicita(
            state.tipo,
            state.nroProtocolo,
            state.titulo,
            state.assunto,
            state.classificacao,
            state.subclassificacao,
            state.pessoaContato,
            state.telefoneContato,
            state.email,
            state.mensagem,
            changeClassificacao,
          )}
          setValues={setFormValues}
          setMappingForm={setMappingForm}
        />
        <br />
        <Grid container>
          <Grid item sm={12} md={6}>
            <ActionButtons direction="flex-start">
              <Button
                text="Fechar"
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon />}
                fullWidth={false}
                onClick={() => handleCloseModal(webContext)}
              />
            </ActionButtons>
          </Grid>
          <Grid item sm={12} md={6}>
            <ActionButtons>
              <Button
                text="Confirmação"
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
                fullWidth={false}
                onClick={() => processaSolicitacao()}
              />
            </ActionButtons>
          </Grid>
        </Grid>
      </ContentSolicitacao>
    </>
  );

  const renderConfirmacao = () => (
    <>
      <MensConfirmacao
        title="Solicitação concluída"
        IconTitle={<CheckIcon color="primary" />}
        description={
          <p>
            Seu chamado foi solicitado com sucesso, agora é só aguardar o
            retorno para a resolução do chamado.
          </p>
        }
        handleClick={() => handleCloseModal(webContext)}
      />
    </>
  );

  const handleTipo = () => {
    const { web_tipoChamado } = getFormValues();

    setState({ ...state, tipo: web_tipoChamado });
  };

  const verificaTipoChamado = () => (
    <>
      <ContentSolicitacao>
        <Typography variant="h6" align="justify">
          Canais de atendimento
        </Typography>
        <Typography variant="subtitle1" align="justify">
          Você sabe quando acionar a Ouvidoria?
        </Typography>
        <br />
        <Typography variant="subtitle1" align="justify">
          A Ouvidoria pode ser acionada quando não se sentir totalmente atendido
          nos outros canais disponibilizados.
        </Typography>
        <Form
          settings={renderFormTipoChamado(handleTipo, state.tipo)}
          setValues={setFormValues}
        />
        <br />
        <Grid container>
          <Grid item sm={12} md={6}>
            <ActionButtons direction="flex-start">
              <Button
                text="Fechar"
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon />}
                fullWidth={false}
                onClick={() => handleCloseModal(webContext)}
              />
            </ActionButtons>
          </Grid>
          <Grid item sm={12} md={6}>
            <ActionButtons>
              <Button
                text="Confirmação"
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
                fullWidth={false}
                onClick={() => {
                  processaTipo();
                }}
              />
            </ActionButtons>
          </Grid>
        </Grid>
      </ContentSolicitacao>
    </>
  );

  if (state.loading) return renderLoading();
  if (state.renderRetorno) return renderConfirmacao();
  if (state.verificaChamado) return verificaTipoChamado();
  if (state.renderSolicitaChamado) return renderSolicitaChamado();
  if (state.renderErro) return renderErro();
  return <></>;
};
