import { SearchIcon } from '@solussaude/core/Icons';

const tipoPessoa = [
  {
    key: 'PF',
    name: 'Física',
  },
  {
    key: 'PJ',
    name: 'Jurídica',
  },
];

export const formDadosInstrumentador = (
  apiData,
  onClick,
  handleTipoPessoa,
) => ({
  form: [
    {
      inputs: [
        [
          // Row
          {
            // Column
            elem_id: 'tipoPessoa',
            elem_val: apiData.tipoPessoa,
            type: 'RadioButton',
            label: 'Tipo de pessoa titular da conta bancária',
            value: tipoPessoa,
            variant: 'outlined',
            onChange: (e) => handleTipoPessoa(e),
            small: true,
            flex: 5,
          },
          // Row
        ],
        [
          {
            // Column
            elem_id: 'nomeInstrumentador',
            elem_val: apiData.nomeInstrumentador,
            label: 'Nome instrumentador',
            type: 'Input',
            autoFocus: true,
            icon: SearchIcon,
            flex: 5,
            required: true,
            variant: 'outlined',
            onClick: () => onClick(),
            // disabled: true,
            small: true,
          },
          apiData.tipoPessoa === 'PF'
            ? {
                elem_id: 'cpf',
                elem_val: apiData.cpf,
                type: 'InputMask',
                label: 'CPF',
                typeMask: 'cpf',
                required: true,
                variant: 'outlined',
                small: true,
              }
            : {
                elem_id: 'cnpj',
                elem_val: apiData.cnpj,
                type: 'InputMask',
                label: 'CNPJ',
                typeMask: 'cnpj',
                required: true,
                variant: 'outlined',
                small: true,
              },
        ],
      ],
    },
  ],
});

const tipoBusca = [
  { key: 'nome', name: 'Nome do prestador' },
  { key: 'nomeFantasia', name: 'Nome fantasia' },
  { key: 'crm', name: 'CRM' },
  { key: 'cpf', name: 'CPF' },
  { key: 'cgc', name: 'CNPJ' },
  { key: 'cidade', name: 'Cidade' },
  { key: 'estado', name: 'Estado' },
  { key: 'rede', name: 'Rede' },
  { key: 'grupo', name: 'Grupo' },
  { key: 'codigo', name: 'Código do prestador' },
];

export const formInstrumentador = (state, changePesquisa) => ({
  form: [
    {
      inputs: [
        [
          {
            // Column
            elem_id: 'tipoBusca',
            elem_val: state.tipoBusca,
            type: 'Select',
            label: 'Escolha uma opção',
            small: true,
            variant: 'outlined',
            value: tipoBusca,
            onChange: () => changePesquisa(),
          },
        ],
        [
          {
            // Column
            elem_id: 'parametro',
            elem_val: state.parametro,
            type: 'InputText',
            label: '',
            variant: 'outlined',
            small: true,
          },
        ],
      ],
    },
  ],
});

const instrumentadores = (apiData) => {
  const array = [];

  if (apiData) {
    if (apiData.length > 0) {
      apiData.forEach((prestador) => {
        array.push({
          codigo: prestador.codigo,
          nome: prestador.nome,
          nomeFantasia: prestador.nomeFantasia,
          cpf: prestador.cpf,
          estadoConselho: prestador.estadoConselho,
          cidade: prestador.cidade,
        });
      });
    }
  }

  return array;
};

export const tableInstrumentadores = (apiData) => ({
  table: {
    options: {
      type: 'headerTop',
      small: true,
      clickable: true,
      pagination: true,
    },
    headers: [
      { label: 'Código' },
      { label: 'Nome' },
      { label: 'Nome fantasia' },
      { label: 'CPF/CNPJ' },
      { label: 'Estado' },
    ],
    data: instrumentadores(apiData),
  },
});
