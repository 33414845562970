import styled from 'styled-components';

export const NoStylesLink = styled.div`
  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;
