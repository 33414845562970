/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';

import {
  FormatAlignLeftIcon,
  DescriptionIcon,
  InfoIcon,
  CalendarTodayIcon,
  CachedIcon,
  AssignmentIcon,
} from '@solussaude/core/Icons';
import Grid from '@solussaude/core/Grid';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import ButtonInfo from '@solussaude/core/Inputs/ButtonInfo';
import Page from '@solussaude/core/Surfaces/Page';
import { isPromise } from '@solussaude/core/Utils';
import WebContext from '@solussaude/core/WebContext';
import SolusAPI from '../../services/SolusApi';

import DeclaracaoCarencia from './DeclaracaoCarencia';
import DeclaracaoPermanencia from './DeclaracaoPermanencia';
import CartaPortabilidade from './CartaPortabilidade';
import UtilizacaoServicos from './UtilizacaoServicos';
import ExtratoAtendimento from './ExtratoAtendimento';

export default function Info() {
  const { module } = React.useContext(WebContext);

  const [state, setState] = React.useState({
    loading: true,
    guiaMedico: true,
    naturezaJuridica: '',
    planos: false,
    carencia: false,
  });

  React.useEffect(() => {
    const init = async () => {
      const userAuth = await isPromise(module.beneficiario.userAuth);
      const { data } = await SolusAPI.get('api/recupera-dados-config');
      const carencia = await SolusAPI.get('api/settings/carencia');

      const guiaMedico = await SolusAPI.get('api/settings/rede');
      setState({
        loading: false,
        guiaMedico: guiaMedico.data === 'S',
        planos: data.planos === 'S',
        naturezaJuridica: userAuth.natureza_juridica,
        carencia: carencia.data === 'S',
      });
    };

    init();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item sm={12} md={12}>
          <Page icon={InfoIcon} title="Informações">
            <Grid item sm={12} md={12}>
              <BodyInfo title="Minhas consultas" />

              <ButtonInfo
                title="Meus protocolos"
                icon={<DescriptionIcon />}
                link={`${localStorage.getItem(
                  'basename',
                )}/info/meus-protocolos`}
              />

              {state.carencia && (
                <ButtonInfo
                  title="Prazos da Carencia"
                  icon={<DescriptionIcon />}
                  link={`${localStorage.getItem(
                    'basename',
                  )}/info/prazo-carencia`}
                />
              )}
              {!state.naturezaJuridica ||
              state.naturezaJuridica === 'PJ' ? null : (
                <ButtonInfo
                  title="Meus comunicados"
                  icon={<DescriptionIcon />}
                  link={`${localStorage.getItem('basename')}/info/comunicados`}
                />
              )}

              {state.guiaMedico ? (
                <ButtonInfo
                  title="Guia médico"
                  icon={<AssignmentIcon />}
                  link={`${localStorage.getItem('basename')}/info/guias-medico`}
                />
              ) : null}
              <ButtonInfo
                title="Consulta de pedidos"
                icon={<AssignmentIcon />}
                link={`${localStorage.getItem(
                  'basename',
                )}/info/consulta-pedido`}
              />
            </Grid>
            <br />
            <>
              <Grid item sm={12} md={12}>
                <BodyInfo title="Relatórios" />
                <ButtonInfo
                  title="Limites de utilização"
                  icon={<FormatAlignLeftIcon />}
                  link={`${localStorage.getItem(
                    'basename',
                  )}/info/limites-utilizacao`}
                />
                {state.planos && (
                  <ButtonInfo
                    title="Relação de planos e produtos"
                    icon={<FormatAlignLeftIcon />}
                    link={`${localStorage.getItem(
                      'basename',
                    )}/info/planos-produtos`}
                  />
                )}
              </Grid>
            </>
            <br />
            <Grid item sm={12} md={12}>
              <BodyInfo title="Extrato" />
              <ButtonInfo
                title="Extrato de atendimentos"
                icon={<AssignmentIcon />}
                renderModal={<ExtratoAtendimento />}
              />
              <ButtonInfo
                title="Componente de utilização de serviços"
                icon={<AssignmentIcon />}
                renderModal={<UtilizacaoServicos />}
              />
            </Grid>
            <br />
            <>
              <Grid item sm={12} md={12}>
                <BodyInfo title="Declarações" />
                <ButtonInfo
                  title="Declaração de carência"
                  icon={<CalendarTodayIcon />}
                  renderModal={<DeclaracaoCarencia />}
                />
                <ButtonInfo
                  title="Declaração de permanência"
                  icon={<CalendarTodayIcon />}
                  renderModal={<DeclaracaoPermanencia />}
                />
              </Grid>
              <br />
            </>
            <Grid item sm={12} md={12}>
              <BodyInfo title="Portabilidade" />
              <ButtonInfo
                title="Carta de portabilidade"
                icon={<CachedIcon />}
                renderModal={<CartaPortabilidade />}
              />
            </Grid>
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
