import React from 'react';
import Button from '@solussaude/core/Inputs/Button';
import { processData } from '@solussaude/core/Utils';
import { CloseIcon, SendIcon } from '@solussaude/core/Icons';

export const temp = {};

export const renderForm = (
  apiData,
  clickTermos,
  handleCloseModal,
  handleClick,
) => ({
  form: [
    {
      inputs: [
        [
          // Row
          {
            elem_id: 'diaVencimento',
            type: 'Select',
            label: 'Dia do vencimento',
            flex: 4,
            value: processData(apiData, 'id_vencimento', 'descricao'),
          },
          {
            elem_id: 'motivo',
            type: 'InputText',
            label: 'Motivo para alteração',
            flex: 8,
          },
          {
            elem_id: 'termos',
            type: 'Checkbox',
            label: (
              <>
                <p>
                  Li e Confirmo as{' '}
                  <Button
                    onClick={() => clickTermos()}
                    text="consequências"
                    color="primary"
                    fullWidth={false}
                  />{' '}
                  da alteração da data de vencimento
                </p>
              </>
            ),
            required: true,
            flex: 10,
          },
        ],
        [
          {
            elem_id: 'btnClose',
            type: 'Button',
            text: 'Fechar',
            fullWidth: true,
            color: 'secondary',
            icon: <CloseIcon />,
            variant: 'outlined',
            onClick: () => handleCloseModal(),
          },
          {
            elem_id: 'btn',
            type: 'Button',
            text: 'Solicitar',
            fullWidth: true,
            endIcon: <SendIcon />,
            color: 'primary',
            variant: 'contained',
            onClick: () => handleClick(),
          },
        ],
      ],
    },
  ],
});
