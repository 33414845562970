import { processData } from '@solussaude/core/Utils';
import { SearchIcon } from '@solussaude/core/Icons';

const tipoBusca = [
  { key: 'nome', name: 'Nome do prestador' },
  { key: 'nomeFantasia', name: 'Nome fantasia' },
  { key: 'crm', name: 'CRM' },
  { key: 'cpf', name: 'CPF' },
  { key: 'cgc', name: 'CNPJ' },
  { key: 'cidade', name: 'Cidade' },
  { key: 'estado', name: 'Estado' },
  { key: 'rede', name: 'Rede' },
  { key: 'grupo', name: 'Grupo' },
  { key: 'codigo', name: 'Código do prestador' },
];

export const formDadosMedico = (state, onClick) => ({
  form: [
    {
      inputs: [
        [
          {
            // Column
            elem_id: 'nomeMedico',
            elem_val: state.nomeMedico,
            label: 'Nome do médico ou profissional',
            type: 'Input',
            icon: SearchIcon,
            autoFocus: true,
            flex: 5,
            required: true,
            variant: 'outlined',
            small: true,
            // disabled: true,
            onClick: () => onClick(),
          },
        ],
        [
          // Row
          {
            // Column
            elem_id: 'crm',
            elem_val: state.crm,
            type: 'InputText',
            label: 'CRM ou Conselho de classe',
            flex: 5,
            variant: 'outlined',
            small: true,
            // disabled: true,
          },
          {
            // Column
            elem_id: 'ufCRM',
            elem_val: state.ufCRM,
            type: 'Select',
            label: 'UF do conselho',
            flex: 5,
            small: true,
            variant: 'outlined',
            // disabled: true,
            value: processData(state.estado.descricao, 'valor', 'descricao'),
          },
        ],
      ],
    },
  ],
});

export const formPrestador = (state, changePesquisa) => ({
  form: [
    {
      inputs: [
        [
          {
            // Column
            elem_id: 'tipoBusca',
            elem_val: state.tipoBusca,
            type: 'Select',
            label: 'Escolha uma opção',
            small: true,
            variant: 'outlined',
            value: tipoBusca,
            onChange: () => changePesquisa(),
          },
        ],
        [
          {
            // Column
            elem_id: 'parametro',
            elem_val: state.parametro,
            type: 'InputText',
            label: '',
            variant: 'outlined',
            small: true,
          },
        ],
      ],
    },
  ],
});

const prestadores = (apiData) => {
  const array = [];

  if (apiData) {
    if (apiData.length > 0) {
      apiData.forEach((prestador) => {
        array.push({
          codigo: prestador.codigo,
          nome: prestador.nome,
          nomeFantasia: prestador.nomeFantasia,
          cpf: prestador.cpf,
          estadoConselho: prestador.estadoConselho,
          cidade: prestador.cidade,
        });
      });
    }
  }

  return array;
};

export const tablePrestadores = (apiData) => ({
  table: {
    options: {
      type: 'headerTop',
      small: true,
      clickable: true,
      pagination: true,
    },
    headers: [
      { label: 'Código' },
      { label: 'Nome' },
      { label: 'Nome fantasia' },
      { label: 'CPF/CNPJ' },
      { label: 'Estado' },
    ],
    data: prestadores(apiData),
  },
});
