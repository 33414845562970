import styled from 'styled-components';

export const TablePanel = styled.div.attrs({
  className: 'withoutShadow',
})`
  background: #fff;

  margin-bottom: 35px;
  /* padding-left:35px;
    padding-right:35px; */

  box-shadow: none;
  border-radius: 5px;
  display: block;
  justify-content: center;
`;

export const TableBody = styled.div`
  flex: 1;
  margin-bottom: 20px;
  margin-top: 10px;

  h3 {
    color: #000;
    font-weight: 700;
    text-align: center;
  }
`;
