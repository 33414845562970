/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  DescriptionIcon,
  WarningIcon,
  CheckIcon,
  CloseIcon,
} from '@solussaude/core/Icons';
import Grid from '@solussaude/core/Grid';
import Page from '@solussaude/core/Surfaces/Page';
import TableCRUD from '@solussaude/core/DataDisplay/TableCRUD';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import SolusAPI from '../../../services/SolusApi';
import defaultTheme from '../../../styles/themes/default';
import WebContext from '@solussaude/core/WebContext';
import { isPromise, formataBase64 } from '@solussaude/core/Utils';
import Button from '@solussaude/core/Inputs/Button';
import { dadosComunicados } from './settings';
import ActionButton from '@solussaude/core/Helpers/ActionButtons';
import * as Styled from './styles';
import { FormatDate } from '../../../utils';

export default function Comunicados() {
  const theme = defaultTheme;
  const context = React.useContext(WebContext);

  const [state, setState] = React.useState({
    loading: true,
    apiData: [],
    successApi: true,
    idBeneficiario: 0,
    idOperador: 0,
    idContrato: 0,
  });

  const handleDadosComunicados = async () => {
    const userAuth = await isPromise(context.module.beneficiario.userAuth);

    const dadosComunicados = await SolusAPI.get(
      `v1/beneficiario/${userAuth.id}/comunicadoweb`,
      {
        todosComunicados: 'S',
      },
      { appName: 'api_webbeneficiario' },
    );

    return dadosComunicados;
  };

  React.useEffect(() => {
    const search = async () => {
      const userAuth = await isPromise(context.module.beneficiario.userAuth);

      const dadosOperador = await SolusAPI.get(
        'v1/operador',
        {
          login: 'OPERADOR_API',
        },
        { appName: 'api_webbeneficiario' },
      );
      const dadosComunicados = await handleDadosComunicados();

      setState({
        loading: false,
        apiData: dadosComunicados.data,
        successApi: dadosComunicados.success,
        idBeneficiario: userAuth.id,
        idOperador: dadosOperador.data.idOperador,
        idContrato: userAuth.id_contrato,
      });
    };
    search();
  }, []);

  const handleCloseModal = async (atualizaComunicados) => {
    if (atualizaComunicados) {
      const dadosComunicados = await handleDadosComunicados();

      setState({
        ...state,
        apiData: dadosComunicados.data,
        successApi: dadosComunicados.success,
      });
    }

    context.showModal({
      open: false,
    });
  };

  const handleAbrirArquivo = (anexos, titulo) => {
    anexos.map((anexo) => {
      const archive = formataBase64(
        anexo.anexo[0].extensao,
        anexo.imagem[0].imagem,
      );

      if (archive?.formatado) {
        let pdfWindow = window.open(archive.formatado, '_blank');
        pdfWindow.document.write(
          `<html><title>${titulo}</title><iframe style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;" src=
          ${encodeURI(archive.formatado)}></iframe></html>`,
        );
      }
    });
  };

  const handleIndicaCiencia = async (idComunicado) => {
    const dadosC = await SolusAPI.post(
      `v1/beneficiario/${state.idBeneficiario}/comunicadoweb/${idComunicado}/ciencia`,
      {
        idComunicado: idComunicado,
        idOperador: state.idOperador,
        idBeneficiario: state.idBeneficiario,
        idContrato: state.idContrato,
        siglaStatus: 'C',
      },
      { appName: 'api_webbeneficiario' },
    );

    if (dadosC.status === 201) {
      //Ciencia indicada com sucesso
      context.showModal({
        open: true,
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<CheckIcon color="primary" />}
              title="Comunicado"
              body="Ciência do comunicado indicada com sucesso!"
              noDivider
            />
            <ActionButton>
              <Button
                variant="contained"
                color="primary"
                text="Concluir"
                endIcon={<CheckIcon />}
                onClick={() => handleCloseModal(true)}
              />
            </ActionButton>
          </>
        ),
      });
    } else {
      //Erro ao indicar ciencia
      context.showModal({
        open: true,
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<WarningIcon color="secondary" />}
              title="Ops..."
              body="Não foi possivel indicar a ciência no comunicado, tente novamente mais tarde!"
              noDivider
            />
            <ActionButton>
              <Button
                variant="contained"
                color="primary"
                text="Fechar"
                onClick={() => handleCloseModal()}
              />
            </ActionButton>
          </>
        ),
      });
    }
  };

  const handleRetiraCiencia = async (idComunicado) => {
    context.showModal({
      open: true,
      disableEscapeKeyDown: true,
      disableBackdropClick: true,
      renderBody: (
        <>
          <BodyInfo
            icon={<WarningIcon color="error" />}
            title="Atenção"
            body="Deseja confirmar a retirada da ciência do comunicado?"
            noDivider
          />
          <Styled.FlexDirection>
            <Styled.ContainerButton paddingDirection="right">
              <Button
                variant="outlined"
                color="primary"
                text="Fechar"
                startIcon={<CloseIcon />}
                fullWidth={true}
                onClick={() => handleCloseModal()}
              />
            </Styled.ContainerButton>
            <Styled.ContainerButton paddingDirection="left">
              <Button
                variant="contained"
                color="primary"
                text="Confirmar"
                startIcon={<CheckIcon />}
                fullWidth={true}
                onClick={() => confirmaRetiradaCiencia()}
              />
            </Styled.ContainerButton>
          </Styled.FlexDirection>
        </>
      ),
    });

    const confirmaRetiradaCiencia = async () => {
      const dadosE = await SolusAPI.post(
        `v1/beneficiario/${state.idBeneficiario}/comunicadoweb/${idComunicado}/ciencia`,
        {
          idComunicado: idComunicado,
          idOperador: state.idOperador,
          idBeneficiario: state.idBeneficiario,
          idContrato: state.idContrato,
          siglaStatus: 'E',
        },
        { appName: 'api_webbeneficiario' },
      );

      if (dadosE.status === 201) {
        //Ciencia retirada com sucesso
        context.showModal({
          open: true,
          disableEscapeKeyDown: true,
          disableBackdropClick: true,
          renderBody: (
            <>
              <BodyInfo
                icon={<CheckIcon color="primary" />}
                title="Comunicado"
                body="Retirada de ciência do comunicado concluída com sucesso!"
                noDivider
              />
              <ActionButton>
                <Button
                  variant="contained"
                  color="primary"
                  text="Fechar"
                  onClick={() => handleCloseModal(true)}
                />
              </ActionButton>
            </>
          ),
        });
      } else {
        //Erro ao retirar ciencia
        context.showModal({
          open: true,
          disableEscapeKeyDown: true,
          disableBackdropClick: true,
          renderBody: (
            <>
              <BodyInfo
                icon={<WarningIcon color="secondary" />}
                title="Ops..."
                body="Não foi possivel retirar a ciência no comunicado, tente novamente mais tarde!"
                noDivider
              />
              <ActionButton>
                <Button
                  variant="contained"
                  color="primary"
                  text="Fechar"
                  onClick={() => handleCloseModal()}
                />
              </ActionButton>
            </>
          ),
        });
      }
    };
  };

  const handleHistoricoCiencia = async (idComunicado) => {
    const dadosH = await SolusAPI.get(
      `v1/beneficiario/${state.idBeneficiario}/comunicadoweb/${idComunicado}/historicociencia`,
      {},
      { appName: 'api_webbeneficiario' },
    );

    if (dadosH.status === 200) {
      context.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<CheckIcon color="primary" />}
              title="Histórico de ciência do comunicado"
              noDivider
              body={
                <>
                  {dadosH.data.map((historico) => (
                    <Styled.Padding paddingDirection="bottom">
                      {historico.siglaStatus === 'C'
                        ? `Ciência do comunicado em: ${FormatDate(
                            historico.data,
                          )}`
                        : `Recusa do comunicado em: ${FormatDate(
                            historico.data,
                          )}`}
                    </Styled.Padding>
                  ))}
                </>
              }
            />
            <Button
              variant="contained"
              color="primary"
              text="Fechar"
              onClick={() => handleCloseModal()}
            />
          </>
        ),
      });
    } else {
      context.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<WarningIcon color="error" />}
              title="Opss..."
              body="Não foi possível obter o histórico do comunicado, tente novamente mais tarde!"
              noDivider
            />
            <ActionButton>
              <Button
                variant="contained"
                color="primary"
                text="Fechar"
                startIcon={<CloseIcon />}
                onClick={() => handleCloseModal()}
              />
            </ActionButton>
          </>
        ),
      });
    }
  };

  const renderBodyTableComunicados = () => {
    if (state.successApi === true) {
      return (
        <TableCRUD
          settings={dadosComunicados(
            state.apiData,
            handleIndicaCiencia,
            handleRetiraCiencia,
            handleHistoricoCiencia,
            handleAbrirArquivo,
          )}
          inLoading={state.loading}
        />
      );
    }
  };

  const renderDefaultErrorComunicados = (
    <>
      <BodyInfo
        icon={<WarningIcon color="error" />}
        title="Opss..."
        body="Não foi possivel carregar seus comunicados, tente novamente mais tarde."
        noDivider
      />
    </>
  );

  return (
    <>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page title="Meus Comunicados" icon={DescriptionIcon}>
            <Grid item sm={12} md={12} />
            {state.successApi
              ? renderBodyTableComunicados()
              : renderDefaultErrorComunicados}
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
