import React from 'react';

import { useHistory } from '@solussaude/core/Router';

import NewPassword from '@solussaude/core/RegisterPassword/NewPassword';
import defaultTheme from '../../../styles/themes/default';
import { renderForm } from './settings';

export default function NewPasswordPage() {
  const theme = defaultTheme;
  const history = useHistory();

  return (
    <NewPassword
      theme={theme}
      props={{ history, location: { state: 0 } }}
      settings={renderForm}
    />
  );
}
